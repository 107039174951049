<template>
    <ui-video-card
        :id="cardElementId"
        :card-name="$t('Panel')"
        classes="ui-video-card-panel"
        :selected="editing"
        @click.left="select"
    >
        <template #selector>
            <ui-library-selector
                v-if="!isCustomCategory"
                ref="$panelSelector"
                :id="cardElementId + '-shape-category'"
                key="library-selector-panel-shape-category"
                v-model="panelShapeType"
                :default-value="panelShapeDefaultTypes[panelShapeCategory]"
                library="panels"
                :category="panelShapeCategory"
                preview-path="panels/"
                :label="$t('Choose a panel shape')"
                :show-label="false"
                :use-selection-label="false"
                :disabled="readOnly"
                @[librarySelectorShowEvent]="openLibrary"
                @[librarySelectorPreviewChangeEvent]="previewPanelShapeType"
            />
            <ui-media-selector
                v-else
                :id="cardElementId + '-shape-image'"
                key="media-selector-panel-shape-image"
                v-model:media="panelShapeImage"
                :type="mediaTypeImage"
                :label="$t('studio.panel_categories.custom')"
                :showLabel="false"
                :disabled="readOnly"
                icon="fa-regular fa-image"
                @[mediaSelectorShowEvent]="openMediaLibrary"
            />
        </template>

        <template #content>
            <ui-dropdown
                :id="cardElementId + '-shape-category'"
                :select="true"
                v-model="panelShapeCategory"
                :disabled="readOnly"
            >
                <template #dropdown-toggle="{ instance }">
                    <svg-icon :icon="instance.selectedValue.toLowerCase() + '-panel-icon'" />
                    <span>{{ instance.toggleLabel }}</span>
                </template>

                <ui-dropdown-item
                    v-for="category in panelShapeCategories"
                    :key="category"
                    :value="category"
                    :label="$t('studio.panel_categories.' + category)"
                >
                    <template #dropdown-menu-item-label="{ instance }">
                        <svg-icon :icon="instance.value.toLowerCase() + '-panel-icon'" />
                        {{ instance.label || instance.value }}
                    </template>
                </ui-dropdown-item>
            </ui-dropdown>
        </template>

        <template #actions-menu-content>
            <ui-card-sequence-menu-content :id="id" :seq-id="seqId" :remove-actions="removePanel" />
        </template>

        <template #actions-tools>
            <ui-panel-tools :seq-id="seqId" :card-element-id="cardElementId" />
        </template>
    </ui-video-card>
</template>

<script setup>
import { computed, inject } from 'vue';

import UiVideoCard from '@/js/videos/components/ui/body/UiVideoCard.vue';
import { PANEL_ELEMENT_ID } from '@/js/constants/index.js';
import UiMediaSelector, { UI_MEDIA_TYPE_IMAGE, UI_MEDIA_SELECTOR_SHOW } from '@/js/components/UiMediaSelector.vue';
import UiLibrarySelector, {
    UI_LIBRARY_SELECTOR_SHOW,
    UI_LIBRARY_SELECTOR_PREVIEW_CHANGE
} from '@/js/components/UiLibrarySelector.vue';
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiDropdownItem from '@/js/components/UiDropdownItem.vue';
import { usePanel } from '@/js/videos/composables/usePanel.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { Panel } from '@/js/video-studio/constants/index.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import UiCardSequenceMenuContent from '@/js/videos/components/ui/body/cards/menus/UiCardSequenceMenuContent.vue';
import UiPanelTools from '@/js/videos/components/ui/body/cards/tools/UiPanelTools.vue';

const props = defineProps({
    id: {
        type: String,
        default: PANEL_ELEMENT_ID
    },
    seqId: String,
    editing: Boolean
});

const mediaTypeImage = UI_MEDIA_TYPE_IMAGE;
const panelShapeDefaultTypes = Panel.DEFAULTS;
const panelShapeCategories = Panel.CATEGORIES;
const librarySelectorShowEvent = UI_LIBRARY_SELECTOR_SHOW;
const librarySelectorPreviewChangeEvent = UI_LIBRARY_SELECTOR_PREVIEW_CHANGE;
const mediaSelectorShowEvent = UI_MEDIA_SELECTOR_SHOW;

const cardElementId = computed(() => `${props.seqId}-${props.id}`);

const videoStudio = inject('$videoStudio');
const { ignoreHistoryStep } = useHistory();
const { readOnly, openLibrary, openMediaLibrary } = useVideo();
const { panelShapeType, panelShapeCategory, panelShapeImage, isCustomCategory, previewPanelShapeType, removePanel } =
    usePanel(props.seqId);

const select = () =>
    videoStudio.value.studio.$stage.getSequenceElement(props.seqId).startEditingElement(null, cardElementId.value);
</script>
