<template>
    <ui-media-selector
        v-if="transitionUses.includes('image')"
        :id="seqId + '-image'"
        key="media-selector-transition-image"
        v-model:media="transitionImage"
        :type="mediaTypeImage"
        :label="$t('Image')"
        :showLabel="true"
        :disabled="readOnly"
        icon="fa-solid fa-image"
        @[mediaSelectorShowEvent]="openMediaLibrary"
    />

    <ui-color-selector
        v-if="transitionUses.includes('image.color')"
        :id="seqId + '-image-color'"
        key="color-selector-transition-image-color"
        v-model:color="transitionImageColor"
        :default-color="colorEmpty"
        :enable-empty="true"
        :palette="brandPalette"
        :enable-other-colors="brandEnableOtherColors"
        :label="$t('Image color')"
        :disabled="readOnly"
    />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import UiMediaSelector, { UI_MEDIA_TYPE_IMAGE } from '@/js/components/UiMediaSelector.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { getPrefixedUuid } from '@/js/utils.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import UiColorSelector from '@/js/components/UiColorSelector.vue';
import { useBrand } from '@/js/videos/composables/useBrand.js';
import { Color } from '@/js/video-studio/constants/index.js';

const props = defineProps({
    seqId: String,
    transitionUses: Array
});

const store = useStore();
const { readOnly, openMediaLibrary } = useVideo();
const { saveHistoryStep } = useHistory();
const { brandPalette, brandEnableOtherColors } = useBrand();
const { sequenceState, sequenceStoreModulePath } = useSequence(props.seqId);

const mediaTypeImage = UI_MEDIA_TYPE_IMAGE;
const colorEmpty = Color.NONE;
const prefixes = computed(() => store.state.ui.prefixes);

const transitionImage = computed({
    get: () => {
        let ref = { src: '', id: '' };

        if (props.transitionUses.includes('image')) {
            ref.src = sequenceState.value.transition.image.src;
            ref.id = sequenceState.value.transition.image.src__id;
        }

        return ref;
    },
    set: ({ src, id }) => {
        saveHistoryStep(() => {
            if (!sequenceState.value.transition.image.src__ref) {
                store.commit(
                    `${sequenceStoreModulePath.value}/setTransitionImageReference`,
                    getPrefixedUuid(prefixes.value.mediaReference)
                );
            }
            store.dispatch(`${sequenceStoreModulePath.value}/updateTransitionImage`, { src, src__id: id });
        });
    }
});

const transitionImageColor = computed({
    get: () => {
        let color = { ref: Color.NONE, value: Color.NONE };

        if (props.transitionUses.includes('image.color')) {
            color.ref = sequenceState.value.transition.image.color__ref;
            color.value = sequenceState.value.transition.image.color;
        }

        return color;
    },
    set: ({ ref, value }) => {
        saveHistoryStep(() => {
            store.commit(
                `${sequenceStoreModulePath.value}/setTransitionImageColor`,
                Object.assign({}, sequenceState.value.transition.image, { color__ref: ref, color: value })
            );
        });
    }
});
</script>
