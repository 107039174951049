<template>
    <div class="sequence-collage-images">
        <ul>
            <li
                v-for="(collageImage, index) in backgroundCollageImages"
                :key="'collage-image' + index"
                class="sequence-collage-image"
            >
                <ui-media-selector
                    :id="'card-seq-' + seqId + '-image' + (index + 1)"
                    :key="'media-selector-collage-image' + (index + 1)"
                    :media="collageImage"
                    :type="mediaTypeImage"
                    :label="sprintf($t('Image %1$d'), index + 1)"
                    :showLabel="false"
                    :disabled="readOnly"
                    icon="fa-regular fa-image"
                    @[mediaSelectorChangeEvent]="setCollageImage(index, $event)"
                    @[mediaSelectorShowEvent]="openMediaLibrary"
                />
            </li>
        </ul>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { printf as sprintf } from 'fast-printf';

import UiMediaSelector, {
    UI_MEDIA_TYPE_IMAGE,
    UI_MEDIA_SELECTOR_SHOW,
    UI_MEDIA_SELECTOR_CHANGE
} from '@/js/components/UiMediaSelector.vue';
import { Background } from '@/js/video-studio/constants/index.js';
import { getPrefixedUuid } from '@/js/utils.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';

const props = defineProps({
    seqId: String
});

const store = useStore();
const { saveHistoryStep } = useHistory();
const { openMediaLibrary, readOnly } = useVideo();
const { sequenceStoreModulePath } = useSequence(props.seqId);

const prefixes = computed(() => store.state.ui.prefixes);

const mediaSelectorShowEvent = UI_MEDIA_SELECTOR_SHOW;
const mediaSelectorChangeEvent = UI_MEDIA_SELECTOR_CHANGE;
const mediaTypeImage = UI_MEDIA_TYPE_IMAGE;

const backgroundCollageImages = computed({
    get: () => {
        let index,
            images = [];
        for (index = 0; index < Background.COLLAGE_MAX_MEDIA; index++) {
            images[index] = {
                src: store.getters[sequenceStoreModulePath.value + '/collageImage'](index + 1),
                id: store.getters[sequenceStoreModulePath.value + '/collageImageId'](index + 1)
            };
        }
        return images;
    },
    set: (images) => {
        let imagesStore = images.reduce((_store, image, index) => {
            if (image.id !== store.getters[sequenceStoreModulePath.value + '/collageImageId'](index + 1)) {
                _store['image' + (index + 1)] = image.src;
                _store['image' + (index + 1) + '__id'] = image.id;
                if (!store.getters[sequenceStoreModulePath.value + '/collageImageRef'](index + 1)) {
                    _store['image' + (index + 1) + '__ref'] = getPrefixedUuid(prefixes.value.mediaReference);
                }
            }
            return _store;
        }, {});

        if (!!Object.keys(imagesStore).length) {
            saveHistoryStep(() => {
                store.commit(`${sequenceStoreModulePath.value}/setCollageImageReferences`, imagesStore);
                store.dispatch(`${sequenceStoreModulePath.value}/updateCollageImages`, imagesStore);
            });
        }
    }
});

const setCollageImage = (index, media) => {
    let images = [...backgroundCollageImages.value];
    images[index] = media;
    backgroundCollageImages.value = [...images];
};
</script>
