<template>
    <div class="sequence-collage-images">
        <ul>
            <li v-for="(n, index) in messageImagesMax" :key="'message-image' + index" class="sequence-collage-image">
                <ui-media-selector
                    :id="cardElementId + '-image' + (index + 1)"
                    :key="'media-selector-message-image' + (index + 1)"
                    :media="messageImages[index]"
                    :type="mediaTypeImage"
                    :show-label="false"
                    :disabled="readOnly"
                    icon="fa-regular fa-image"
                    @[mediaSelectorChangeEvent]="setMessageImage(index, $event)"
                    @[mediaSelectorShowEvent]="openMediaLibrary"
                />
            </li>
        </ul>
    </div>
</template>

<script setup>
import { useStore } from 'vuex';

import UiMediaSelector, {
    UI_MEDIA_TYPE_IMAGE,
    UI_MEDIA_SELECTOR_SHOW,
    UI_MEDIA_SELECTOR_CHANGE
} from '@/js/components/UiMediaSelector.vue';
import { useMessage } from '@/js/videos/composables/useMessage.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';

const props = defineProps({
    seqId: String,
    cardElementId: String,
    elementId: String
});

const mediaSelectorShowEvent = UI_MEDIA_SELECTOR_SHOW;
const mediaSelectorChangeEvent = UI_MEDIA_SELECTOR_CHANGE;
const mediaTypeImage = UI_MEDIA_TYPE_IMAGE;

const store = useStore();
const { saveHistoryStep } = useHistory();
const { readOnly, openMediaLibrary } = useVideo();
const { messageImages, messageImagesMax, displayedMediaSelectors, elementStoreModulePath } = useMessage(
    props.seqId,
    props.elementId
);

const setMessageImage = (index, media) => {
    let images = [...messageImages.value];
    images[index] = media;
    messageImages.value = [...images];
};
</script>
