<template>
    <ui-dropdown
        :id="cardElementId + '-timing'"
        :menu="false"
        :caret="false"
        :scrollable="false"
        :disabled="readOnly"
        :tooltip="$t('Duration')"
        menu-placement="bottom"
        :icon-only="true"
        :toggle-style="toggleDurationStyle"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-clock" />
        </template>

        <ui-number-input
            :id="cardElementId + '-start'"
            v-model="panelStart"
            :default-value="panelStartDefault"
            :min="0"
            :label="$t('Starting time')"
            :disabled="readOnly"
        />
        <ui-number-input
            :id="cardElementId + '-end'"
            v-model="panelEnd"
            :default-value="panelEndDefault"
            :min="panelStart"
            :label="$t('Ending time')"
            :disabled="readOnly"
        />
    </ui-dropdown>
</template>

<script setup>
import { computed } from 'vue';
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiNumberInput from '@/js/components/UiNumberInput.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { usePanel } from '@/js/videos/composables/usePanel.js';
import { Duration } from '@/js/video-studio/constants/index.js';

const props = defineProps({
    seqId: String,
    cardElementId: String
});

const panelStartDefault = Duration.START_DEFAULT;
const panelEndDefault = Duration.END_DEFAULT;

const { readOnly } = useVideo();
const { panelStart, panelEnd } = usePanel(props.seqId);

const toggleDurationStyle = computed(() => {
    if (panelStart.value !== panelStartDefault || (panelEnd.value !== '' && panelEnd.value !== 0)) {
        return { color: 'var(--edit-mode-color)' };
    } else return {};
});
</script>
