<template>
    <div class="sequence-collage-images">
        <ul>
            <li
                v-for="(collageImage, index) in visualCollageImages"
                :key="'collage-image' + index"
                class="sequence-collage-image"
            >
                <ui-media-selector
                    :id="cardElementId + '-image' + (index + 1)"
                    :key="'media-selector-collage-image' + (index + 1)"
                    :media="collageImage"
                    :type="mediaTypeImage"
                    :label="sprintf($t('Image %1$d'), index + 1)"
                    :showLabel="false"
                    :disabled="readOnly"
                    icon="fa-regular fa-image"
                    @[mediaSelectorChangeEvent]="setCollageImage(index, $event)"
                    @[mediaSelectorShowEvent]="openMediaLibrary"
                />
            </li>
        </ul>
    </div>
</template>

<script setup>
import { printf as sprintf } from 'fast-printf';
import UiMediaSelector, {
    UI_MEDIA_TYPE_IMAGE,
    UI_MEDIA_SELECTOR_SHOW,
    UI_MEDIA_SELECTOR_CHANGE
} from '@/js/components/UiMediaSelector.vue';
import { useVisual } from '@/js/videos/composables/useVisual.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';

const props = defineProps({
    seqId: String,
    elementId: String,
    cardElementId: String
});

const mediaTypeImage = UI_MEDIA_TYPE_IMAGE;
const mediaSelectorShowEvent = UI_MEDIA_SELECTOR_SHOW;
const mediaSelectorChangeEvent = UI_MEDIA_SELECTOR_CHANGE;

const { readOnly, openMediaLibrary } = useVideo();
const { visualCollageImages, setCollageImage } = useVisual(props.seqId, props.elementId);
</script>
