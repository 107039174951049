<template>
    <ui-dropdown
        id="settings-logo"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="top-start"
        menu-strategy="fixed"
        :toggle-style="brandLogoEnabled ? { color: 'var(--edit-mode-color)' } : {}"
    >
        <template #dropdown-toggle>
            <svg-icon icon="logo-icon" />
            <span>{{ $t('Logo') }}</span>
        </template>

        <ui-switch-button
            id="settings-enable-brand-logo"
            :label="$t('Display brand logo')"
            v-model="brandLogoEnabled"
            :disabled="readOnly"
        />

        <ui-media-selector
            v-if="brandLogoEnabled"
            id="settings-brand-logo-image"
            key="media-selector-brand-logo-image"
            v-model:media="brandLogoImage"
            :type="mediaTypeImage"
            :label="$t('Brand logo')"
            :disabled="readOnly"
            icon="fa-solid fa-image"
            @[mediaSelectorShowEvent]="openMediaLibrary"
        />
        <ui-library-selector
            v-if="brandLogoEnabled"
            id="settings-brand-logo-animation"
            key="library-selector-brand-logo-animation"
            v-model="brandLogoAnimationType"
            :default-value="brandLogoDefaultAnimationType"
            library="logoAnimations"
            preview-path="logos/"
            :label="$t('Animation')"
            :disabled="readOnly"
            icon="fa-solid fa-sparkles"
            @[librarySelectorShowEvent]="openLibrary"
        />

        <ui-switch-button
            v-if="brandLogoEnabled"
            id="settings-override-brand-logo-size"
            :label="$t('Change size and position')"
            v-model="brandLogoOverridePosition"
            :disabled="readOnly"
        />

        <template v-if="brandLogoOverridePosition">
            <ui-number-input
                id="settings-brand-logo-width"
                v-model="brandLogoWidth"
                :default-value="''"
                :min="1"
                :decimals="1"
                :label="$t('Width')"
                :disabled="readOnly"
            />
            <ui-number-input
                id="settings-brand-logo-height"
                v-model="brandLogoHeight"
                :default-value="''"
                :min="1"
                :decimals="1"
                :label="$t('Height')"
                :disabled="readOnly"
            />
            <div class="ui-content-field">
                <label>{{ $t('Horizontal position') }}</label>
                <ui-dropdown
                    id="settings-brand-logo-hor-position"
                    :select="true"
                    :editable="true"
                    :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                    v-model="brandLogoHorizontalPosition"
                    :disabled="readOnly"
                >
                    <ui-dropdown-item
                        v-for="position in brandLogoHorizontalPositions"
                        :key="position"
                        :value="position"
                        :label="$t('studio.alignments.' + position)"
                    />
                </ui-dropdown>
            </div>

            <div class="ui-content-field">
                <label>{{ $t('Vertical position') }}</label>
                <ui-dropdown
                    id="settings-brand-logo-ver-position"
                    :select="true"
                    :editable="true"
                    :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                    v-model="brandLogoVerticalPosition"
                    :disabled="readOnly"
                >
                    <ui-dropdown-item
                        v-for="position in brandLogoVerticalPositions"
                        :key="position"
                        :value="position"
                        :label="$t('studio.alignments.' + position)"
                    />
                </ui-dropdown>
            </div>
        </template>
    </ui-dropdown>
</template>

<script setup>
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';
import UiMediaSelector, { UI_MEDIA_TYPE_IMAGE, UI_MEDIA_SELECTOR_SHOW } from '@/js/components/UiMediaSelector.vue';
import UiLibrarySelector, { UI_LIBRARY_SELECTOR_SHOW } from '@/js/components/UiLibrarySelector.vue';
import UiNumberInput from '@/js/components/UiNumberInput.vue';
import UiDropdownItem from '@/js/components/UiDropdownItem.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useBranding } from '@/js/videos/composables/useBranding.js';
import { Logo } from '@/js/video-studio/constants/index.js';
import { LOGO_HORIZONTAL_POSITIONS, LOGO_VERTICAL_POSITIONS } from '@/js/constants/index.js';

const mediaTypeImage = UI_MEDIA_TYPE_IMAGE;
const mediaSelectorShowEvent = UI_MEDIA_SELECTOR_SHOW;
const librarySelectorShowEvent = UI_LIBRARY_SELECTOR_SHOW;

const brandLogoDefaultAnimationType = Logo.DEFAULT;
const brandLogoHorizontalPositions = LOGO_HORIZONTAL_POSITIONS;
const brandLogoVerticalPositions = LOGO_VERTICAL_POSITIONS;

const { readOnly, openMediaLibrary, openLibrary } = useVideo();
const {
    brandLogoEnabled,
    brandLogoImage,
    brandLogoAnimationType,
    brandLogoOverridePosition,
    brandLogoWidth,
    brandLogoHeight,
    brandLogoHorizontalPosition,
    brandLogoVerticalPosition
} = useBranding();
</script>
