<template>
    <ui-dropdown
        id="settings-captions"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="top-end"
        menu-strategy="fixed"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-comment-dots" />
            <span>{{ $t('Captions') }}</span>
        </template>

        <div class="captions-font-selection ui-content-field" :class="{ disabled: readOnly }">
            <label>{{ $t('Font') }}</label>
            <ui-dropdown
                id="settings-captions-font"
                :select="true"
                v-model="captionsFont"
                :disabled="readOnly"
                :toggle-style="getCaptionsFontStyles(captionsFont)"
            >
                <ui-dropdown-item
                    v-for="font in parsedFontLibrary"
                    :key="font.name"
                    :value="font.name"
                    :style="getCaptionsFontStyles(font.name)"
                />
            </ui-dropdown>
        </div>

        <div class="ui-content-field" :class="{ disabled: readOnly }">
            <label>{{ $t('Alignment') }}</label>
            <ui-dropdown id="settings-captions-alignment" :select="true" v-model="captionsAlign" :disabled="readOnly">
                <ui-dropdown-item
                    v-for="(alignLabel, alignValue) in availableAlignmentTypes"
                    :value="alignValue"
                    :label="alignLabel"
                />
            </ui-dropdown>
        </div>

        <div class="ui-content-field" :class="{ disabled: readOnly }">
            <label>{{ $t('Style') }}</label>
            <ui-dropdown id="settings-captions-style" :select="true" v-model="captionsStyle" :disabled="readOnly">
                <ui-dropdown-item
                    v-for="(styleLabel, styleValue) in availableFontStyles"
                    :key="styleValue"
                    :value="styleValue"
                    :label="styleLabel"
                />
            </ui-dropdown>
        </div>

        <div class="ui-content-field" :class="{ disabled: readOnly }">
            <label>{{ $t('Size') }}</label>
            <ui-dropdown id="settings-captions-size" :select="true" v-model="captionsSize" :disabled="readOnly">
                <ui-dropdown-item
                    v-for="scale in fontScaleValues"
                    :key="'scale-' + scale"
                    :value="scale * fontSizeReference"
                />
            </ui-dropdown>
        </div>

        <ui-color-selector
            id="settings-captions-color"
            key="color-selector-captions-color"
            v-model:color="captionsColor"
            :default-color="captionsDefaultColor"
            :palette="brandPalette"
            :enable-other-colors="brandEnableOtherColors"
            :label="$t('Text color')"
            :disabled="readOnly"
        />

        <ui-color-selector
            id="settings-captions-bg-color"
            key="color-selector-captions-bg-color"
            v-model:color="captionsBackgroundColor"
            :default-color="captionsDefaultBackgroundColor"
            :palette="brandPalette"
            :enable-empty="true"
            :enable-alpha="true"
            :enable-other-colors="brandEnableOtherColors"
            :label="$t('Text background color')"
            :disabled="readOnly"
        />

        <ui-switch-button
            id="settings-enable-captions-shadow"
            :label="$t('Add shadow')"
            v-model="captionsShadowEnabled"
            :disabled="readOnly"
        />

        <div class="ui-content-field" :class="{ disabled: readOnly }">
            <label>{{ $t('Horizontal position') }}</label>
            <ui-dropdown
                id="settings-captions-hor-position"
                :select="true"
                :editable="true"
                :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                v-model="captionsHorizontalPosition"
                :disabled="readOnly"
            >
                <ui-dropdown-item
                    v-for="position in captionsHorizontalPositions"
                    :key="position"
                    :value="position"
                    :label="$t('studio.alignments.' + position)"
                />
            </ui-dropdown>
        </div>

        <div class="ui-content-field" :class="{ disabled: readOnly }">
            <label>{{ $t('Vertical position') }}</label>
            <ui-dropdown
                id="settings-captions-ver-position"
                :select="true"
                :editable="true"
                :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                v-model="captionsVerticalPosition"
                :disabled="readOnly"
            >
                <ui-dropdown-item
                    v-for="position in captionsVerticalPositions"
                    :key="position"
                    :value="position"
                    :label="$t('studio.alignments.' + position)"
                />
            </ui-dropdown>
        </div>
    </ui-dropdown>
</template>

<script setup>
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';
import UiColorSelector from '@/js/components/UiColorSelector.vue';
import UiDropdownItem from '@/js/components/UiDropdownItem.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import {
    CAPTIONS_HORIZONTAL_POSITIONS,
    CAPTIONS_VERTICAL_POSITIONS,
    TEXT_EDITOR_DEFAULT_FONT_SCALES
} from '@/js/constants/index.js';
import { useBranding } from '@/js/videos/composables/useBranding.js';
import { Color, Message } from '@/js/video-studio/constants/index.js';
import { useBrand } from '@/js/videos/composables/useBrand.js';

const fontScaleValues = TEXT_EDITOR_DEFAULT_FONT_SCALES;
const fontSizeReference = Message.FONT_SIZE_DEFAULT;
const captionsDefaultColor = Color.CAPTIONS_DEFAULT;
const captionsDefaultBackgroundColor = Color.NONE;
const captionsHorizontalPositions = CAPTIONS_HORIZONTAL_POSITIONS;
const captionsVerticalPositions = CAPTIONS_VERTICAL_POSITIONS;

const { readOnly } = useVideo();
const { brandPalette, brandEnableOtherColors, parsedFontLibrary } = useBrand();
const {
    captionsFont,
    captionsAlign,
    captionsStyle,
    captionsSize,
    captionsColor,
    captionsVerticalPosition,
    captionsHorizontalPosition,
    captionsShadowEnabled,
    captionsBackgroundColor,
    availableAlignmentTypes,
    availableFontStyles
} = useBranding();

const getCaptionsFontStyles = (font) => ({
    '--font-thumbnail': `url(/assets/thumbnails/fonts/${font}.gif)`
});
</script>
