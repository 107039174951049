import { useStore } from 'vuex';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { getPrefixedUuid } from '@/js/utils.js';
import { useBrand } from '@/js/videos/composables/useBrand.js';
import { Align, Color, Dimension, Font, Message } from '@/js/video-studio/constants/index.js';
import { VIDEO_BRANDING_BORDER_DEFAULT_SIZE } from '@/js/constants/index.js';

export const useBranding = () => {
    const store = useStore();
    const { brandLogo } = useBrand();
    const { startHistoryStep } = useHistory();
    const { saveVideo } = useVideo();
    const { t } = useI18n();

    const settingsState = computed(() => store.state.settings);
    const prefixes = computed(() => store.state.ui.prefixes);

    const brandLogoSize = computed(() => store.getters['settings/logoSize']);
    const brandLogoPosition = computed(() => store.getters['settings/logoPosition']);
    const borders = computed(() => store.getters['settings/borders']);

    const brandLogoEnabled = computed({
        get: () => settingsState.value.logo.enabled,
        set: (value) => {
            startHistoryStep();
            store.commit('settings/enableLogo', value);
            saveVideo();
        }
    });

    const brandLogoAnimationType = computed({
        get: () => store.getters['settings/logoAnimation'],
        set: (type) => {
            let override =
                (!!type && type !== brandLogo.value.animation.type) ||
                brandLogoImage.value.src !== brandLogo.value.animation.src;

            startHistoryStep();
            store.commit('settings/overrideLogo', override);
            store.commit('settings/setLogoAnimation', type);
            saveVideo();
        }
    });

    const brandLogoImage = computed({
        get: () => {
            let ref = { src: '', id: '' };

            if (brandLogoEnabled.value) {
                ref.src = store.getters['settings/logo'];
                ref.id = store.getters['settings/logoId'];
            }

            return ref;
        },
        set: ({ src, id }) => {
            let override =
                (!!src && src !== brandLogo.value.animation.src) ||
                brandLogoAnimationType.value !== brandLogo.value.animation.type;

            startHistoryStep();
            store.commit('settings/overrideLogo', override);
            if (!settingsState.value.logo.animation.src__ref) {
                store.commit('settings/setLogoReference', getPrefixedUuid(prefixes.value.mediaReference));
            }
            store.dispatch('settings/updateLogo', { src, src__id: id });
            saveVideo();
        }
    });

    const brandLogoOverridePosition = computed({
        get: () => settingsState.value.logo.position.overrideBranding,
        set: (value) => {
            startHistoryStep();
            store.commit('settings/overrideLogoPosition', value);
            saveVideo();
        }
    });

    const brandLogoWidth = computed({
        get: () => (brandLogoSize.value.width !== Dimension.AUTO ? parseFloat(brandLogoSize.value.width) : ''),
        set: (value) => {
            if (value !== brandLogoWidth.value) {
                startHistoryStep();
                store.commit('settings/setLogoWidth', {
                    value: Dimension.CUSTOM,
                    custom: value !== '' ? value + Dimension.PERCENT_UNIT : value
                });
                saveVideo();
            }
        }
    });

    const brandLogoHeight = computed({
        get: () => (brandLogoSize.value.height !== Dimension.AUTO ? parseFloat(brandLogoSize.value.height) : ''),
        set: (value) => {
            if (value !== brandLogoHeight.value) {
                startHistoryStep();
                store.commit('settings/setLogoHeight', {
                    value: Dimension.CUSTOM,
                    custom: value !== '' ? value + Dimension.PERCENT_UNIT : value
                });
                saveVideo();
            }
        }
    });

    const brandLogoHorizontalPosition = computed({
        get: () => {
            return brandLogoPosition.value.alignH !== Align.CUSTOM
                ? brandLogoPosition.value.alignH
                : parseFloat(brandLogoPosition.value.alignX);
        },
        set: (value) => {
            let parsedValue = value.replace(',', '.'),
                position = {
                    alignH: !isNaN(parsedValue) ? Align.CUSTOM : value,
                    alignX: !isNaN(parsedValue) ? parsedValue : null
                };
            if (
                position.alignH !== settingsState.value.logo.position.alignH ||
                position.alignX !== settingsState.value.logo.position.alignX
            ) {
                startHistoryStep();
                store.commit(
                    'settings/setLogoPosition',
                    Object.assign({}, settingsState.value.logo.position, position)
                );
                saveVideo();
            }
        }
    });

    const brandLogoVerticalPosition = computed({
        get: () => {
            return brandLogoPosition.value.alignV !== Align.CUSTOM
                ? brandLogoPosition.value.alignV
                : parseFloat(brandLogoPosition.value.alignY);
        },
        set: (value) => {
            let parsedValue = value.replace(',', '.'),
                position = {
                    alignV: !isNaN(parsedValue) ? Align.CUSTOM : value,
                    alignY: !isNaN(parsedValue) ? parsedValue : Dimension.ZERO
                };
            if (
                position.alignV !== settingsState.value.logo.position.alignV ||
                position.alignY !== settingsState.value.logo.position.alignY
            ) {
                startHistoryStep();
                store.commit(
                    'settings/setLogoPosition',
                    Object.assign({}, settingsState.value.logo.position, position)
                );
                saveVideo();
            }
        }
    });

    const bordersEnabled = computed({
        get: () => settingsState.value.borders.enabled,
        set: (value) => {
            if (value ^ bordersEnabled.value) {
                startHistoryStep();
                if (!!value && !bordersEnabled.value && settingsState.value.borders.size === Dimension.ZERO) {
                    store.commit('settings/setBorderSize', VIDEO_BRANDING_BORDER_DEFAULT_SIZE);
                }
                store.commit('settings/enableBorders', value);
                store.commit('settings/customizeBorders', value);
                saveVideo();
            }
        }
    });
    const enableBorders = () => (bordersEnabled.value = true);

    const bordersColor = computed({
        get: () => {
            let color = { ref: Color.BORDER_DEFAULT, value: Color.BORDER_DEFAULT };

            if (bordersEnabled.value) {
                color.ref = settingsState.value.borders.color__ref;
                color.value = settingsState.value.borders.color;
            }

            return color;
        },
        set: ({ ref, value }) => {
            startHistoryStep();
            store.commit('settings/setBorderColor', value);
            store.commit('settings/setBorderColorReference', ref);
            saveVideo();
        }
    });

    const bordersSize = computed({
        get: () => settingsState.value.borders.size,
        set: (value) => {
            if (value !== bordersSize.value) {
                startHistoryStep();
                store.commit('settings/setBorderSize', value);
                saveVideo();
            }
        }
    });

    const borderTop = computed({
        get: () => borders.value && borders.value.top.size !== Dimension.ZERO,
        set: (value) => {
            if (value !== borderTop.value) {
                startHistoryStep();
                store.commit('settings/setBorderSide', {
                    side: Dimension.TOP,
                    enabled: !value,
                    size: !value ? Dimension.ZERO : null
                });
                saveVideo();
            }
        }
    });

    const borderBottom = computed({
        get: () => borders.value && borders.value.bottom.size !== Dimension.ZERO,
        set: (value) => {
            if (value !== borderBottom.value) {
                startHistoryStep();
                store.commit('settings/setBorderSide', {
                    side: Dimension.BOTTOM,
                    enabled: !value,
                    size: !value ? Dimension.ZERO : null
                });
                saveVideo();
            }
        }
    });

    const borderLeft = computed({
        get: () => borders.value && borders.value.left.size !== Dimension.ZERO,
        set: (value) => {
            if (value !== borderLeft.value) {
                startHistoryStep();
                store.commit('settings/setBorderSide', {
                    side: Dimension.LEFT,
                    enabled: !value,
                    size: !value ? Dimension.ZERO : null
                });
                saveVideo();
            }
        }
    });

    const borderRight = computed({
        get: () => borders.value && borders.value.right.size !== Dimension.ZERO,
        set: (value) => {
            if (value !== borderRight.value) {
                startHistoryStep();
                store.commit('settings/setBorderSide', {
                    side: Dimension.RIGHT,
                    enabled: !value,
                    size: !value ? Dimension.ZERO : null
                });
                saveVideo();
            }
        }
    });

    const captionsFont = computed({
        get: () => store.getters['settings/captionsFont'],
        set: (font) => {
            startHistoryStep();
            store.commit('settings/setFont', { value: font, custom: null });
            saveVideo();
        }
    });

    const captionsAlign = computed({
        get: () => settingsState.value.captions.align || Align.TEXT_CENTER,
        set: (value) => {
            startHistoryStep();
            store.commit('settings/setAlign', value);
            saveVideo();
        }
    });

    const captionsStyle = computed({
        get: () => settingsState.value.captions.style || Font.STYLE_BOLD,
        set: (value) => {
            startHistoryStep();
            store.commit('settings/setStyle', value);
            saveVideo();
        }
    });

    const captionsSize = computed({
        get: () => settingsState.value.captions.fontScale.value * Message.FONT_SIZE_DEFAULT,
        set: (value) => {
            let fontScale = value / Message.FONT_SIZE_DEFAULT;

            if (fontScale !== settingsState.value.captions.fontScale.value) {
                startHistoryStep();
                store.commit('settings/setFontScale', { value: fontScale });
                saveVideo();
            }
        }
    });

    const captionsColor = computed({
        get: () => ({
            ref: settingsState.value.captions.color.start__ref || Color.CAPTIONS_DEFAULT,
            value: settingsState.value.captions.color.start || Color.CAPTIONS_DEFAULT
        }),
        set: ({ ref, value }) => {
            startHistoryStep();
            store.commit('settings/setColor', { start__ref: ref, start: value });
            saveVideo();
        }
    });

    const captionsBackgroundColor = computed({
        get: () => ({
            ref: settingsState.value.captions.background.color.start__ref || Color.NONE,
            value: settingsState.value.captions.background.color.start || Color.NONE,
            alpha: settingsState.value.captions.background.opacity
        }),
        set: ({ ref, value, alpha }) => {
            startHistoryStep();
            store.commit('settings/setBackground', { color: { start__ref: ref, start: value } });
            store.commit('settings/setBackgroundOpacity', alpha);
            saveVideo();
        }
    });

    const captionsShadowEnabled = computed({
        get: () => settingsState.value.captions.shadow,
        set: (value) => {
            startHistoryStep();
            store.commit('settings/enableShadow', value);
            saveVideo();
        }
    });

    const captionsHorizontalPosition = computed({
        get: () => {
            return settingsState.value.captions.position.alignH !== Align.CUSTOM
                ? settingsState.value.captions.position.alignH
                : parseFloat(settingsState.value.captions.position.alignX);
        },
        set: (value) => {
            let parsedValue = value.replace(',', '.'),
                position = {
                    alignH: !isNaN(parsedValue) ? Align.CUSTOM : value,
                    alignX: !isNaN(parsedValue) ? parsedValue : Dimension.ZERO
                };
            if (
                position.alignH !== settingsState.value.captions.position.alignH ||
                position.alignX !== settingsState.value.captions.position.alignX
            ) {
                startHistoryStep();
                store.commit(
                    'settings/setPosition',
                    Object.assign({}, settingsState.value.captions.position, position)
                );
                saveVideo();
            }
        }
    });

    const captionsVerticalPosition = computed({
        get: () => {
            return settingsState.value.captions.position.alignV !== Align.CUSTOM
                ? settingsState.value.captions.position.alignV
                : parseFloat(settingsState.value.captions.position.alignY);
        },
        set: (value) => {
            let parsedValue = value.replace(',', '.'),
                position = {
                    alignV: !isNaN(parsedValue) ? Align.CUSTOM : value,
                    alignY: !isNaN(parsedValue) ? parsedValue : Dimension.ZERO
                };

            if (
                position.alignV !== settingsState.value.captions.position.alignV ||
                position.alignY !== settingsState.value.captions.position.alignY
            ) {
                startHistoryStep();
                store.commit(
                    'settings/setPosition',
                    Object.assign({}, settingsState.value.captions.position, position)
                );
                saveVideo();
            }
        }
    });

    const availableAlignmentTypes = computed(() =>
        Object.fromEntries(
            Align.TEXT_ALIGNMENTS.filter((alignValue) => alignValue !== Align.TEXT_JUSTIFY).map((alignValue) => [
                alignValue,
                t('studio.text_alignments.' + alignValue)
            ])
        )
    );

    const availableFontStyles = computed(() =>
        Object.fromEntries(Font.STYLES.map((style) => [style, t('studio.font_styles.' + style)]))
    );

    return {
        brandLogoEnabled,
        brandLogoImage,
        brandLogoAnimationType,
        brandLogoOverridePosition,
        brandLogoWidth,
        brandLogoHeight,
        brandLogoHorizontalPosition,
        brandLogoVerticalPosition,

        bordersEnabled,
        enableBorders,
        bordersColor,
        bordersSize,
        borderTop,
        borderBottom,
        borderLeft,
        borderRight,

        captionsFont,
        captionsAlign,
        captionsStyle,
        captionsSize,
        captionsColor,
        captionsBackgroundColor,
        captionsShadowEnabled,
        captionsHorizontalPosition,
        captionsVerticalPosition,

        availableAlignmentTypes,
        availableFontStyles
    };
};
