<template>
    <ui-dropdown
        v-if="!readOnly && isEmotionMode"
        :id="seqId + '-add-card'"
        ref="dropdown"
        class="add-card"
        :toggle-class="['ui-action-button', !showLabel ? 'no-label' : null]"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        :tooltip="showTooltip ? $t('Add an element') : ''"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-solid fa-plus-large" />
            <span v-if="showLabel">{{ $t('Add an element') }}</span>
        </template>

        <ui-card-selector :seq-id="seqId" @[addedCardEvent]="handleNewCard" />
    </ui-dropdown>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiCardSelector, { UI_CARD_SELECTOR_ADDED_CARD } from '@/js/videos/components/ui/body/UiCardSelector.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';

const props = defineProps({
    seqId: String,
    showLabel: {
        type: Boolean,
        default: true
    },
    showTooltip: {
        type: Boolean,
        default: false
    }
});
const emit = defineEmits(['new-card']);

const store = useStore();
const { readOnly, isEmotionMode } = useVideo();
const dropdown = ref(null);

const addedCardEvent = UI_CARD_SELECTOR_ADDED_CARD;
const handleNewCard = (card) => {
    dropdown.value.hide(); // mandatory to close after click since property "menu" is false
    emit('new-card', card);
};
</script>
