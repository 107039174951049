<template>
    <ui-dropdown
        :id="seqId + '-transition'"
        :caret="false"
        :menu="false"
        :scrollable="false"
        toggle-class="ui-dropdown-action"
        :toggle-style="transitionType !== transitionDefaultType ? { color: 'var(--edit-mode-color)' } : {}"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-forward" />
            <span>{{ $t('Transition') }}</span>
        </template>

        <div class="transition-explanation">
            <fa-icon class="icon" icon="fa-solid fa-circle-info" />
            {{ $t('transition.explanation') }}
        </div>

        <ui-library-selector
            ref="$transitionSelector"
            :id="seqId + '-transition-type'"
            v-model="transitionType"
            :default-value="transitionDefaultType"
            library="transitions"
            preview-path="transitions/"
            :label="$t('Choose a transition')"
            :disabled="readOnly || !isEmotionMode"
            icon="fa-solid fa-sparkles"
            @[librarySelectorShowEvent]="openLibrary"
            @[librarySelectorPreviewChangeEvent]="previewTransitionType"
        />

        <div v-if="transitionUses.includes('scene')" class="sequence-transition-scene">
            <ui-switch-button
                :id="seqId + '-scene'"
                :label="$t('Add scene background')"
                v-model="enableTransitionScene"
                :disabled="readOnly"
            />
        </div>

        <ui-sequence-transition-colors
            v-if="transitionUses.some((use) => /^color\./.test(use))"
            :seq-id="seqId"
            :transition-uses="transitionUses"
        />

        <ui-sequence-transition-image :seq-id="seqId" :transition-uses="transitionUses" />
    </ui-dropdown>
</template>

<script setup>
import { computed, inject } from 'vue';

import UiDropdown from '@/js/components/UiDropdown.vue';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { useStore } from 'vuex';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { Transition } from '@/js/video-studio/constants/index.js';
import { STAGE_LOADING_ENDED_EVENT } from '@/js/video-studio/utils/index.js';
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';
import UiLibrarySelector from '@/js/components/UiLibrarySelector.vue';
import UiSequenceTransitionColors from '@/js/videos/components/ui/body/sequence-panel/header/transition/UiSequenceTransitionColors.vue';
import UiSequenceTransitionImage from '@/js/videos/components/ui/body/sequence-panel/header/transition/UiSequenceTransitionImage.vue';
import { UI_LIBRARY_SELECTOR_SHOW, UI_LIBRARY_SELECTOR_PREVIEW_CHANGE } from '@/js/components/UiLibrarySelector.vue';
import { useBrand } from '@/js/videos/composables/useBrand.js';

const props = defineProps({
    seqId: String
});

const store = useStore();
const { saveHistoryStep, ignoreHistoryStep } = useHistory();
const { readOnly, isEmotionMode, openLibrary } = useVideo();
const { findLibraryItem } = useBrand();
const { sequenceState, sequenceStoreModulePath } = useSequence(props.seqId);
const videoStudio = inject('$videoStudio');

const librarySelectorShowEvent = UI_LIBRARY_SELECTOR_SHOW;
const librarySelectorPreviewChangeEvent = UI_LIBRARY_SELECTOR_PREVIEW_CHANGE;
const transitionDefaultType = Transition.DEFAULT;

const transitionType = computed({
    get: () => sequenceState.value.transition.type,
    set: (type) => saveHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/setTransition`, type))
});

const transitionMetadata = computed(() => {
    if (!transitionType.value || transitionType.value === transitionDefaultType) return null;
    return findLibraryItem.value('transitions', '', transitionType.value) || null;
});

const transitionUses = computed(() => transitionMetadata.value?.uses || []);

const enableTransitionScene = computed({
    get: () => sequenceState.value.transition.scene,
    set: (value) => {
        saveHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/enableTransitionScene`, value));
    }
});

const previewTransitionType = (type, cancel) => {
    ignoreHistoryStep(() => {
        store.commit(`${sequenceStoreModulePath.value}/setTransition`, type);
        if (!cancel)
            videoStudio.value.studio.$stage.$el.addEventListener(STAGE_LOADING_ENDED_EVENT, playTransitionTypePreview, {
                once: true
            });
    });
};

const playTransitionTypePreview = () => {
    store.commit('preview/setCurrentTimelineId', props.seqId);
    videoStudio.value.studio.$stage.seekSequenceTimeline(props.seqId, 0.001);
    videoStudio.value.studio.$stage.playTimeline();
};
</script>
