<template>
    <template v-if="messageUses.some((use) => /^palette\.color/.test(use))">
        <ui-button-color-selector
            v-if="messageUses.includes('palette.color1')"
            :id="cardElementId + '-color1'"
            key="color-selector-message-color1"
            v-model:color="messageColor1"
            default-color="color1"
            :palette="brandPalette"
            :enable-other-colors="brandEnableOtherColors"
            :disabled="readOnly"
            :tooltip="$t('Color 1')"
        />
        <ui-button-color-selector
            v-if="messageUses.includes('palette.color2')"
            :id="cardElementId + '-color2'"
            key="color-selector-message-color2"
            v-model:color="messageColor2"
            default-color="color2"
            :palette="brandPalette"
            :enable-other-colors="brandEnableOtherColors"
            :disabled="readOnly"
            :tooltip="$t('Color 2')"
        />
        <ui-button-color-selector
            v-if="messageUses.includes('palette.color3')"
            :id="cardElementId + '-color3'"
            key="color-selector-message-color3"
            v-model:color="messageColor3"
            default-color="color2"
            :palette="brandPalette"
            :enable-other-colors="brandEnableOtherColors"
            :disabled="readOnly"
            :tooltip="$t('Color 3')"
        />
        <ui-button-color-selector
            v-if="messageUses.includes('palette.color4')"
            :id="cardElementId + '-color4'"
            key="color-selector-message-color4"
            v-model:color="messageColor4"
            default-color="color2"
            :palette="brandPalette"
            :enable-other-colors="brandEnableOtherColors"
            :disabled="readOnly"
            :tooltip="$t('Color 4')"
        />
        <ui-button-color-selector
            v-if="messageUses.includes('palette.color5')"
            :id="cardElementId + '-color5'"
            key="color-selector-message-color5"
            v-model:color="messageColor5"
            default-color="color2"
            :palette="brandPalette"
            :enable-other-colors="brandEnableOtherColors"
            :disabled="readOnly"
            :tooltip="$t('Color 5')"
        />
    </template>

    <ui-button-color-selector
        v-if="messageUses.includes('palette.quotes')"
        :id="cardElementId + '-quotes-color'"
        key="color-selector-message-quotes-color"
        class="ui-card-row"
        v-model:color="messageQuotesColor"
        default-color="color1"
        :palette="brandPalette"
        :enable-other-colors="brandEnableOtherColors"
        :disabled="readOnly"
        :tooltip="$t('Color 5')"
    />
</template>

<script setup>
import { useMessage } from '@/js/videos/composables/useMessage.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useBrand } from '@/js/videos/composables/useBrand.js';
import UiButtonColorSelector from '@/js/videos/components/ui/body/buttons/UiButtonColorSelector.vue';

const props = defineProps({
    seqId: String,
    cardElementId: String,
    elementId: String
});

const { readOnly } = useVideo();
const { brandPalette, brandEnableOtherColors } = useBrand();
const { messageUses, messageColor1, messageColor2, messageColor3, messageColor4, messageColor5, messageQuotesColor } =
    useMessage(props.seqId, props.elementId);
</script>
