<template>
    <ui-video-card
        :id="cardElementId"
        :card-name="$t('Visual {number}', { number })"
        classes="ui-video-card-visual"
        :selected="editing"
        @click.left="select"
    >
        <template v-if="!isBrandLogoCategory" #selector>
            <ui-sequence-visual-type-selector
                ref="$visualTypeSelector"
                :seq-id="seqId"
                :element-id="elementId"
                :card-element-id="cardElementId"
                :trigger-selection="triggerSelection"
            />
        </template>

        <template #actions-menu-content>
            <ui-card-sequence-menu-content
                :id="id"
                :seq-id="seqId"
                :enable-duplicate="true"
                :can-duplicate="canDuplicateVisual"
                :duplicate-actions="duplicateVisual"
                :remove-actions="removeVisual"
                :element-store-module-path="elementStoreModulePath"
            />
        </template>

        <template #content>
            <ui-dropdown
                :id="cardElementId + '-category'"
                :select="true"
                v-model="visualCategory"
                :disabled="readOnly"
                :key="visualTypesRender"
                menu-strategy="fixed"
                @[dropdownCategorySelectEvent]="triggerCategoryDropdown"
            >
                <template #dropdown-toggle="{ instance }">
                    <component
                        :is="getVisualIcon(instance.selectedValue.toLowerCase()).component"
                        class="icon"
                        v-if="visualCategories.includes(instance.selectedValue)"
                        :icon="getVisualIcon(instance.selectedValue.toLowerCase()).name"
                    />
                    <span>{{ instance.toggleLabel }}</span>
                </template>

                <ui-dropdown-item
                    v-for="category in visualCategories"
                    :key="category"
                    :value="category"
                    :label="$t('studio.visual_categories.' + category)"
                    :disabled="recordingDisabled(category)"
                    :show-icon-on-disabled="true"
                    v-tooltip="recordingDisabled(category) && $t('studio.features.recording-disabled')"
                >
                    <template #dropdown-menu-item-label="{ instance }">
                        <component
                            :is="getVisualIcon(instance.value.toLowerCase()).component"
                            class="icon"
                            :icon="getVisualIcon(instance.value.toLowerCase()).name"
                        />
                        {{ instance.label || instance.value }}
                    </template>
                </ui-dropdown-item>
            </ui-dropdown>
        </template>

        <template #actions-tools>
            <ui-visual-tools :seq-id="seqId" :element-id="elementId" :card-element-id="cardElementId" />
        </template>

        <template #second-row v-if="isCollageCategory">
            <ui-visual-collage-images :seq-id="seqId" :card-element-id="cardElementId" :element-id="elementId" />
        </template>
    </ui-video-card>
</template>

<script setup>
import { computed, inject, ref } from 'vue';
import UiVideoCard from '@/js/videos/components/ui/body/UiVideoCard.vue';
import { VISUAL_ELEMENT_ID } from '@/js/constants/index.js';
import UiSequenceVisualTypeSelector from '@/js/videos/components/ui/body/selectors/UiSequenceVisualTypeSelector.vue';
import { useVisual } from '@/js/videos/composables/useVisual.js';
import UiDropdown, { UI_DROPDOWN_SELECT_CHANGE } from '@/js/components/UiDropdown.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import UiDropdownItem from '@/js/components/UiDropdownItem.vue';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';
import UiCardSequenceMenuContent from '@/js/videos/components/ui/body/cards/menus/UiCardSequenceMenuContent.vue';
import UiVisualTools from '@/js/videos/components/ui/body/cards/tools/visual/UiVisualTools.vue';
import UiVisualCollageImages from '@/js/videos/components/ui/body/cards/tools/visual/UiVisualCollageImages.vue';

const vTooltip = UsesTooltip.directives.tooltip;

const props = defineProps({
    id: {
        type: String,
        default: VISUAL_ELEMENT_ID
    },
    elementId: {
        type: String,
        default: ''
    },
    seqId: String,
    editing: Boolean,
    number: {
        type: Number,
        default: 1
    }
});

const dropdownCategorySelectEvent = UI_DROPDOWN_SELECT_CHANGE;

const videoStudio = inject('$videoStudio');
const cardElementId = computed(() => `${props.seqId}-${props.elementId}`);

const { readOnly, openMediaLibrary } = useVideo();
const {
    elementStoreModulePath,
    visualCategory,
    visualCategories,
    visualTypesRender,
    canDuplicateVisual,
    removeVisual,
    duplicateVisual,
    getVisualIcon,
    recordingDisabled,
    isCollageCategory,
    isBrandLogoCategory
} = useVisual(props.seqId, props.elementId);

const triggerSelection = ref(false);
const triggerCategoryDropdown = () => (triggerSelection.value = true);

defineExpose({
    elementId: props.elementId,
    visualCategory,
    triggerCategoryDropdown,
    openMediaLibrary
});

const select = () =>
    videoStudio.value.studio.$stage.getSequenceElement(props.seqId).startEditingElement(null, props.elementId);
</script>
