<template>
    <button
        class="preview-actions-menu ui-action-button preview-action preview-action-alt preview-action-share"
        v-tooltip.light="$t('Share preview')"
        @click="openShowUrl"
    >
        <span class="action-icon">
            <fa-icon class="icon" icon="fa-regular fa-share-from-square" />
        </span>
    </button>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';

const vTooltip = UsesTooltip.directives.tooltip;

const route = useRoute();
const router = useRouter();

let previewVideoUrl;
if (route.meta.isTemplate || route.meta.isSequenceTemplate) {
    previewVideoUrl = router.resolve({
        name: route.meta.isTemplate ? 'PreviewTemplate' : 'PreviewSequenceTemplate',
        params: { id: route.params.id, brandId: route.params.brandId }
    });
} else {
    previewVideoUrl = router.resolve({ name: 'PreviewVideo', params: { id: route.params.id } });
}

const openShowUrl = () => window.open(previewVideoUrl.href);
</script>
