<template>
    <ui-dropdown
        :id="cardElementId + '-timing'"
        :menu="false"
        :caret="false"
        :scrollable="false"
        :disabled="readOnly"
        :tooltip="$t('Duration')"
        menu-placement="bottom"
        :icon-only="true"
        :toggle-style="toggleDurationStyle"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-clock" />
        </template>

        <ui-number-input
            :id="cardElementId + '-start'"
            v-model="messageStart"
            :default-value="0"
            :min="0"
            :label="$t('Starting time')"
            :disabled="readOnly"
        />
        <ui-number-input
            :id="cardElementId + '-end'"
            v-model="messageEnd"
            :default-value="messageEndDefault"
            :min="messageStart"
            :label="$t('Ending time')"
            :disabled="readOnly"
        />
    </ui-dropdown>
</template>

<script setup>
import { computed } from 'vue';
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiNumberInput from '@/js/components/UiNumberInput.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { Duration } from '@/js/video-studio/constants/index.js';
import { useMessage } from '@/js/videos/composables/useMessage.js';

const props = defineProps({
    seqId: String,
    cardElementId: String,
    elementId: String
});

const messageEndDefault = Duration.END_DEFAULT;

const { readOnly } = useVideo();
const { messageStart, messageEnd } = useMessage(props.seqId, props.elementId);

const toggleDurationStyle = computed(() => {
    if (messageStart.value !== Duration.START_DEFAULT || messageEnd.value !== '') {
        return { color: 'var(--edit-mode-color)' };
    } else return {};
});
</script>
