import { computed, inject, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { useBrand } from '@/js/videos/composables/useBrand.js';
import { Align, Color, Dimension, Duration, Panel } from '@/js/video-studio/constants/index.js';
import { getPrefixedUuid } from '@/js/utils.js';
import { STAGE_LOADING_ENDED_EVENT } from '@/js/video-studio/utils/index.js';
import { usePreview } from '@/js/videos/composables/usePreview.js';

export const usePanel = (sequenceId) => {
    const store = useStore();
    const videoStudio = inject('$videoStudio');
    const { findLibraryItem } = useBrand();
    const { saveHistoryStep, ignoreHistoryStep } = useHistory();
    const { playPreview } = usePreview();
    const { sequenceState, sequenceStoreModulePath } = useSequence(sequenceId);

    const prefixes = computed(() => store.state.ui.prefixes);

    const _cachedPanelShapeTypes = ref({});

    const panelShapeType = computed({
        get: () => sequenceState.value.panel.shape.type,
        set: (type) => {
            saveHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/setPanel`, type));
        }
    });

    const panelShapeCategory = computed({
        get: () => sequenceState.value.panel.shape.category,
        set: (category) => {
            saveHistoryStep(() => {
                let shapeSwapType = panelShapeType.value.replace(
                        new RegExp('^' + panelShapeCategory.value.replace(/^./, (c) => c.toUpperCase())),
                        category.replace(/^./, (c) => c.toUpperCase())
                    ),
                    shapeSwapItem = findLibraryItem.value('panels', category, shapeSwapType) || null;
                _cachedPanelShapeTypes.value[panelShapeCategory.value] = panelShapeType.value;

                store.commit(sequenceStoreModulePath.value + '/setPanelCategory', category);
                store.commit(
                    sequenceStoreModulePath.value + '/setPanel',
                    shapeSwapItem?.name || _cachedPanelShapeTypes.value[category] || Panel.DEFAULTS[category]
                );
            });
        }
    });

    const isCustomCategory = computed(() => panelShapeCategory.value === Panel.CUSTOM_CATEGORY);
    const isSideCategory = computed(() => panelShapeCategory.value === Panel.SIDE_CATEGORY);

    const panelShapeImage = computed({
        get: () => {
            let ref = { src: '', id: '' };

            if (isCustomCategory.value) {
                ref.src = sequenceState.value.panel.shape.src;
                ref.id = sequenceState.value.panel.shape.src__id;
            }

            return ref;
        },
        set: ({ src, id }) => {
            saveHistoryStep(() => {
                if (!sequenceState.value.panel.shape.src__ref) {
                    store.commit(
                        sequenceStoreModulePath.value + '/setPanelSourceReference',
                        getPrefixedUuid(prefixes.value.mediaReference)
                    );
                }
                store.dispatch(sequenceStoreModulePath.value + '/updatePanelSource', { src, src__id: id });
            });
        }
    });

    const playPanelShapeTypePreview = () => playPreview(sequenceId, sequenceState.value.panel.animation.start);
    const previewPanelShapeType = (type, cancel) => {
        ignoreHistoryStep(() => {
            store.commit(sequenceStoreModulePath.value + '/setPanel', type);
            if (!cancel)
                videoStudio.value.studio.$stage.$el.addEventListener(
                    STAGE_LOADING_ENDED_EVENT,
                    playPanelShapeTypePreview,
                    {
                        once: true
                    }
                );
        });
    };

    const panelStart = computed({
        get: () => sequenceState.value.panel.animation.start,
        set: (value) => {
            if (value !== panelStart.value) {
                saveHistoryStep(() => store.commit(sequenceStoreModulePath.value + '/setPanelStart', value));
            }
        }
    });

    const panelEnd = computed({
        get: () =>
            sequenceState.value.panel.animation.end !== Duration.END_DEFAULT
                ? sequenceState.value.panel.animation.end
                : '',
        set: (value) => {
            if (value !== panelEnd.value) {
                saveHistoryStep(() => store.commit(sequenceStoreModulePath.value + '/setPanelEnd', value));
            }
        }
    });

    const panelSide = computed({
        get: () => sequenceState.value.panel.position.side,
        set: (side) => {
            saveHistoryStep(() => {
                store.commit(
                    sequenceStoreModulePath.value + '/setPanelPosition',
                    Object.assign({}, sequenceState.value.panel.position, { side })
                );
            });
        }
    });

    const panelWidthDefault = computed(() => (isSideCategory.value ? Dimension.PANEL_DEFAULT : Dimension.AUTO));
    const panelWidth = computed({
        get: () =>
            sequenceState.value.panel.size.width !== Dimension.AUTO
                ? parseFloat(sequenceState.value.panel.size.width)
                : '',
        set: (value) => {
            if (value !== panelWidth.value) {
                saveHistoryStep(() => {
                    store.commit(
                        sequenceStoreModulePath.value + '/setPanelWidth',
                        value !== Dimension.AUTO ? value + Dimension.PERCENT_UNIT : value
                    );
                });
            }
        }
    });

    const panelHeight = computed({
        get: () =>
            sequenceState.value.panel.size.height !== Dimension.AUTO
                ? parseFloat(sequenceState.value.panel.size.height)
                : '',
        set: (value) => {
            if (value !== panelHeight.value) {
                saveHistoryStep(() => {
                    store.commit(
                        sequenceStoreModulePath.value + '/setPanelHeight',
                        value !== Dimension.AUTO ? value + Dimension.PERCENT_UNIT : value
                    );
                });
            }
        }
    });

    const panelRatioConstraint = computed({
        get: () => sequenceState.value.panel.size.ratioConstraint,
        set: (value) => {
            saveHistoryStep(() => store.commit(sequenceStoreModulePath.value + '/setPanelRatioConstraint', value));
        }
    });

    const panelHorizontalPosition = computed({
        get: () =>
            sequenceState.value.panel.position.alignH !== Align.CUSTOM
                ? sequenceState.value.panel.position.alignH
                : parseFloat(sequenceState.value.panel.position.alignX),
        set: (value) => {
            let parsedValue = value.replace(',', '.'),
                position = {
                    alignH: !isNaN(parsedValue) ? Align.CUSTOM : value,
                    alignX: !isNaN(parsedValue) ? parsedValue : Dimension.ZERO
                };

            if (
                position.alignH !== sequenceState.value.panel.position.alignH ||
                position.alignX !== sequenceState.value.panel.position.alignX
            ) {
                saveHistoryStep(() => {
                    store.commit(
                        sequenceStoreModulePath.value + '/setPanelPosition',
                        Object.assign({}, sequenceState.value.panel.position, position)
                    );
                });
            }
        }
    });

    const panelVerticalPosition = computed({
        get: () =>
            sequenceState.value.panel.position.alignV !== Align.CUSTOM
                ? sequenceState.value.panel.position.alignV
                : parseFloat(sequenceState.value.panel.position.alignY),
        set: (value) => {
            let parsedValue = value.replace(',', '.'),
                position = {
                    alignV: !isNaN(parsedValue) ? Align.CUSTOM : value,
                    alignY: !isNaN(parsedValue) ? parsedValue : Dimension.ZERO
                };

            if (
                position.alignV !== sequenceState.value.panel.position.alignV ||
                position.alignY !== sequenceState.value.panel.position.alignY
            ) {
                saveHistoryStep(() => {
                    store.commit(
                        sequenceStoreModulePath.value + '/setPanelPosition',
                        Object.assign({}, sequenceState.value.panel.position, position)
                    );
                });
            }
        }
    });

    const panelCanHaveBgMove = computed(
        () =>
            sequenceState.value.panel.enabled &&
            isSideCategory.value &&
            sequenceState.value.panel.opacity === Color.OPACITY_FULL
    );

    const enableColor = computed({
        get: () => sequenceState.value.panel.color.enabled,
        set: (value) => {
            saveHistoryStep(() => store.commit(sequenceStoreModulePath.value + '/enablePanelColor', value));
        }
    });

    const panelColorStart = computed({
        get: () => ({
            ref: sequenceState.value.panel.color.start__ref,
            value: sequenceState.value.panel.color.start,
            alpha: sequenceState.value.panel.opacity
        }),
        set: ({ ref, value, alpha }) => {
            saveHistoryStep(() => {
                store.commit(sequenceStoreModulePath.value + '/setPanelColor', {
                    start__ref: ref,
                    start: value
                });
                store.commit(sequenceStoreModulePath.value + '/setPanelOpacity', alpha);

                if (
                    isSideCategory.value &&
                    !panelCanHaveBgMove.value &&
                    sequenceState.value.panel.animation.enabledBgMove
                ) {
                    store.commit(sequenceStoreModulePath.value + '/enablePanelBgMove', false);
                }
            });
        }
    });

    const panelAnimationEnabled = computed({
        get: () => sequenceState.value.panel.animation.enabled,
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(sequenceStoreModulePath.value + '/enablePanelAnimation', value);
            });
        }
    });

    const panelBgMoveEnabled = computed({
        get: () => store.getters[sequenceStoreModulePath.value + '/panelHasBgMove'],
        set: (value) => {
            saveHistoryStep(() => {
                if (isSideCategory.value && !panelCanHaveBgMove.value && value) {
                    store.commit(sequenceStoreModulePath.value + '/setPanelOpacity', Color.OPACITY_FULL);
                }
                store.commit(sequenceStoreModulePath.value + '/enablePanelBgMove', value);
            });
        }
    });

    const removePanel = () => store.dispatch(sequenceStoreModulePath.value + '/showPanelCard', false);
    onMounted(() => {
        _cachedPanelShapeTypes.value = { [panelShapeCategory.value]: panelShapeType.value };
    });

    return {
        panelShapeType,
        panelShapeCategory,
        panelShapeImage,

        panelStart,
        panelEnd,

        panelSide,
        panelWidth,
        panelWidthDefault,
        panelHeight,
        panelRatioConstraint,
        panelHorizontalPosition,
        panelVerticalPosition,
        panelCanHaveBgMove,
        panelAnimationEnabled,
        panelBgMoveEnabled,

        enableColor,
        panelColorStart,

        previewPanelShapeType,

        isCustomCategory,
        isSideCategory,
        removePanel
    };
};
