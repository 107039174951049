<template>
    <ui-dropdown
        :id="cardElementId + '-track-volume-menu'"
        class="centered"
        :menu="false"
        :caret="false"
        :scrollable="false"
        :disabled="readOnly || isMediaConverting || !track.src"
        :tooltip="isMediaConverting ? $t('media.preparation.warning') : $t('Volume')"
        menu-placement="bottom"
        :icon-only="true"
        :toggle-style="toggleAudioVolumeStyle"
    >
        <template #dropdown-toggle>
            <svg-icon icon="audio-icon" />
        </template>

        <ui-range-input
            :id="cardElementId + '-track-volume'"
            v-model="trackVolume"
            :horizontal="false"
            :label="$t('Volume')"
            :disabled="readOnly"
        />
    </ui-dropdown>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import UiDropdown from '@/js/components/UiDropdown.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import UiRangeInput from '@/js/components/UiRangeInput.vue';

const props = defineProps({
    seqId: String,
    cardElementId: String
});

const store = useStore();
const { readOnly } = useVideo();
const { saveHistoryStep } = useHistory();
const { sequenceState, sequenceStoreModulePath } = useSequence(props.seqId);

const isMediaConverting = computed(() => store.getters['loading/isConverting'](track.value.src));
const track = computed(() => ({
    src: sequenceState.value.audio.track.src,
    id: sequenceState.value.audio.track.src__id
}));

const trackVolume = computed({
    get: () => Math.round(100 * store.getters[sequenceStoreModulePath.value + '/trackVolume']),
    set: (value) => {
        saveHistoryStep(() => {
            store.commit(`${sequenceStoreModulePath.value}/setTrackVolume`, {
                value: (value / 100).toFixed(2),
                custom: null
            });
        });
    }
});

const toggleAudioVolumeStyle = computed(() => {
    if (trackVolume.value !== 100) {
        return { color: 'var(--edit-mode-color)' };
    } else return {};
});
</script>
