<template>
    <ui-dropdown
        :id="cardElementId + '-effects'"
        class="centered"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        :disabled="readOnly"
        :toggle-style="toggleStyle"
        :icon-only="true"
        :tooltip="$t('Effects')"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-sparkles" />
        </template>

        <ui-switch-button
            :id="cardElementId + '-enable-shadow'"
            :label="$t('Add shadow')"
            v-model="shadowEnabled"
            :disabled="readOnly"
        />
    </ui-dropdown>
</template>

<script setup>
import { computed } from 'vue';
import UiDropdown from '@/js/components/UiDropdown.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';
import { useMessage } from '@/js/videos/composables/useMessage.js';

const props = defineProps({
    seqId: String,
    cardElementId: String,
    elementId: String
});

const { readOnly } = useVideo();
const { shadowEnabled } = useMessage(props.seqId, props.elementId);

const toggleStyle = computed(() => (shadowEnabled.value === true ? { color: 'var(--edit-mode-color)' } : {}));
</script>
