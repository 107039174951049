import { computed } from 'vue';
import { useStore } from 'vuex';

export function useDisplay() {
    const store = useStore();

    const playing = computed(() => store.state.display.timeline.playing);
    const labels = computed(() => store.state.display.timeline.labels);
    const elapsedTime = computed(() => store.state.display.timeline.elapsedTime);
    const sequenceElapsedTime = computed(() => store.state.display.timeline.sequenceElapsedTime);
    const sequenceTotalTime = computed(() => store.state.display.timeline.sequenceTotalTime);
    const seeking = computed(() => store.state.display.seeking);
    const currentFormatString = computed(() => store.getters['display/formatString']);
    const currentFormatRatio = computed(() => store.getters['display/formatRatio']);

    return {
        playing,
        labels,
        elapsedTime,
        sequenceTotalTime,
        sequenceElapsedTime,
        seeking,
        currentFormatString,
        currentFormatRatio
    };
}
