<template>
    <div class="ui-text-editor" :class="containerClasses">
        <label class="ui-text-label" :class="labelClasses" :for="editorId" :aria-hidden="!showLabel">
            <slot>{{ label }}</slot>
        </label>
        <div
            class="ui-text-editor-input"
            :class="editorClasses"
            :style="editorComputedStyles"
            :spellcheck="false"
            @transitionend.self="handleTransitionEnd"
        >
            <editor-content :editor="editor" :id="editorId" class="ui-text-editor-element" />
            <div v-if="editor" class="ui-text-editor-toolbar">
                <div class="ui-text-editor-toolbar-common">
                    <template v-if="enableFontSelection">
                        <ui-dropdown
                            :id="editorId + '-tool-font'"
                            class="font-dropdown"
                            :model-value="currentFont"
                            :select="true"
                            :caret="false"
                            :default-label="$t('Font')"
                            toggleClass="ui-simple-button"
                            :disabled="disabled"
                            :tooltip="currentFont"
                            @[dropdownChangeEvent]="handleFontChange"
                        >
                            <template #dropdown-toggle="{ instance }">
                                {{ $t('Font') }}
                            </template>

                            <ui-dropdown-item
                                v-for="font in parsedFontLibrary"
                                :key="font.name"
                                :value="font.name"
                                :style="getFontStyles(font.name)"
                            />
                        </ui-dropdown>

                        <div class="separator"></div>
                    </template>

                    <ui-dropdown
                        v-if="enableFontSize"
                        :id="editorId + '-tool-font-size'"
                        class="font-size-dropdown"
                        :model-value="currentFontSize"
                        :select="true"
                        :caret="false"
                        :scrollable="false"
                        :editable="true"
                        :editable-reg-exp="/^\d+(?:(?:\.|,)\d+)?$/"
                        :default-label="$t('Auto')"
                        toggleClass="ui-simple-button"
                        :disabled="disabled"
                        :tooltip="$t('Font size')"
                        @[dropdownChangeEvent]="handleFontSizeChange"
                    >
                        <template #dropdown-toggle="{ instance }">
                            {{ Math.round(instance.selectedValue) || $t('Auto') }}
                        </template>
                        <ui-dropdown-item key="size-auto" :label="$t('Auto')" />
                        <ui-dropdown-item
                            v-for="scale in fontScaleValues"
                            :key="'scale-' + scale"
                            :value="scale * fontSizeReference"
                        />
                    </ui-dropdown>

                    <ui-dropdown
                        :id="editorId + '-tool-align'"
                        class="align-dropdown"
                        :model-value="currentAlign"
                        :select="true"
                        :caret="false"
                        :scrollable="false"
                        :default-label="$t('Alignment')"
                        toggleClass="ui-simple-button"
                        menu-placement="bottom-start"
                        :disabled="disabled"
                        :tooltip="$t('Alignment')"
                        @[dropdownChangeEvent]="handleAlignChange"
                    >
                        <template #dropdown-toggle="{ instance }">
                            <fa-icon class="icon" icon="fa-solid fa-align-left" />
                        </template>
                        <ui-dropdown-item
                            v-for="(alignLabel, alignValue) in availableAlignmentTypes"
                            :key="alignValue"
                            :value="alignValue"
                            :label="alignLabel"
                        >
                            <fa-icon class="icon" :icon="'fa-solid fa-align-' + alignValue" />
                        </ui-dropdown-item>
                    </ui-dropdown>

                    <div class="separator"></div>

                    <button
                        class="ui-simple-button"
                        :class="{ selected: editor.isActive('bold') }"
                        :disabled="disabled"
                        :title="$t('Bold')"
                        v-tooltip="$t('Bold')"
                        @click="editor.chain().focus().toggleBold().run()"
                    >
                        <span class="visually-hidden">{{ $t('Bold') }}</span>
                        <fa-icon class="icon" icon="fa-solid fa-bold" />
                    </button>
                    <button
                        class="ui-simple-button"
                        :class="{ selected: editor.isActive('italic') }"
                        :disabled="disabled"
                        :title="$t('Italic')"
                        v-tooltip="$t('Italic')"
                        @click="editor.chain().focus().toggleItalic().run()"
                    >
                        <span class="visually-hidden">{{ $t('Italic') }}</span>
                        <fa-icon class="icon" icon="fa-solid fa-italic" />
                    </button>

                    <div class="text-color-tool">
                        <button
                            class="ui-simple-button"
                            :class="colorToolClasses"
                            :style="{ color: !disabled ? editorCurrentColor : null }"
                            :disabled="disabled"
                            :title="$t('Text color')"
                            v-tooltip="$t('Text color')"
                            @click="editor.chain().focus().setColor(editorCurrentColor).run()"
                        >
                            <span class="visually-hidden">{{ $t('Text color') }}</span>
                            <fa-icon class="icon" icon="fa-solid fa-font" />
                        </button>
                        <ui-color-selector
                            :id="editorId + '-text-color'"
                            :color="currentColor"
                            :palette="brandPalette"
                            :enable-other-colors="brandEnableOtherColors"
                            :label="$t('Choose a text color')"
                            :showLabel="false"
                            :disabled="disabled"
                            @[colorChangeEvent]="handleColorChange"
                        />
                    </div>

                    <button
                        class="ui-simple-button"
                        :disabled="disabled"
                        :title="$t('Clear formatting')"
                        v-tooltip="$t('Erase style')"
                        @click="editor.chain().focus().unsetAllMarks().run()"
                    >
                        <span class="visually-hidden">{{ $t('Clear formatting') }}</span>
                        <fa-icon class="icon" icon="fa-solid fa-eraser" />
                    </button>

                    <!--<ui-dropdown :id="editorId+'-tool-emoji'" ref="$emojiDropdown" class="emoji-dropdown" :menu="false" :caret="false" :scrollable="false"
                             :default-label="$t('Emoji')" toggleClass="ui-simple-button" menu-placement="bottom-end"
                             :disabled="disabled">
                    <template #dropdown-toggle="{ instance }">
                        <svg-icon icon="editor-emoji-icon" />
                    </template>
                    <ui-emoji-picker :id="editorId+'-emoji-picker'" :disabled="disabled" @[emojiSelectEvent]="insertEmoji" />
                </ui-dropdown>-->

                    <!-- TODO: temporary solution only -->
                    <button
                        class="ui-simple-button"
                        :disabled="disabled"
                        :title="$t('Emoji')"
                        v-tooltip="$t('Emojis')"
                        @click="openAcademyEmojiList"
                    >
                        <span class="visually-hidden">{{ $t('Emoji') }}</span>
                        <fa-icon class="icon" icon="fa-solid fa-face-grin-wide" />
                    </button>

                    <div v-if="editorHasMarks" class="separator"></div>

                    <button
                        v-if="showHighlightTool"
                        class="ui-simple-button ui-special-action-button"
                        :class="highlightToolClasses"
                        :disabled="disabled"
                        :title="$t('Highlight')"
                        @click="toggleHighlight"
                    >
                        <span class="visually-hidden">{{ $t('Highlight') }}</span>
                        <fa-icon class="icon" icon="fa-solid fa-highlighter" />
                    </button>
                    <button
                        v-if="showSeparatorTool"
                        class="ui-simple-button ui-special-action-button"
                        :disabled="disabled || !canInsertHighlightGroupSeparator"
                        :title="$t('Line separator')"
                        @click="insertSeparator"
                    >
                        <span class="visually-hidden">{{ $t('Line separator') }}</span>
                        <svg-icon icon="line-separator-icon" />
                    </button>
                    <button
                        v-if="!!marks.highlightGroup"
                        class="ui-simple-button ui-special-action-button"
                        :disabled="disabled || canInsertHighlightGroupSeparator"
                        :title="$t('Insert an example')"
                        @click="insertHighlightGroupTemplate"
                    >
                        <span class="visually-hidden">{{ $t('Insert an example') }}</span>
                        <fa-icon class="icon" icon="fa-regular fa-circle-question" />
                    </button>
                </div>
                <div class="ui-text-editor-toolbar-ai">
                    <ui-dropdown
                        id="ai-tools-actions-list"
                        :class="{ disabled: this.editor.isEmpty }"
                        toggle-class="ui-action-button"
                        :icon="true"
                        :caret="false"
                        :disabled="this.editor.isEmpty"
                        menu-placement="bottom-start"
                    >
                        <template #dropdown-toggle>
                            <svg-icon
                                v-tooltip="!this.editor.isEmpty ? $t('Improve with ai') : $t('Disabled until content')"
                                :icon="`sparkles-ai-${locale}-icon`"
                            />
                        </template>

                        <!-- Dynamic dropdown -->
                        <template v-if="!showToneSelection && !showLanguageSelection">
                            <ui-dropdown-item @click="handleEditorAction('FixSpellingAndGrammar')">
                                <template #dropdown-menu-item-label>
                                    <fa-icon class="icon" icon="fa-regular fa-spell-check" />
                                    {{ $t('Fix spelling') }}
                                </template>
                            </ui-dropdown-item>
                            <ui-dropdown-item @click="handleEditorAction('simplify')">
                                <template #dropdown-menu-item-label>
                                    <fa-icon class="icon" icon="fa-regular fa-pen-line" />
                                    {{ $t('Simplify') }}
                                </template>
                            </ui-dropdown-item>
                            <ui-dropdown-item @click="handleEditorAction('shorten')">
                                <template #dropdown-menu-item-label>
                                    <fa-icon class="icon" icon="fa-regular fa-left-long-to-line" />
                                    {{ $t('Shorten') }}
                                </template>
                            </ui-dropdown-item>
                            <ui-dropdown-item @click.stop="toggleToneOptions">
                                <template #dropdown-menu-item-label>
                                    <fa-icon class="icon" icon="fa-regular fa-microphone-stand" />
                                    {{ $t('Modify the tone') }}
                                </template>
                            </ui-dropdown-item>
                            <ui-dropdown-item @click="handleEditorAction('emoji')">
                                <template #dropdown-menu-item-label>
                                    <fa-icon class="icon" icon="fa-regular fa-face-smile-wink" />
                                    {{ $t('Enrich with emojis') }}
                                </template>
                            </ui-dropdown-item>
                            <ui-dropdown-item @click.stop="toggleLanguageOptions">
                                <template #dropdown-menu-item-label>
                                    <fa-icon class="icon" icon="fa-regular fa-language" />
                                    {{ $t('Translate the text') }}
                                </template>
                            </ui-dropdown-item>
                        </template>

                        <!-- Tone selection -->
                        <template v-else-if="showToneSelection">
                            <ui-dropdown-item @click.stop="toggleToneOptions">
                                <template #dropdown-menu-item-label>
                                    <fa-icon class="icon" icon="fa-regular fa-arrow-left" />
                                    {{ $t('Back') }}
                                </template>
                            </ui-dropdown-item>
                            <ui-dropdown-item
                                v-for="(tone, index) in toneOptions"
                                :key="index"
                                @click="handleEditorAction('tone', tone.action)"
                            >
                                <template #dropdown-menu-item-label>
                                    {{ $t(tone.label) }}
                                </template>
                            </ui-dropdown-item>
                        </template>

                        <!-- Language selection -->
                        <template v-else-if="showLanguageSelection">
                            <ui-dropdown-item @click.stop="toggleLanguageOptions">
                                <template #dropdown-menu-item-label>
                                    <fa-icon class="icon" icon="fa-regular fa-arrow-left" />
                                    {{ $t('Back') }}
                                </template>
                            </ui-dropdown-item>
                            <ui-dropdown-item
                                v-for="(language, index) in translateOptions"
                                :key="index"
                                @click="handleEditorAction('language', language.suffix)"
                            >
                                <template #dropdown-menu-item-label>
                                    {{ language.label }}
                                </template>
                            </ui-dropdown-item>
                        </template>
                    </ui-dropdown>
                </div>
            </div>
        </div>

        <span v-show="expandable && !expanded" class="ui-text-editor-expand-label">
            {{ $t('See more') }}
        </span>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import tinycolor from 'tinycolor2';
import { Editor, EditorContent } from '@tiptap/vue-3';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import HardBreak from '@tiptap/extension-hard-break';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import TextStyle from '@tiptap/extension-text-style';
import { Color as TextColor } from '@tiptap/extension-color';
// import StarterKit from '@tiptap/starter-kit';
import Ai from '@tiptap-pro/extension-ai';
import { useI18n } from 'vue-i18n';
import { parseStudioMessage, StudioMarkers, TextColorContrast, toStudioMessage } from '../extensions/tiptap';
import _debounce from 'lodash/debounce';
import _escapeRegExp from 'lodash/escapeRegExp';
import { Color, Message } from 'cte-video-studio';
import UiColorSelector, { UI_COLOR_SELECTOR_CHANGE } from './UiColorSelector.vue';
import UiDropdown, { UI_DROPDOWN_SELECT_CHANGE } from './UiDropdown.vue';
import UiDropdownItem from './UiDropdownItem.vue';
import UiEmojiPicker, { UI_EMOJI_PICKER_SELECT } from './UiEmojiPicker.vue';
import UiIcon from './UiIcon.vue';
import { UsesTooltip } from '../mixins';
import Align from '@/js/video-studio/constants/align.js';
import { useTranslateOptions } from '@/js/composables/useTranslateOptions.js';
import { toneOptions } from '@/js/constants/tiptap.js';
import Font from '@/js/video-studio/constants/font.js';

export const UI_TEXT_EDITOR_CHANGE = 'update:modelValue';
export const UI_TEXT_EDITOR_FONT_CHANGE = 'ui-text-editor-font-change';
export const UI_TEXT_EDITOR_FONT_SIZE_CHANGE = 'ui-text-editor-font-size-change';
export const UI_TEXT_EDITOR_ALIGN_CHANGE = 'ui-text-editor-align-change';
export const UI_TEXT_EDITOR_PREVIEW_CHANGE = 'ui-text-editor-preview-change';

const TEXT_EDITOR_DEFAULT_FONT_SCALES = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2];
const EMIT_EDITOR_CHANGE_DELAY = 1500;

const AiExtended = Ai.extend({
    addCommands() {
        const generateAiCommand =
            (instruction) =>
            (param, content, options = {}) =>
            ({ editor }) => {
                if (!content || !content.trim()) return false;
                const prompt = `Here's a list of instructions for rewriting a text that I'm going to give you :
• ${instruction.replace('{param}', param)}
• Keep the language of the original text. If the original is written in French, write the new text in French. If the original is in English, write the new text in English. Keep this logic for all languages.
• If the text contains HTML tags, keep them strictly unchanged (including their structure and attributes). Only modify the text they contain.
• Provide only the rewritten text, without any additional comments or explanations.

The text to modify :${content}`;

                return editor.commands.aiTextPrompt({ text: prompt }, options);
            };

        return {
            ...this.parent?.(),
            aiCustomFixSpellingAndGrammarCommand: generateAiCommand('Fix the spelling and the grammar of the text.'),
            aiCustomToneCommand: generateAiCommand('Transform the text using a {param} tone.'),
            aiCustomShortenCommand: generateAiCommand('Reduce the text by a quarter.'),
            aiCustomSimplifyCommand: generateAiCommand('Simplify the wording of the text.'),
            aiCustomEmojifyCommand: generateAiCommand('Add emojis to the text.')
        };
    }
});

export default {
    mixins: [UsesTooltip],

    setup() {
        const { locale, t } = useI18n();
        const { translateOptions } = useTranslateOptions();
        return {
            locale,
            t,
            translateOptions,
            toneOptions
        };
    },

    components: {
        EditorContent,
        UiColorSelector,
        UiDropdown,
        UiDropdownItem,
        UiEmojiPicker,
        UiIcon
    },

    emits: [
        UI_TEXT_EDITOR_CHANGE,
        UI_TEXT_EDITOR_FONT_CHANGE,
        UI_TEXT_EDITOR_FONT_SIZE_CHANGE,
        UI_TEXT_EDITOR_ALIGN_CHANGE,
        UI_TEXT_EDITOR_PREVIEW_CHANGE
    ],

    props: {
        id: {
            type: String
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        defaultValue: {
            type: [String, Number],
            default: ''
        },
        messageType: {
            type: String,
            default: Message.DEFAULT
        },
        previewing: {
            type: Boolean,
            default: false
        },
        enableFontSelection: {
            type: Boolean,
            default: false
        },
        enableFontSize: {
            type: Boolean,
            default: true
        },
        fontSize: {
            type: [String, Number],
            default: ''
        },
        align: {
            type: String,
            default: 'left'
        },
        enableAlignJustify: {
            type: Boolean,
            default: true
        },
        palette: {
            type: Object,
            default: () => ({})
        },
        marks: {
            type: Object,
            default: () => ({})
        },
        horizontal: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        editorStyles: {
            type: Object,
            default: () => ({})
        },
        font: {
            type: String,
            default: Font.DEFAULT
        },
        expandable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            showToneSelection: false,
            showLanguageSelection: false,
            streamMode: true,
            editor: null,
            hasFocus: false,
            dropdownChangeEvent: UI_DROPDOWN_SELECT_CHANGE,
            colorChangeEvent: UI_COLOR_SELECTOR_CHANGE,
            emojiSelectEvent: UI_EMOJI_PICKER_SELECT,
            fontScaleValues: TEXT_EDITOR_DEFAULT_FONT_SCALES,
            fontSizeReference: Message.FONT_SIZE_DEFAULT,
            alignmentTypes: Align.TEXT_ALIGNMENTS,
            currentFont: this.font,
            currentFontSize: this.fontSize,
            currentAlign: this.align,
            currentColor: {
                ref: Color.BLACK,
                value: Color.BLACK
            },
            highlight: true,
            expanded: false,
            transitioning: false
        };
    },

    computed: {
        ...mapState({
            prefixes: (state) => state.ui.prefixes,
            brandPalette: (state) => state.branding.palette,
            fontLibrary: (state) => state.branding.libraries.fonts,
            shortLanguage: (state) => state.ui.shortLanguage,
            brandEnableOtherColors: (state) => state.branding.enableOtherColors,
            disableRightAlignment: (state) => state.ui.restrictions.disableRightAlignment
        }),

        containerClasses() {
            return {
                horizontal: this.horizontal,
                disabled: this.disabled
            };
        },

        labelClasses() {
            return {
                'visually-hidden': !this.showLabel
            };
        },

        editorId() {
            return this.id + '-text-editor';
        },

        editorClasses() {
            return [
                'align-' + this.currentAlign,
                {
                    focus: this.hasFocus,
                    expandable: this.expandable,
                    expanded: this.expanded,
                    transitioning: this.transitioning
                }
            ];
        },

        editorComputedStyles() {
            let styles = { ...this.editorStyles };

            Object.keys(this.brandPalette).reduce((vars, color) => {
                if (!!this.brandPalette[color]) {
                    vars['--brand-palette-' + color] = this.brandPalette[color];
                }
                return vars;
            }, styles);
            Object.keys(this.palette).reduce((vars, color) => {
                if (!!this.palette[color]) {
                    vars['--palette-' + color] = this.palette[color];
                }
                return vars;
            }, styles);

            if (this.marks.highlightMark) {
                styles['--highlight-bg-color'] = this.palette.color1 || null;
                styles['--highlight-color'] = this.palette.color2 || null;
            }

            return styles;
        },

        editorCurrentColor() {
            return this.getResolvedColorProperty(this.currentColor);
        },

        colorToolClasses() {
            return {
                'non-readable-color': !tinycolor.isReadable(this.editorCurrentColor, '#fff')
            };
        },

        editorHasMarks() {
            return !!Object.keys(this.marks).length;
        },

        showHighlightTool() {
            return !!this.marks.highlightGroup || !!this.marks.highlightMark;
        },

        highlightToolClasses() {
            return {
                selected:
                    (!!this.marks.highlightGroup && this.editor.isActive('highlightGroup')) ||
                    (!!this.marks.highlightMark && this.editor.isActive('highlightMark'))
            };
        },

        showSeparatorTool() {
            return !!this.marks.highlightGroup || !!this.marks.lineSeparator;
        },

        canInsertHighlightGroupSeparator() {
            return !this.marks.highlightGroup || this.editor.can().insertHighlightGroupSeparator();
        },

        availableAlignmentTypes() {
            return Object.fromEntries(
                this.alignmentTypes
                    .filter(
                        (alignValue) =>
                            (this.enableAlignJustify || alignValue !== Align.TEXT_JUSTIFY) &&
                            (!this.disableRightAlignment || alignValue !== Align.TEXT_RIGHT)
                    )
                    .map((alignValue) => [alignValue, this.$t('studio.text_alignments.' + alignValue)])
            );
        },

        parsedFontLibrary() {
            return this.fontLibrary
                .slice()
                .sort((fontA, fontB) => fontA.name.localeCompare(fontB.name, this.shortLanguage));
        }
    },

    watch: {
        modelValue() {
            let parsedContent = this.getParsedContent();
            if (parsedContent !== this.editor.getHTML() && !this.previewing) {
                let { $from, $to } = this.editor.state.selection;
                this.editor
                    .chain()
                    .setContent(parsedContent, false, { preserveWhitespace: true })
                    .setTextSelection({ from: $from.pos, to: $to.pos })
                    .run();
            }
        },

        previewing(newValue) {
            if (!newValue) {
                if (this.messageType == this._savedMessageType) {
                    this.emitPreviewChangeEvent(this._savedValue);
                } else {
                    this.editor.commands.setContent(this.getParsedContent(), false, { preserveWhitespace: true });
                }
            } else {
                this._savedValue = this.modelValue;
                this._savedMessageType = this.messageType;
            }
        },

        messageType() {
            if (!this.previewing) {
                if (this.messageType != this._savedMessageType) {
                    this.editor.commands.setContent(this.getParsedContent(), false, { preserveWhitespace: true });
                }
                this._savedValue = null;
                this._savedMessageType = null;
            } else {
                this.emitPreviewChangeEvent();
            }
        },

        font(newValue) {
            this.currentFont = newValue;
        },

        fontSize(newValue) {
            this.currentFontSize = newValue;
        },

        align(newValue) {
            this.currentAlign = newValue;
        },

        disabled(newValue) {
            this.editor.setEditable(!newValue);
        }
    },

    methods: {
        toggleToneOptions() {
            this.showToneSelection = !this.showToneSelection;
        },
        toggleLanguageOptions() {
            this.showLanguageSelection = !this.showLanguageSelection;
        },
        getBrandPaletteReference(key) {
            return this.prefixes.settingsReference + 'palette.' + key;
        },

        getResolvedColorProperty({ ref, value }) {
            let refMatches = RegExp(
                '^' + _escapeRegExp(this.prefixes.settingsReference) + 'palette\\.(color\\d+)'
            ).exec(ref);

            return !!refMatches && refMatches[1] ? 'var(--brand-palette-' + refMatches[1] + ')' : value;
        },

        getParsedContent() {
            let parsedContent = /\S/.test(this.modelValue) ? this.modelValue : this.defaultValue;
            return parseStudioMessage(parsedContent, this.marks);
        },

        getRenderedContent() {
            let renderedContent = this.editor.getHTML();
            return toStudioMessage(renderedContent, this.marks);
        },

        handleFocus(event) {
            this.hasFocus = true;
        },

        handleBlur(event) {
            this.hasFocus = false;
        },

        emitChangeEvent() {
            this.$emit(UI_TEXT_EDITOR_CHANGE, this.getRenderedContent());
        },

        emitPreviewChangeEvent(value = null) {
            this.$emit(UI_TEXT_EDITOR_PREVIEW_CHANGE, value ?? this.getRenderedContent(), value != null);
        },

        handleFontChange(font) {
            this.currentFont = font;
            this.$emit(UI_TEXT_EDITOR_FONT_CHANGE, font);
        },

        handleFontSizeChange(fontSize) {
            this.currentFontSize = fontSize;
            this.$emit(UI_TEXT_EDITOR_FONT_SIZE_CHANGE, fontSize);
        },

        handleAlignChange(align) {
            this.currentAlign = align;
            console.log('align', align);
            this.$emit(UI_TEXT_EDITOR_ALIGN_CHANGE, align);
        },

        handleColorChange(color) {
            this.currentColor = color;

            let colorProperty = this.getResolvedColorProperty(color);
            this.editor.chain().focus().setColor(colorProperty).run();
        },

        insertEmoji(emoji) {
            this.editor.chain().focus().insertContent(emoji).run();
            this.$refs.$emojiDropdown.hide();
        },

        toggleHighlight() {
            if (this.showHighlightTool) {
                let highlightMethod = !!this.marks.highlightGroup ? 'toggleHighlightGroup' : 'toggleHighlight';
                this.editor.chain().focus()[highlightMethod]().run();
            }
        },

        insertSeparator() {
            if (this.showSeparatorTool && this.canInsertHighlightGroupSeparator) {
                let separatorMethod = !!this.marks.highlightGroup
                    ? 'insertHighlightGroupSeparator'
                    : 'insertLineSeparator';
                this.editor.chain().focus()[separatorMethod]().run();
            }
        },

        insertHighlightGroupTemplate() {
            if (!!this.marks.highlightGroup && !this.canInsertHighlightGroupSeparator) {
                this.editor
                    .chain()
                    .focus()
                    .insertHighlightGroupTemplate($t('First sentence'), this.$t('Second sentence'))
                    .run();
            }
        },

        openAcademyEmojiList() {
            window.open('https://academy.2emotion.com/emojis/', '_blank', 'noopener');
        },
        handleEditorAction(type, action) {
            const { editor, streamMode } = this;
            if (editor.isEmpty) return;

            if (editor.state.selection.empty) {
                editor.chain().focus().selectAll().run();
            }

            const textToRewrite = editor.getText();
            if (!textToRewrite.trim()) return;

            switch (type) {
                case 'FixSpellingAndGrammar':
                    editor
                        .chain()
                        .focus()
                        .aiCustomFixSpellingAndGrammarCommand(null, textToRewrite, { stream: streamMode })
                        .run();
                    break;

                case 'shorten':
                    editor.chain().focus().aiCustomShortenCommand(null, textToRewrite, { stream: streamMode }).run();
                    break;

                case 'simplify':
                    editor.chain().focus().aiCustomSimplifyCommand(null, textToRewrite, { stream: streamMode }).run();
                    break;

                case 'emoji':
                    editor.chain().focus().aiCustomEmojifyCommand(null, textToRewrite, { stream: streamMode }).run();
                    break;

                case 'tone': {
                    const toneType = action.split(':')[1];
                    if (!toneType) return;
                    editor.chain().focus().aiCustomToneCommand(toneType, textToRewrite, { stream: streamMode }).run();
                    break;
                }

                case 'language':
                    editor.chain().focus().aiTranslate(action, { stream: streamMode }).run();
                    break;

                default:
                    editor.chain().focus()[action]({ stream: streamMode }).run();
            }
        },

        expandEditor() {
            this.expanded = true;
            this.transitioning = true;
        },

        reduceEditor() {
            this.expanded = false;
            this.transitioning = true;
        },

        handleTransitionEnd(event) {
            this.transitioning = false;
        },

        getFontStyles(font) {
            return {
                '--font-thumbnail': `url(/assets/thumbnails/fonts/${font}.gif)`
            };
        }
    },

    mounted() {
        this._savedValue = null;
        this._savedMessageType = null;

        this.editor = new Editor({
            editable: !this.disabled,
            content: this.getParsedContent(),
            enableInputRules: false,
            enablePasteRules: false,
            parseOptions: {
                preserveWhitespace: true
            },
            extensions: [
                // StarterKit,
                AiExtended.configure({
                    appId: import.meta.env.VITE_TIPTAP_APP_ID,
                    token: import.meta.env.VITE_TIPTAP_APP_TOKEN
                }),
                Document,
                Paragraph,
                StudioMarkers.configure(this.marks),
                HardBreak,
                TextStyle,
                TextColor,
                Bold,
                Italic,
                Text,
                TextColorContrast
                //EmojiReplacer,
            ],
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onUpdate: _debounce(this.emitChangeEvent, EMIT_EDITOR_CHANGE_DELAY)
        });

        window.myEditor = this.editor;
    },

    beforeUnmount() {
        this.editor.destroy();
    }
};
</script>
