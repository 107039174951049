<template>
    <button
        class="ui-caption-button ui-caption-button-icon-only"
        :style="toggleQuickCutStyle"
        :disabled="readOnly || isMediaConverting || !track.src"
        v-tooltip="isMediaConverting ? $t('media.preparation.warning') : $t('Cut')"
        @click="openQuickCutTimeline"
    >
        <fa-icon icon="fa-regular fa-scissors" class="icon" />
    </button>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import UsesTooltip from '@/js/mixins/UsesTooltip.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';

const vTooltip = UsesTooltip.directives.tooltip;

const props = defineProps({
    seqId: String
});

const store = useStore();
const { readOnly } = useVideo();
const { sequenceState, sequenceStoreModulePath } = useSequence(props.seqId);

const track = computed(() => ({
    src: sequenceState.value.audio.track.src,
    id: sequenceState.value.audio.track.src__id
}));

const isMediaConverting = computed(() => store.getters['loading/isConverting'](track.value.src));

const toggleQuickCutStyle = computed(() => {
    if (
        sequenceState.value.audio.track.timerangeSegments.length > 0 &&
        (sequenceState.value.audio.track.timerangeSegments[0].start > 0 ||
            sequenceState.value.audio.track.timerangeSegments[0].end !==
                sequenceState.value.audio.track.totalDuration())
    ) {
        return { color: 'var(--edit-mode-color)' };
    }

    return null;
});

const openQuickCutTimeline = () => store.dispatch('ui/quickcut/openQuickCutForAudio', { seqId: props.seqId });
</script>
