// Editor constants
export const toneOptions = [
    { action: `tone:Professional`, label: 'Professional' },
    { action: `tone:Informal`, label: 'Informal' },
    { action: `tone:Simple and direct`, label: 'Simple and direct' },
    { action: `tone:Warm`, label: 'Warm' },
    { action: `tone:Journalistic`, label: 'Journalistic' },
    { action: `tone:Commercial`, label: 'Commercial' },
    { action: `tone:Social`, label: 'Social' }
];
