<template>
    <ui-dropdown
        :id="cardElementId + '-effects'"
        class="centered"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        :disabled="readOnly"
        :toggle-style="toggleStyle"
        :icon-only="true"
        :tooltip="$t('Effects')"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-sparkles" />
        </template>

        <ui-library-selector
            v-if="!hasCollageBackground && !hasMapZoomBackground"
            :id="cardElementId + '-bg-animation'"
            key="library-selector-bg-animation"
            v-model="backgroundAnimationType"
            :default-value="backgroundDefaultAnimationType"
            library="backgroundAnimations"
            category="background"
            preview-path="animations/background/"
            :label="$t('Animation')"
            :use-selection-label="false"
            :disabled="readOnly"
            icon="fa-regular fa-sparkles"
            @[librarySelectorShowEvent]="openLibrary"
            @[librarySelectorPreviewChangeEvent]="previewBackgroundAnimationType"
        />

        <ui-library-selector
            v-if="backgroundAnimationTypeUses.includes('animation.zoomTarget')"
            :id="'card-seq-' + seqId + '-bg-animation-zoom-target'"
            key="library-selector-bg-animation-zoom-target"
            v-model="backgroundAnimationZoomTarget"
            :default-value="backgroundDefaultAnimationZoomTarget"
            library="zoomTargets"
            preview-path="animations/zoom-target/"
            :label="$t('Zoom target')"
            :use-selection-label="false"
            :disabled="readOnly"
            @[librarySelectorShowEvent]="openLibrary"
            @[librarySelectorPreviewChangeEvent]="previewBackgroundAnimationZoomTarget"
        />

        <ui-switch-button
            v-if="hasCollageBackground"
            :id="`${cardElementId}-enable-collage-animation`"
            :label="$t('Animate')"
            v-model="collageAnimationEnabled"
            :disabled="readOnly"
        />
        <ui-switch-button
            v-if="!hasColorBackground"
            :id="`${cardElementId}-enable-blur`"
            :label="$t('Blur background')"
            v-model="enableBlur"
            :disabled="readOnly"
        />
        <ui-switch-button
            v-if="!hasColorBackground"
            :id="`${cardElementId}-enable-bg-overlay`"
            :label="$t('Add color effect')"
            v-model="enableOverlay"
            :disabled="readOnly"
        />

        <ui-background-overlay-selector :seq-id="seqId" />

        <ui-switch-button
            v-if="hasMapZoomBackground"
            :id="cardElementId + '-bg-map-zoom-enable-marker'"
            :label="$t('Show map marker')"
            v-model="enableMapZoomMarker"
            :disabled="readOnly"
        />

        <!--<ui-media-selector
            v-if="hasMapZoomBackground && enableMapZoomMarker"
            :id="cardElementId + '-bg-map-zoom-marker'"
            v-model="mapZoomMarker"
            :type="mediaTypeIcon"
            :label="$t('Custom map marker')"
            :disabled="readOnly"
            icon="fa-regular fa-image"
            @[mediaSelectorShowEvent]="openMediaLibrary"
        />-->
    </ui-dropdown>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';

import UiDropdown from '@/js/components/UiDropdown.vue';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { Align, Background, Overlay } from '@/js/video-studio/constants/index.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import UiLibrarySelector, {
    UI_LIBRARY_SELECTOR_SHOW,
    UI_LIBRARY_SELECTOR_PREVIEW_CHANGE
} from '@/js/components/UiLibrarySelector.vue';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { STAGE_LOADING_ENDED_EVENT } from '@/js/video-studio/utils/index.js';
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';
import { useBrand } from '@/js/videos/composables/useBrand.js';
import UiBackgroundOverlaySelector from '@/js/videos/components/ui/body/UiBackgroundOverlaySelector.vue';
import UiMediaSelector, { UI_MEDIA_TYPE_ICON, UI_MEDIA_SELECTOR_SHOW } from '@/js/components/UiMediaSelector.vue';
import { getPrefixedUuid } from '@/js/utils.js';

const props = defineProps({
    seqId: String,
    cardElementId: String
});

const videoStudio = inject('$videoStudio');

const store = useStore();
const { readOnly, openLibrary, openMediaLibrary } = useVideo();
const { isBrandLibraryEmpty } = useBrand();
const { saveHistoryStep, ignoreHistoryStep } = useHistory();
const {
    backgroundVideo,
    resolvedBackgroundState,
    sequenceStoreModulePath,
    sequenceState,
    hasColorBackground,
    hasCollageBackground,
    hasMapZoomBackground,
    backgroundType,
    backgroundAnimationType,
    backgroundAnimationTypeUses
} = useSequence(props.seqId);

const toggleStyle = computed(() => {
    if (
        backgroundAnimationType.value !== Background.ANIMATION_DEFAULT ||
        (hasCollageBackground.value && collageAnimationEnabled.value) ||
        (!hasColorBackground.value && enableBlur.value) ||
        (!hasColorBackground.value && enableOverlay.value) ||
        (hasMapZoomBackground.value && enableMapZoomMarker.value)
    ) {
        return { color: 'var(--edit-mode-color)' };
    } else return {};
});

const backgroundDefaultAnimationType = Background.ANIMATION_DEFAULT;
const librarySelectorShowEvent = UI_LIBRARY_SELECTOR_SHOW;
const librarySelectorPreviewChangeEvent = UI_LIBRARY_SELECTOR_PREVIEW_CHANGE;
const backgroundDefaultAnimationZoomTarget = Align.CENTER;
const mediaSelectorShowEvent = UI_MEDIA_SELECTOR_SHOW;
const mediaTypeIcon = UI_MEDIA_TYPE_ICON;

const prefixes = computed(() => store.state.ui.prefixes);
const collageAnimationEnabled = computed({
    get: () => sequenceState.value.background.collage.animated,
    set: (value) => {
        saveHistoryStep(() => {
            store.commit(`${sequenceStoreModulePath.value}/setBackgroundCollageAnimation`, value);
        });
    }
});

const enableBlur = computed({
    get: () => resolvedBackgroundState.value.blur,
    set: (value) => {
        saveHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/enableBackgroundBlur`, value));
    }
});

const overlayType = computed({
    get: () => resolvedBackgroundState.value.overlay.type,
    set: (value) => {
        saveHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/setBackgroundOverlay`, value));
    }
});

const enableOverlay = computed({
    get: () => resolvedBackgroundState.value.overlay.enabled,
    set: (value) => {
        saveHistoryStep(() => {
            store.commit(`${sequenceStoreModulePath.value}/enableBackgroundOverlay`, value);
            if (value && isBrandLibraryEmpty.value('overlays') && overlayType.value !== Overlay.DEFAULT) {
                store.commit(`${sequenceStoreModulePath.value}/setBackgroundOverlay`, Overlay.DEFAULT);
            }
        });
    }
});

const enableMapZoomMarker = computed({
    get: () => resolvedBackgroundState.value.mapZoom.marker.enabled,
    set(value) {
        saveHistoryStep(() => store.commit(sequenceStoreModulePath.value + '/enableBackgroundMapZoomMarker', value));
    }
});

const mapZoomMarker = computed({
    get: () => {
        let ref = { src: '', id: '' };

        if (hasMapZoomBackground.value && enableMapZoomMarker.value) {
            ref.src = resolvedBackgroundState.value.mapZoom.marker.src;
            ref.id = resolvedBackgroundState.value.mapZoom.marker.src__id;
        }

        return ref;
    },
    set({ src, id }) {
        saveHistoryStep(() => {
            if (!resolvedBackgroundState.value.mapZoom.marker.src__ref) {
                store.commit(
                    sequenceStoreModulePath.value + '/setBackgroundMapZoomMarkerReference',
                    getPrefixedUuid(prefixes.value.mediaReference)
                );
            }
            store.dispatch(sequenceStoreModulePath.value + '/updateBackgroundMapZoomMarker', { src, src__id: id });
        });
    }
});

const backgroundAnimationZoomTarget = computed({
    get: () =>
        (backgroundType.value === Background.IMAGE_TYPE ||
            backgroundType.value === Background.VIDEO_TYPE ||
            backgroundType.value === Background.ANIMATED_TYPE ||
            backgroundType.value === Background.RECORDING_TYPE) &&
        resolvedBackgroundState.value.animation.zoomTarget,
    set: (zoomTarget) =>
        saveHistoryStep(() =>
            store.commit(sequenceStoreModulePath.value + '/setBackgroundAnimationZoom', { zoomTarget })
        )
});

const previewBackgroundAnimationType = (type, cancel) => {
    ignoreHistoryStep(() => {
        store.commit(`${sequenceStoreModulePath.value}/setBackgroundAnimation`, type);
        if (!cancel)
            videoStudio.value.studio.$stage.$el.addEventListener(
                STAGE_LOADING_ENDED_EVENT,
                playBackgroundAnimationTypePreview,
                { once: true }
            );
    });
};

const previewBackgroundAnimationZoomTarget = (zoomTarget, cancel) => {
    ignoreHistoryStep(() => {
        store.commit(sequenceStoreModulePath.value + '/setBackgroundAnimationZoom', { zoomTarget });
        if (!cancel)
            videoStudio.value.studio.$stage.$el.addEventListener(
                STAGE_LOADING_ENDED_EVENT,
                playBackgroundAnimationTypePreview,
                { once: true }
            );
    });
};

const playBackgroundAnimationTypePreview = () => {
    store.commit('preview/setCurrentTimelineId', props.seqId);
    videoStudio.value.studio.$stage.seekSequenceTimeline(props.seqId, 0.001);
    videoStudio.value.studio.$stage.playTimeline();
};
</script>
