import { computed, inject, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { Color, Duration, Visual } from '@/js/video-studio/constants/index.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { getPrefixedUuid } from '@/js/utils.js';
import { useBrand } from '@/js/videos/composables/useBrand.js';
import { STAGE_LOADING_ENDED_EVENT } from '@/js/video-studio/utils/index.js';
import { usePreview } from '@/js/videos/composables/usePreview.js';
import { MAX_NB_OF_VISUALS_PER_SEQUENCES, RECORDING_ELEMENT_ID, VISUAL_ELEMENT_ID } from '@/js/constants/index.js';
import { useCard } from '@/js/videos/composables/useCard.js';
import { v4 as uuidv4 } from 'uuid';
import { useElement } from '@/js/videos/composables/useElement.js';

export const useVisual = (sequenceId, elementId) => {
    const videoStudio = inject('$videoStudio');

    const store = useStore();
    const { t } = useI18n();
    const { playPreview } = usePreview();
    const { canDuplicateCard } = useCard(sequenceId, VISUAL_ELEMENT_ID);
    const { brandPalette, isBrandLibraryEmpty, findLibraryItem } = useBrand();
    const { isEmotionMode, restrictions, saveVideo } = useVideo();
    const { saveHistoryStep, ignoreHistoryStep, startHistoryStep } = useHistory();
    const { sequenceStoreModulePath } = useSequence(sequenceId);
    const { elementState, elementStoreModulePath } = useElement(sequenceId, elementId);

    const isMediaConverting = computed(
        () =>
            (isRecordingCategory.value && store.getters['loading/isConverting'](visualRecording.value.src)) ||
            (isVideoCategory.value && store.getters['loading/isConverting'](visualVideo.value.src))
    );

    const prefixes = computed(() => store.state.ui.prefixes);

    // visual categories
    const isBrandLogoCategory = computed(() => visualCategory.value === Visual.BRAND_LOGO_CATEGORY);
    const isImageCategory = computed(() => visualCategory.value === Visual.IMAGE_CATEGORY);
    const isVideoCategory = computed(() => visualCategory.value === Visual.VIDEO_CATEGORY);
    const isIconCategory = computed(() => visualCategory.value === Visual.ICON_CATEGORY);
    const isAnimatedCategory = computed(() => visualCategory.value === Visual.ANIMATED_CATEGORY);
    const isRecordingCategory = computed(() => visualCategory.value === Visual.RECORDING_CATEGORY);
    const isCollageCategory = computed(() => visualCategory.value === Visual.COLLAGE_CATEGORY);
    const isLottieCategory = computed(() => visualCategory.value === Visual.LOTTIE_CATEGORY);

    const isColorizable = computed(
        () =>
            isBrandLogoCategory.value ||
            isImageCategory.value ||
            isAnimatedCategory.value ||
            isLottieCategory.value ||
            isVideoCategory.value ||
            isIconCategory.value ||
            isRecordingCategory.value
    );

    const visualTypesRender = ref(0);

    const canDuplicateVisual = computed(() => {
        const visualsLength = store.getters['sequences/' + sequenceId + '/visuals'].length;

        return (
            canDuplicateCard.value &&
            visualsLength < MAX_NB_OF_VISUALS_PER_SEQUENCES &&
            (restrictions.value.maxSequenceVisualCards === null ||
                visualsLength < restrictions.value.maxSequenceVisualCards)
        );
    });
    const removeVisual = () => store.dispatch(sequenceStoreModulePath.value + '/removeElement', elementId);
    const duplicateVisual = () => {
        videoStudio.value.studio.$stage.pauseTimeline();
        startHistoryStep();
        store.dispatch('sequences/' + sequenceId + '/duplicateVisual', {
            newId: Visual.PREFIX_ID + uuidv4(),
            modelId: elementId
        });
        saveVideo();
    };

    const visualAnimationEnabled = computed({
        get: () => elementState.value.animation.enabled,
        set: (value) => {
            saveHistoryStep(() => store.commit(elementStoreModulePath.value + '/enableAnimation', value));
        }
    });

    const visualImage = computed({
        get: () => {
            let ref = { src: '', id: '' };

            if (isImageCategory.value || isIconCategory.value) {
                ref.src = elementState.value.image.src;
                ref.id = elementState.value.image.src__id;
            }

            return ref;
        },
        set: ({ src, id }) => {
            saveHistoryStep(() => {
                if (!elementState.value.image.src__ref) {
                    store.commit(
                        elementStoreModulePath.value + '/image/setSourceRef',
                        getPrefixedUuid(prefixes.value.mediaReference)
                    );
                }
                store.dispatch(elementStoreModulePath.value + '/image/update', { src, src__id: id });
                store.dispatch(elementStoreModulePath.value + '/resetAsset');
            });
        }
    });

    const visualVideo = computed({
        get: () => {
            let ref = { src: '', id: '' };

            if (isVideoCategory.value) {
                ref.src = elementState.value.video.src;
                ref.id = elementState.value.video.src__id;
            }

            return ref;
        },
        set({ src, id }) {
            // reset video timerange segments and timerange if the video source is changed for UiQuickCut
            if (this.getMediaSrc !== src) {
                store.commit(`${elementStoreModulePath.value}/video/setTimerangeSegments`, []);
                store.commit(`${elementStoreModulePath.value}/video/setTimerange`, { start: 0, end: 0 });
            }

            saveHistoryStep(() => {
                if (!elementState.value.video.src__ref) {
                    store.commit(
                        elementStoreModulePath.value + '/video/setSourceRef',
                        getPrefixedUuid(prefixes.value.mediaReference)
                    );
                }
                store.dispatch(elementStoreModulePath.value + '/video/update', { src, src__id: id });
                store.dispatch(elementStoreModulePath.value + '/resetAsset');
            });
        }
    });

    const visualRecording = computed({
        get: () => {
            let ref = { src: '', id: '' };

            if (isRecordingCategory.value) {
                ref.src = elementState.value.video.src;
                ref.id = elementState.value.video.src__id;
            }

            return ref;
        },
        set({ src, id }) {
            saveHistoryStep(() => {
                if (!elementState.value.video.src__ref) {
                    store.commit(
                        elementStoreModulePath.value + '/video/setSourceRef',
                        getPrefixedUuid(prefixes.value.mediaReference)
                    );
                }
                store.dispatch(elementStoreModulePath.value + '/video/update', { src, src__id: id });
                store.dispatch(elementStoreModulePath.value + '/resetAsset');
            });
        }
    });

    const visualType = computed({
        get: () => elementState.value.animation.type,
        set: (type) => {
            saveHistoryStep(() => store.commit(elementStoreModulePath.value + '/setAnimation', type));
        }
    });

    const visualCategory = computed({
        get: () => {
            return elementState.value.animation.category !== Visual.IMAGE_CATEGORY
                ? elementState.value.animation.category
                : (elementState.value.image.useBranding && Visual.BRAND_LOGO_CATEGORY) ||
                      elementState.value.animation.category;
        },
        set: (category) => {
            saveHistoryStep(() => {
                store.commit(
                    elementStoreModulePath.value + '/image/useBranding',
                    category === Visual.BRAND_LOGO_CATEGORY
                );
                if (category === Visual.BRAND_LOGO_CATEGORY) category = Visual.IMAGE_CATEGORY;

                let visualRef = {};
                switch (elementState.value.animation.category) {
                    case Visual.IMAGE_CATEGORY:
                    case Visual.ICON_CATEGORY:
                        visualRef = { src: visualImage.value.src, src__id: visualImage.value.id };
                        break;
                    case Visual.VIDEO_CATEGORY:
                        visualRef = { src: visualVideo.value.src, src__id: visualVideo.value.id };
                        break;
                    case Visual.RECORDING_CATEGORY:
                        visualRef = { src: visualRecording.value.src, src__id: visualRecording.value.id };
                        break;
                }

                _cachedVisualTypes.value[elementState.value.animation.category] = visualType.value;
                _cachedVisualReference.value[elementState.value.animation.category] = visualRef;

                let visualSrcAction = '';
                switch (category) {
                    case Visual.IMAGE_CATEGORY:
                    case Visual.ICON_CATEGORY:
                        visualSrcAction = 'image/update';
                        break;
                    case Visual.VIDEO_CATEGORY:
                    case Visual.RECORDING_CATEGORY:
                        visualSrcAction = 'video/update';
                        break;
                }

                store.commit(elementStoreModulePath.value + '/setAnimationCategory', category);
                store.commit(
                    elementStoreModulePath.value + '/setAnimation',
                    _cachedVisualTypes.value[category] || visualDefaultTypes.value[category]
                );

                store.dispatch(elementStoreModulePath.value + '/resetImageAndVideo');
                if (!!visualSrcAction && _cachedVisualReference.value[category]) {
                    store.dispatch(
                        elementStoreModulePath.value + '/' + visualSrcAction,
                        _cachedVisualReference.value[category]
                    );
                }

                if (category === Visual.LOTTIE_CATEGORY) {
                    store.commit(elementStoreModulePath.value + '/setColor', {
                        start: brandPalette.value.color1,
                        second: brandPalette.value.color2
                    });
                }
            });
        }
    });

    const visualCategories = computed(() => {
        let categories = [...Visual.CATEGORIES];
        categories.splice(categories.indexOf(Visual.BLUR_CATEGORY), 1);
        categories = categories.filter(
            (category) =>
                ![Visual.ANIMATED_CATEGORY, Visual.LOTTIE_CATEGORY].includes(category) ||
                !isBrandLibraryEmpty.value('visuals', category)
        );

        if (!isEmotionMode.value) {
            let cat1 = [Visual.ANIMATED_CATEGORY, Visual.ICON_CATEGORY, Visual.BRAND_LOGO_CATEGORY],
                cat2 = [
                    Visual.IMAGE_CATEGORY,
                    Visual.VIDEO_CATEGORY,
                    Visual.COLLAGE_CATEGORY,
                    Visual.RECORDING_CATEGORY
                ],
                cat3 = [Visual.LOTTIE_CATEGORY];
            categories = categories.filter(
                (category) =>
                    (cat1.includes(category) && cat1.includes(visualCategory.value)) ||
                    (cat2.includes(category) && cat2.includes(visualCategory.value)) ||
                    (cat3.includes(category) && cat3.includes(visualCategory.value))
            );
        }

        return categories;
    });
    watch(visualCategories, () => visualTypesRender.value++);

    const enableVisualColor = computed({
        get: () => elementState.value.color.enabled,
        set: (value) => {
            saveHistoryStep(() => store.commit(elementStoreModulePath.value + '/enableColor', value));
        }
    });

    const visualOpacity = computed({
        get: () => Math.round(100 * store.getters[elementStoreModulePath.value + '/opacity']),
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(elementStoreModulePath.value + '/setOpacity', {
                    value: (value / 100).toFixed(2),
                    custom: null
                });
            });
        }
    });

    const visualColorStart = computed({
        get: () => {
            let color = { ref: Color.NONE, value: Color.NONE };

            if (isColorizable.value) {
                color.ref = elementState.value.color.start__ref;
                color.value = elementState.value.color.start;
            }

            return color;
        },
        set: ({ ref, value }) => {
            saveHistoryStep(() => {
                store.commit(
                    elementStoreModulePath.value + '/setColor',
                    Object.assign({}, elementState.value.color, { start__ref: ref, start: value })
                );
            });
        }
    });

    const visualColorSecond = computed({
        get: () => {
            let color = { ref: Color.NONE, value: Color.NONE };

            if (isColorizable.value) {
                color.ref = elementState.value.color.second__ref;
                color.value = elementState.value.color.second;
            }

            return color;
        },
        set: ({ ref, value }) => {
            saveHistoryStep(() => {
                store.commit(
                    elementStoreModulePath.value + '/setColor',
                    Object.assign({}, elementState.value.color, { second__ref: ref, second: value })
                );
            });
        }
    });

    const visualColorThird = computed({
        get: () => {
            let color = { ref: Color.NONE, value: Color.NONE };

            if (isColorizable.value) {
                color.ref = elementState.value.color.third__ref;
                color.value = elementState.value.color.third;
            }

            return color;
        },
        set: ({ ref, value }) => {
            saveHistoryStep(() => {
                store.commit(
                    elementStoreModulePath.value + '/setColor',
                    Object.assign({}, elementState.value.color, { third__ref: ref, third: value })
                );
            });
        }
    });

    const defaultThirdColor = computed(() => (isLottieCategory.value ? Color.CARTOON_THIRD_COLOR : ''));
    const thirdColorPalette = computed(() => {
        let palette = brandPalette.value;

        if (isLottieCategory.value) {
            palette = {};
            Color.SKINS.forEach((color, key) => {
                palette['color' + (key + 1)] = color;
            });
        }
        return palette;
    });

    const visualStart = computed({
        get: () => store.getters[elementStoreModulePath.value + '/start'],
        set: (value) => {
            if (value !== visualStart.value) {
                saveHistoryStep(() => {
                    if (visualEnd.value !== '' && value > visualEnd.value) {
                        store.commit(elementStoreModulePath.value + '/setEnd', {
                            value: value + (visualEnd.value - visualStart.value),
                            custom: Duration.NONE
                        });
                    }

                    store.commit(elementStoreModulePath.value + '/setStart', { value, custom: Duration.NONE });
                });
            }
        }
    });

    const visualEnd = computed({
        get: () =>
            store.getters[elementStoreModulePath.value + '/end'] !== Duration.END_DEFAULT
                ? store.getters[elementStoreModulePath.value + '/end']
                : '',
        set: (value) => {
            if (value !== visualEnd.value) {
                saveHistoryStep(() =>
                    store.commit(elementStoreModulePath.value + '/setEnd', { value, custom: Duration.NONE })
                );
            }
        }
    });

    const volume = computed({
        get: () => Math.round(100 * elementState.value.video.volume),
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${elementStoreModulePath.value}/video/setVolume`, value / 100);
            });
        }
    });

    const visualCollageImages = computed({
        get: () => {
            let index,
                images = [];

            for (index = 0; index < Visual.COLLAGE_MAX_MEDIA; index++) {
                images[index] = {
                    src: store.getters[elementStoreModulePath.value + '/collageImage'](index + 1),
                    id: store.getters[elementStoreModulePath.value + '/collageImageId'](index + 1)
                };
            }
            return images;
        },
        set: (images) => {
            let imagesStore = images.reduce((_store, image, index) => {
                if (image.id !== store.getters[elementStoreModulePath.value + '/collageImageId'](index + 1)) {
                    _store['image' + (index + 1)] = image.src;
                    _store['image' + (index + 1) + '__id'] = image.id;
                    if (!store.getters[elementStoreModulePath.value + '/collageImageRef'](index + 1)) {
                        _store['image' + (index + 1) + '__ref'] = getPrefixedUuid(prefixes.value.mediaReference);
                    }
                }

                return _store;
            }, {});

            if (!!Object.keys(imagesStore).length) {
                saveHistoryStep(() => {
                    store.commit(elementStoreModulePath.value + '/setCollageImageReferences', imagesStore);
                    store.dispatch(elementStoreModulePath.value + '/updateCollageImages', imagesStore);
                });
            }
        }
    });

    const setCollageImage = (index, media) => {
        let images = [...visualCollageImages.value];
        images[index] = media;
        visualCollageImages.value = [...images];
    };

    const visualCategoryPreviewPath = computed(() => {
        let categoryFolder =
            visualCategory.value === Visual.BRAND_LOGO_CATEGORY || visualCategory.value === Visual.ICON_CATEGORY
                ? Visual.IMAGE_CATEGORY
                : visualCategory.value === Visual.RECORDING_CATEGORY
                  ? Visual.VIDEO_CATEGORY
                  : visualCategory.value;

        return 'visuals/' + categoryFolder + '/';
    });

    const visualCategoryForLibrary = computed(() =>
        visualCategory.value !== Visual.RECORDING_CATEGORY
            ? elementState.value.animation.category
            : Visual.VIDEO_CATEGORY
    );

    const visualDefaultTypes = computed(() =>
        visualCategories.value.reduce((types, category) => {
            switch (category) {
                case Visual.BRAND_LOGO_CATEGORY:
                case Visual.IMAGE_CATEGORY:
                case Visual.ICON_CATEGORY:
                case Visual.VIDEO_CATEGORY:
                case Visual.ANIMATED_CATEGORY:
                case Visual.RECORDING_CATEGORY:
                    types[category] = Visual.DEFAULT;
                    break;
                case Visual.BLUR_CATEGORY:
                    types[category] = Visual.BLUR_ANIMATION_NAME;
                    break;
                case Visual.COLLAGE_CATEGORY:
                    types[category] = Visual.COLLAGE_DEFAULT;
                    break;
                case Visual.LOTTIE_CATEGORY:
                    types[category] = Visual.LOTTIE_DEFAULT;
                    break;
            }
            return types;
        }, {})
    );

    const visualTypeDefaultLabel = computed(() =>
        visualCategories.value.reduce((labels, category) => {
            switch (category) {
                case Visual.BRAND_LOGO_CATEGORY:
                case Visual.IMAGE_CATEGORY:
                case Visual.ICON_CATEGORY:
                case Visual.VIDEO_CATEGORY:
                case Visual.RECORDING_CATEGORY:
                    labels[category] = t('Choose an animation');
                    break;
                case Visual.ANIMATED_CATEGORY:
                    labels[category] = t('Choose a visual');
                    break;
                default:
                    labels[category] = '';
                    break;
            }

            return labels;
        }, {})
    );

    const visualMetadata = computed(
        () => findLibraryItem.value('visuals', visualCategory.value, visualType.value) || null
    );
    const visualUses = computed(() => visualMetadata.value?.uses || []);

    const visualVideoCaptions = computed({
        get: () => (isVideoCategory.value && elementState.value.video.captions) || [],
        set: (value) => {
            saveHistoryStep(() => store.commit(elementStoreModulePath.value + '/video/setCaptions', value));
        }
    });
    const resetVisualVideoCaptions = () => (visualVideoCaptions.value = []);

    const playVisualTypePreview = () =>
        playPreview(sequenceId, store.getters[elementStoreModulePath.value + '/start'] || 0.001);
    const previewVisualType = (type, cancel) => {
        ignoreHistoryStep(() => {
            store.commit(elementStoreModulePath.value + '/setAnimation', type);

            if (!cancel)
                videoStudio.value.studio.$stage.$el.addEventListener(STAGE_LOADING_ENDED_EVENT, playVisualTypePreview, {
                    once: true
                });
        });
    };

    const getVisualIcon = (category) => {
        let component = 'fa-icon',
            name = '';

        switch (category) {
            case Visual.LOTTIE_CATEGORY:
                name = 'fa-solid fa-person-running';
                break;
            case Visual.COLLAGE_CATEGORY:
                name = 'fa-regular fa-table-layout';
                break;
            case Visual.ICON_CATEGORY:
                name = 'fa-regular fa-badge-check';
                break;
            case Visual.ANIMATED_CATEGORY:
                name = 'fa-regular fa-note-sticky';
                break;
            case Visual.VIDEO_CATEGORY:
                name = 'fa-regular fa-circle-play';
                break;
            case Visual.IMAGE_CATEGORY:
                name = 'fa-regular fa-image';
                break;
            case Visual.BRAND_LOGO_CATEGORY:
                name = 'fa-regular fa-hexagon-image';
                break;
            case Visual.RECORDING_CATEGORY:
                component = 'svg-icon';
                name = 'recording-icon';
                break;
        }

        return { component, name };
    };

    const recordingDisabled = (visualCategory) =>
        visualCategory === RECORDING_ELEMENT_ID && !store.state.auth.user.client.enableRecording;

    const _cachedVisualTypes = ref({});
    const _cachedVisualReference = ref({});
    onMounted(() => {
        _cachedVisualTypes.value = { [visualCategory.value]: visualType.value };
        _cachedVisualReference.value = {
            [visualCategory.value]:
                isImageCategory.value || isIconCategory.value
                    ? { src: visualImage.value.src, src__id: visualImage.value.id }
                    : isVideoCategory.value
                      ? { src: visualVideo.value.src, src__id: visualVideo.value.id }
                      : isRecordingCategory.value
                        ? { src: visualRecording.value.src, src__id: visualRecording.value.id }
                        : {}
        };
    });

    return {
        elementState,
        elementStoreModulePath,
        visualTypesRender,
        isMediaConverting,

        canDuplicateVisual,
        removeVisual,
        duplicateVisual,

        isBrandLogoCategory,
        isImageCategory,
        isVideoCategory,
        isIconCategory,
        isAnimatedCategory,
        isRecordingCategory,
        isCollageCategory,
        isLottieCategory,

        visualAnimationEnabled,
        visualCategories,
        visualCategory,
        visualImage,
        visualVideo,
        visualRecording,
        visualType,

        enableVisualColor,
        visualOpacity,
        visualColorStart,
        visualColorSecond,
        visualColorThird,
        defaultThirdColor,
        thirdColorPalette,

        visualStart,
        visualEnd,
        volume,
        visualCollageImages,
        setCollageImage,

        visualCategoryPreviewPath,
        visualDefaultTypes,
        visualTypeDefaultLabel,
        visualCategoryForLibrary,

        visualUses,

        resetVisualVideoCaptions,
        visualVideoCaptions,

        previewVisualType,

        getVisualIcon,
        recordingDisabled
    };
};
