<template>
    <ui-dropdown
        :id="dropdownId"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        ref="dropdown"
        :icon-only="true"
        class="ui-dropdown-color-selector"
        :toggle-style="colorPreviewStyles"
        v-tooltip="tooltip"
    >
        <template #dropdown-toggle>
            <div class="ui-dropdown-color-selector-toggle"></div>
        </template>

        <ui-color-picker
            ref="picker"
            :id="pickerId"
            :class="pickerClasses"
            v-model="colorObject"
            :default-color="defaultColor"
            :palette="palette"
            :enable-empty="enableEmpty"
            :color-reference="colorReference"
            :enable-alpha="enableAlpha"
            :default-alpha="defaultAlpha"
            :enable-other-colors="enableOtherColors"
            :is-brand-palette="isBrandPalette"
            @mousedown.stop
        />
    </ui-dropdown>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiColorPicker from '@/js/components/UiColorPicker.vue';
import { Color } from '@/js/video-studio/constants/index.js';
import { types } from '@/js/video-studio/utils/index.js';
import { useStore } from 'vuex';
import tinycolor from 'tinycolor2';

const UI_COLOR_SELECTOR_CHANGE = 'update:color';

const vTooltip = UsesTooltip.directives.tooltip;

const props = defineProps({
    id: String,
    color: {
        type: Object,
        default: () => ({
            ref: Color.NONE,
            value: Color.NONE,
            alpha: Color.OPACITY_DEFAULT
        })
    },
    defaultColor: {
        type: String,
        default: Color.BLACK
    },
    palette: {
        type: Object,
        default: () => ({})
    },
    enableEmpty: {
        type: Boolean,
        default: false
    },
    enableAlpha: {
        type: Boolean,
        default: false
    },
    defaultAlpha: {
        type: Number,
        default: Color.OPACITY_DEFAULT
    },
    enableOtherColors: {
        type: Boolean,
        default: false
    },
    isBrandPalette: {
        type: Boolean,
        default: true
    },
    tooltip: String
});
const emit = defineEmits([UI_COLOR_SELECTOR_CHANGE]);

const store = useStore();
const prefixes = computed(() => store.state.ui.prefixes);

const picker = ref(null);
const dropdown = ref(null);

const dropdownId = computed(() => props.id + '-selector');
const pickerId = computed(() => props.id + '-picker');
const pickerClasses = computed(() => ({ show: dropdown.value?.show || false }));

const colorReference = computed(() => {
    return !!props.color.value
        ? props.color.ref
        : props.palette[props.defaultColor]
          ? getPaletteReference(props.defaultColor)
          : props.defaultColor;
});
const isEmpty = computed(() => props.enableEmpty && props.color.value === Color.NONE);

const resolvedDefaultColor = computed(() => getResolvedDefaultColor(props.defaultColor));

const colorObject = computed({
    get: () => {
        return {
            hex: props.color.value || resolvedDefaultColor.value,
            a: props.color.alpha > 0 || props.color.alpha === 0 ? props.color.alpha : props.defaultAlpha
        };
    },
    set: (newColor) => {
        let newValue = {
            ref: picker.value.selectedPaletteColor || newColor.hex.toLowerCase(),
            value: newColor.hex.toLowerCase()
        };
        if (props.enableAlpha) newValue.alpha = newColor.a;

        if (!isEqualColor(newValue, props.color)) {
            emit(UI_COLOR_SELECTOR_CHANGE, newValue);
        }
    }
});

const colorPreviewStyles = computed(() => ({
    '--selected-color': isEmpty.value ? 'none' : colorPreview.value.hex,
    '--selected-alpha': props.enableAlpha ? colorPreview.value.a : Color.OPACITY_DEFAULT
}));

const isEqualColor = ({ ref: refA, value: colorA, alpha: alphaA }, { ref: refB, value: colorB, alpha: alphaB }) => {
    return (
        tinycolor.equals(refA, refB) && tinycolor.equals(colorA, colorB) && (!props.enableAlpha || alphaA === alphaB)
    );
};

const getResolvedDefaultColor = (defaultColor) => {
    return types.isColor(defaultColor)
        ? defaultColor
        : store.state.branding.palette[defaultColor] || (props.enableEmpty ? Color.NONE : Color.BLACK);
};

const getPaletteReference = (key) => prefixes.value.settingsReference + 'palette.' + key;

const colorPreview = ref({
    hex: props.color.value || getResolvedDefaultColor(props.defaultColor),
    a: props.color.alpha > 0 || props.color.alpha === 0 ? props.color.alpha : props.defaultAlpha
});
watch(
    () => props.color,
    () => {
        colorPreview.value = {
            hex: props.color.value || resolvedDefaultColor.value,
            a: props.color.alpha > 0 || props.color.alpha === 0 ? props.color.alpha : props.defaultAlpha
        };
    }
);
</script>
