<template>
    <ui-dropdown
        id="settings-borders"
        class="centered borders-dropdown"
        ref="bordersDropdown"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        :toggle-style="bordersEnabled ? { color: 'var(--edit-mode-color)' } : {}"
        @[dropdownShowEvent]="enableBorders"
    >
        <template #dropdown-toggle>
            <svg-icon icon="borders-icon" />
            <span>{{ $t('Borders') }}</span>
        </template>

        <ui-switch-button
            id="settings-enable-borders"
            :label="$t('Add borders')"
            v-model="bordersEnabled"
            :disabled="readOnly"
        />

        <template v-if="bordersEnabled">
            <ui-color-selector
                id="settings-borders-color"
                key="color-selector-borders"
                v-model:color="bordersColor"
                :default-color="bordersDefaultColor"
                :palette="brandPalette"
                :enable-other-colors="brandEnableOtherColors"
                :label="$t('Color')"
                :disabled="readOnly"
            />

            <ui-number-input
                id="settings-borders-size"
                v-model="bordersSize"
                :default-value="bordersDefaultSize"
                :min="0"
                :label="$t('Size')"
                :disabled="readOnly"
            />

            <div class="border-sides-settings" :class="{ disabled: readOnly }">
                <label>{{ $t('Position') }}</label>
                <div class="border-side-inputs">
                    <input type="checkbox" class="border-side-top" v-model="borderTop" :disabled="readOnly" />
                    <input type="checkbox" class="border-side-bottom" v-model="borderBottom" :disabled="readOnly" />
                    <input type="checkbox" class="border-side-left" v-model="borderLeft" :disabled="readOnly" />
                    <input type="checkbox" class="border-side-right" v-model="borderRight" :disabled="readOnly" />
                </div>
            </div>
        </template>
    </ui-dropdown>
</template>

<script setup>
import { ref, watch } from 'vue';
import UiDropdown, { UI_DROPDOWN_MENU_SHOW } from '@/js/components/UiDropdown.vue';
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';
import UiColorSelector from '@/js/components/UiColorSelector.vue';
import UiNumberInput from '@/js/components/UiNumberInput.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { VIDEO_BRANDING_BORDER_DEFAULT_SIZE } from '@/js/constants/index.js';
import { useBranding } from '@/js/videos/composables/useBranding.js';
import { useBrand } from '@/js/videos/composables/useBrand.js';
import { Color } from '@/js/video-studio/constants/index.js';

const dropdownShowEvent = UI_DROPDOWN_MENU_SHOW;
const bordersDefaultSize = VIDEO_BRANDING_BORDER_DEFAULT_SIZE;
const bordersDefaultColor = Color.BORDER_DEFAULT;

const { readOnly } = useVideo();
const { brandPalette, brandEnableOtherColors } = useBrand();
const { enableBorders, bordersEnabled, bordersColor, bordersSize, borderTop, borderBottom, borderLeft, borderRight } =
    useBranding();

// automatically hide dropdown when borders are disabled
const bordersDropdown = ref(null);
watch(bordersEnabled, (newValue) => !newValue && bordersDropdown.value.hide());
</script>
