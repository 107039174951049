<template>
    <ui-dropdown
        :id="cardElementId + '-position'"
        class="panel-position-dropdown"
        :menu="false"
        :caret="false"
        :scrollable="false"
        :icon-only="true"
        menu-placement="bottom"
        menu-strategy="fixed"
        :tooltip="$t('Position')"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-arrows-up-down-left-right" />
        </template>

        <div v-if="isSideCategory" class="panel-side-settings">
            <label>{{ $t('Position') }}</label>
            <div class="panel-side-inputs">
                <input
                    v-for="(side, name) in panelSides"
                    type="radio"
                    :class="'panel-side-' + name"
                    :value="side"
                    v-model="panelSide"
                    :disabled="readOnly"
                />
            </div>
        </div>

        <ui-number-input
            :id="cardElementId + '-width'"
            v-model="panelWidth"
            :default-value="panelWidthDefault"
            :min="1"
            :decimals="1"
            :label="isSideCategory ? $t('Size') : $t('Width')"
            :disabled="readOnly"
        />

        <ui-number-input
            v-if="!isSideCategory"
            :id="cardElementId + '-height'"
            v-model="panelHeight"
            :default-value="panelHeightDefault"
            :min="1"
            :decimals="1"
            :label="$t('Height')"
            :disabled="readOnly"
        />

        <ui-switch-button
            v-if="!isSideCategory"
            :id="cardElementId + '-ratio-constraint'"
            :label="$t('Keep original ratio')"
            v-model="panelRatioConstraint"
            :disabled="readOnly"
        />

        <div v-if="!isSideCategory" class="ui-content-field" :class="{ disabled: readOnly }">
            <label>{{ $t('Horizontal position') }}</label>
            <ui-dropdown
                :id="cardElementId + '-hor-position'"
                :select="true"
                :editable="true"
                :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                v-model="panelHorizontalPosition"
                :disabled="readOnly"
            >
                <ui-dropdown-item
                    v-for="position in panelHorizontalPositions"
                    :key="position"
                    :value="position"
                    :label="$t('studio.alignments.' + position)"
                />
            </ui-dropdown>
        </div>

        <div v-if="!isSideCategory" class="ui-content-field" :class="{ disabled: readOnly }">
            <label>{{ $t('Vertical position') }}</label>
            <ui-dropdown
                :id="cardElementId + '-ver-position'"
                :select="true"
                :editable="true"
                :editable-reg-exp="/^\-?\d+(?:(?:\.|,)\d+)?$/"
                v-model="panelVerticalPosition"
                :disabled="readOnly"
            >
                <ui-dropdown-item
                    v-for="position in panelVerticalPositions"
                    :key="position"
                    :value="position"
                    :label="$t('studio.alignments.' + position)"
                />
            </ui-dropdown>
        </div>
    </ui-dropdown>
</template>

<script setup>
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiNumberInput from '@/js/components/UiNumberInput.vue';
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';
import UiDropdownItem from '@/js/components/UiDropdownItem.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { usePanel } from '@/js/videos/composables/usePanel.js';
import { Align, Dimension } from '@/js/video-studio/constants/index.js';
import { PANEL_HORIZONTAL_POSITIONS, PANEL_VERTICAL_POSITIONS } from '@/js/constants/index.js';

const props = defineProps({
    seqId: String,
    cardElementId: String
});

const panelSides = {
    left: Align.LEFT,
    right: Align.RIGHT,
    top: Align.TOP,
    bottom: Align.BOTTOM
};
const panelHeightDefault = Dimension.AUTO;
const panelHorizontalPositions = PANEL_HORIZONTAL_POSITIONS;
const panelVerticalPositions = PANEL_VERTICAL_POSITIONS;

const { readOnly } = useVideo();
const {
    isSideCategory,
    panelSide,
    panelWidth,
    panelHeight,
    panelWidthDefault,
    panelRatioConstraint,
    panelHorizontalPosition,
    panelVerticalPosition
} = usePanel(props.seqId);
</script>
