<template>
    <ui-color-selector
        v-if="transitionUses.includes('color.start')"
        :id="seqId + '-color-start'"
        key="color-selector-transition-color-start"
        class="ui-card-row"
        v-model:color="transitionColorStart"
        default-color="color1"
        :palette="brandPalette"
        :enable-other-colors="brandEnableOtherColors"
        :label="sprintf($t('Color %1$d'), 1)"
        :disabled="readOnly"
    />
    <ui-color-selector
        v-if="transitionUses.includes('color.second')"
        :id="seqId + '-color-second'"
        key="color-selector-transition-color-second"
        class="ui-card-row"
        v-model:color="transitionColorSecond"
        default-color="color2"
        :palette="brandPalette"
        :enable-other-colors="brandEnableOtherColors"
        :label="sprintf($t('Color %1$d'), 2)"
        :disabled="readOnly"
    />
    <ui-color-selector
        v-if="transitionUses.includes('color.third')"
        :id="seqId + '-color-third'"
        key="color-selector-transition-color-third"
        class="ui-card-row"
        v-model:color="transitionColorThird"
        default-color="color2"
        :palette="brandPalette"
        :enable-other-colors="brandEnableOtherColors"
        :label="sprintf($t('Color %1$d'), 3)"
        :disabled="readOnly"
    />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { printf as sprintf } from 'fast-printf';

import UiColorSelector from '@/js/components/UiColorSelector.vue';
import { Color } from '@/js/video-studio/constants/index.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { useBrand } from '@/js/videos/composables/useBrand.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';

const props = defineProps({
    seqId: String,
    transitionUses: Array
});

const store = useStore();
const { readOnly } = useVideo();
const { brandPalette, brandEnableOtherColors } = useBrand();
const { saveHistoryStep, ignoreHistoryStep } = useHistory();
const { sequenceState, sequenceStoreModulePath } = useSequence(props.seqId);

const transitionColorStart = computed({
    get: () => {
        let color = { ref: Color.NONE, value: Color.NONE };

        if (props.transitionUses.includes('color.start')) {
            color.ref = sequenceState.value.transition.color.start__ref;
            color.value = sequenceState.value.transition.color.start;
        }

        return color;
    },
    set: ({ ref, value }) => {
        saveHistoryStep(() => {
            store.commit(
                `${sequenceStoreModulePath.value}/setTransitionColor`,
                Object.assign({}, sequenceState.value.transition.color, { start__ref: ref, start: value })
            );
        });
    }
});

const transitionColorSecond = computed({
    get: () => {
        let color = { ref: Color.NONE, value: Color.NONE };

        if (props.transitionUses.includes('color.second')) {
            color.ref = sequenceState.value.transition.color.second__ref;
            color.value = sequenceState.value.transition.color.second;
        }

        return color;
    },
    set: ({ ref, value }) => {
        saveHistoryStep(() => {
            store.commit(
                `${sequenceStoreModulePath.value}/setTransitionColor`,
                Object.assign({}, sequenceState.value.transition.color, { second__ref: ref, second: value })
            );
        });
    }
});

const transitionColorThird = computed({
    get: () => {
        let color = { ref: Color.NONE, value: Color.NONE };

        if (props.transitionUses.includes('color.third')) {
            color.ref = sequenceState.value.transition.color.third__ref;
            color.value = sequenceState.value.transition.color.third;
        }

        return color;
    },
    set: ({ ref, value }) => {
        saveHistoryStep(() => {
            store.commit(
                `${sequenceStoreModulePath.value}/setTransitionColor`,
                Object.assign({}, sequenceState.value.transition.color, { third__ref: ref, third: value })
            );
        });
    }
});
</script>
