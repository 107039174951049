<template>
    <li class="ui-sequence-list-item ui-sidebar-item" :class="itemClasses">
        <ui-dropdown
            v-if="dropdownEnabled"
            :id="'sli-' + id"
            :icon="true"
            :caret="false"
            menu-placement="right-start"
            menu-strategy="fixed"
            class="ui-sequence-list-item-actions"
            v-on="dropdownListeners"
        >
            <template #dropdown-toggle>
                <span class="visually-hidden">{{ $t('Sequence actions...') }}</span>
                <svg-icon icon="menu-icon" />
            </template>

            <ui-dropdown-item :disabled="!canCopySequence" @click.prevent="copySequence">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-copy" />
                    {{ $t('Copy') }}
                </template>
            </ui-dropdown-item>
            <ui-dropdown-item v-if="!sequenceMode" :disabled="!canPasteSequence" @click.prevent="pasteSequence">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-clipboard" />
                    {{ $t('Paste after') }}
                </template>
            </ui-dropdown-item>
            <ui-dropdown-item v-if="!sequenceMode" :disabled="!canAddSequence" @click.prevent="duplicateSequence">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-clone" />
                    {{ $t('Duplicate') }}
                </template>
            </ui-dropdown-item>
            <ui-dropdown-item
                v-if="!sequenceMode"
                :disabled="!canRemoveSequence && !state.options.hidden"
                @click.prevent="toggleHideSequence"
            >
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" :icon="'fa-regular ' + (!state.options.hidden ? 'fa-eye-slash' : 'fa-eye')" />
                    {{ !state.options.hidden ? $t('Hide') : $t('Show') }}
                </template>
            </ui-dropdown-item>
            <ui-dropdown-item
                v-if="!sequenceMode && canCreateSequenceTemplate"
                :disabled="!canCreateSequenceTemplate"
                @click.prevent="saveSequenceAsTemplate"
            >
                <template #dropdown-menu-item-label="{ instance }">
                    <svg-icon icon="sequence-template-icon" />
                    {{ sprintf($t('%1$s...'), $t('Save as template')) }}
                </template>
            </ui-dropdown-item>
            <ui-dropdown-item
                v-if="!sequenceMode"
                classes="ui-delete-item"
                :disabled="!canRemoveSequence && !state.options.hidden"
                @click.prevent="deleteSequence"
            >
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-trash-can" />
                    {{ $t('Delete') }}
                </template>
            </ui-dropdown-item>
        </ui-dropdown>

        <a class="ui-sidebar-item-handle" href="#" ref="$handle" @click.prevent="handleClick">
            <div class="ui-sequence-list-item-preview" :style="backgroundStyles">
                <div class="ui-sequence-list-item-preview-blur-fallback w-100 h-100" :style="backgroundBlurStyles">
                    <img
                        v-if="!!previewSrc"
                        :src="previewSrc"
                        class="w-100 h-100"
                        :style="backgroundPreviewStyles"
                        :alt="$t('Preview image')"
                        loading="lazy"
                    />
                    <div v-if="state.options.hidden" class="sequence-hidden-overlay">
                        <fa-icon icon="fa-regular fa-eye-slash" />
                    </div>
                    <div
                        v-if="backgroundState.overlay.enabled && !backgroundIsColorType"
                        class="ui-sequence-list-item-preview-overlay"
                        :style="backgroundOverlayStyles"
                    ></div>
                    <div
                        v-if="detection.features.backdropFilter && backgroundState.blur"
                        class="ui-sequence-list-item-preview-blur"
                    ></div>
                </div>
            </div>
            <span class="ui-sequence-list-item-name">{{ index + 1 + '.' + (' ' + state.name || '') }}</span>
        </a>

        <template v-if="canAddEmptySequence && index < totalSequences - 1">
            <ui-sequence-library-selector
                v-if="canUseSequenceTemplate"
                ref="$addSequenceSelector"
                :id="'add-sequence-library-' + index"
                class="ui-add-sequence-button"
                :class="addButtonClasses"
                :create-index="index + 1"
                :title="$t('Add a sequence')"
                :label="$t('Add a sequence')"
                :show-label="false"
                icon="fa-solid fa-plus-large"
                :disabled="readOnly"
                @[librarySelectorShowEvent]="addEmptySequence($event)"
                @[librarySelectorPreviewChangeEvent]="previewAddedSequence"
                @[librarySelectorChangeEvent]="applySequenceTemplate"
            />
            <button
                v-else
                class="ui-add-sequence-button"
                :class="addButtonClasses"
                :title="$t('Add a sequence')"
                @click="addEmptySequence($event)"
            >
                <span class="visually-hidden">{{ $t('Add a sequence') }}</span>
                <fa-icon class="icon" icon="fa-solid fa-plus" />
            </button>
        </template>
    </li>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { Background, Color } from 'cte-video-studio';
import { printf as sprintf } from 'fast-printf';
import { UiDropdown, UiDropdownItem, UiIcon } from '../../../../components';
import { UI_DROPDOWN_MENU_SHOW, UI_DROPDOWN_MENU_HIDE } from '../../../../components/UiDropdown.vue';
import {
    UI_LIBRARY_SELECTOR_SHOW,
    UI_LIBRARY_SELECTOR_PREVIEW_CHANGE,
    UI_LIBRARY_SELECTOR_CHANGE
} from '../../../../components/UiLibrarySelector.vue';
import UiSequenceLibrarySelector from '../UiSequenceLibrarySelector.vue';
import loader from '@/js/video-studio/loader.js';

export const UI_SEQUENCE_ITEM_SELECT = 'ui-sequence-item-select';
export const UI_SEQUENCE_ITEM_ACTIONS_OPEN = 'ui-sequence-item-actions-open';
export const UI_SEQUENCE_ITEM_ACTIONS_CLOSE = 'ui-sequence-item-actions-close';
export const UI_SEQUENCE_ITEM_DUPLICATE = 'ui-sequence-item-duplicate';
export const UI_SEQUENCE_ITEM_COPY = 'ui-sequence-item-copy';
export const UI_SEQUENCE_ITEM_PASTE_AFTER = 'ui-sequence-item-paste-after';
export const UI_SEQUENCE_ITEM_TOGGLE_HIDE = 'ui-sequence-item-toggle-hide';
export const UI_SEQUENCE_ITEM_SAVE_TEMPLATE = 'ui-sequence-item-save-template';
export const UI_SEQUENCE_ITEM_DELETE = 'ui-sequence-item-delete';
export const UI_SEQUENCE_ITEM_ADD = 'ui-sequence-item-add';
export const UI_SEQUENCE_ITEM_PREVIEW = 'ui-sequence-item-preview';
export const UI_SEQUENCE_ITEM_APPLY_TEMPLATE = 'ui-sequence-item-apply-template';

export default {
    components: {
        UiDropdown,
        UiDropdownItem,
        UiIcon,
        UiSequenceLibrarySelector
    },

    emits: [
        UI_SEQUENCE_ITEM_SELECT,
        UI_SEQUENCE_ITEM_ACTIONS_OPEN,
        UI_SEQUENCE_ITEM_ACTIONS_CLOSE,
        UI_SEQUENCE_ITEM_DUPLICATE,
        UI_SEQUENCE_ITEM_COPY,
        UI_SEQUENCE_ITEM_PASTE_AFTER,
        UI_SEQUENCE_ITEM_TOGGLE_HIDE,
        UI_SEQUENCE_ITEM_SAVE_TEMPLATE,
        UI_SEQUENCE_ITEM_DELETE,
        UI_SEQUENCE_ITEM_ADD,
        UI_SEQUENCE_ITEM_PREVIEW,
        UI_SEQUENCE_ITEM_APPLY_TEMPLATE
    ],

    props: {
        id: String
    },

    data() {
        return {
            librarySelectorShowEvent: UI_LIBRARY_SELECTOR_SHOW,
            librarySelectorPreviewChangeEvent: UI_LIBRARY_SELECTOR_PREVIEW_CHANGE,
            librarySelectorChangeEvent: UI_LIBRARY_SELECTOR_CHANGE,

            actionMenuActive: false
        };
    },

    computed: {
        ...mapState({
            detection: (state) => state.detection,
            format: (state) => state.display.format,
            readOnly: (state) => state.ui.readOnly,
            sequenceMode: (state) => state.ui.sequenceMode,
            state(state) {
                return state.sequences[this.id];
            },
            backgroundRawState(state) {
                return state.sequences[this.id].background;
            },
            currentEditedItemId: (state) => state.ui.currentEditedItemId
        }),

        ...mapGetters({
            totalSequences: 'sequences/total',
            canAddEmptySequence: 'ui/canAddEmptySequence',
            canAddSequence: 'ui/canAddSequence',
            canMoveSequence: 'ui/canMoveSequence',
            canCopySequence: 'ui/canCopySequence',
            canPasteSequence: 'ui/canPasteSequence',
            canCreateSequenceTemplate: 'ui/canCreateSequenceTemplate',
            canUseSequenceTemplate: 'ui/canUseSequenceTemplate',
            canRemoveSequence: 'ui/canRemoveSequence'
        }),

        resolvedId() {
            return this.$store.getters['sequences/' + this.id + '/resolvedId'];
        },

        index() {
            return this.$store.getters['sequences/' + this.id + '/index'];
        },

        previousVisible() {
            return this.$store.getters['sequences/' + this.id + '/previousVisible'];
        },

        nextVisible() {
            return this.$store.getters['sequences/' + this.id + '/nextVisible'];
        },

        next() {
            return this.$store.getters['sequences/' + this.id + '/next'];
        },

        fallback() {
            return this.previousVisible || this.nextVisible;
        },

        backgroundState() {
            return !this.hasPreviousBackground || !this.previousWithBackground
                ? this.backgroundRawState
                : this.previousWithBackground.background;
        },

        previousWithBackground() {
            return this.$store.getters['sequences/' + this.id + '/previousWithBackground'];
        },

        hasPreviousBackground() {
            return this.$store.getters['sequences/' + this.id + '/hasPreviousBackground'];
        },

        hasColorBackground() {
            return (
                (this.hasPreviousBackground && !this.previousWithBackground) ||
                this.$store.getters['sequences/' + this.resolvedId + '/hasColorBackground']
            );
        },

        hasMapZoomBackground() {
            return this.$store.getters['sequences/' + this.resolvedId + '/hasMapZoomBackground'];
        },

        previewSrc() {
            return this.$store.getters['sequences/' + this.id + '/previewSrc'];
        },

        blobPreviewSrc() {
            return !!this.previewSrc ? this.$store.getters['loading/getBlob'](this.previewSrc) : '';
        },

        itemClasses() {
            return {
                active: this.currentEditedItemId == this.id,
                'active-menu': this.actionMenuActive,
                hidden: this.state.options.hidden,
                locked: !this.canMoveSequence
            };
        },

        backgroundStyles() {
            return {
                backgroundColor: this.hasColorBackground ? this.backgroundState.color.start : Color.BACKGROUND_DEFAULT
            };
        },

        backgroundPreviewStyles() {
            return {
                transform:
                    this.hasMapZoomBackground && (this.detection.features.backdropFilter || !this.backgroundState.blur)
                        ? 'scale(1.06)'
                        : null
            };
        },

        backgroundBlurStyles() {
            return this.detection.features.backdropFilter || !this.backgroundState.blur
                ? {}
                : {
                      filter: 'blur(var(--blur-filter-amount))',
                      transform: 'scale(1.05)'
                  };
        },

        backgroundOverlayStyles() {
            return {
                backgroundColor: this.$store.getters['sequences/' + this.resolvedId + '/backgroundOverlayColorStart'],
                opacity: this.backgroundState.overlay.opacity
            };
        },

        dropdownEnabled() {
            return (
                !this.readOnly &&
                (this.canCopySequence || this.canPasteSequence || this.canAddSequence || this.canRemoveSequence)
            );
        },

        dropdownListeners() {
            return {
                [UI_DROPDOWN_MENU_SHOW]: this.handleDropdownMenuShow,
                [UI_DROPDOWN_MENU_HIDE]: this.handleDropdownMenuHide
            };
        },

        addButtonClasses() {
            return {
                'next-hidden': !!this.next && this.next.options.hidden
            };
        },

        backgroundIsColorType() {
            return this.backgroundState.type === Background.COLOR_TYPE;
        }
    },

    watch: {
        currentEditedItemId(value) {
            if (value == this.id) this.$refs.$handle.focus();
        },

        previewSrc(newValue, oldValue) {
            this.updatePreview(newValue, oldValue);
        }
    },

    methods: {
        ...mapActions({
            //
        }),

        sprintf,

        handleClick() {
            this.$emit(UI_SEQUENCE_ITEM_SELECT, this);
        },

        handleDropdownMenuShow() {
            this.actionMenuActive = true;
            this.$emit(UI_SEQUENCE_ITEM_ACTIONS_OPEN, this);
        },

        handleDropdownMenuHide() {
            this.actionMenuActive = false;
            this.$emit(UI_SEQUENCE_ITEM_ACTIONS_CLOSE, this);
        },

        duplicateSequence() {
            this.$emit(UI_SEQUENCE_ITEM_DUPLICATE, this);
        },

        copySequence() {
            this.$emit(UI_SEQUENCE_ITEM_COPY, this);
        },

        pasteSequence() {
            this.$emit(UI_SEQUENCE_ITEM_PASTE_AFTER, this, this.index);
        },

        toggleHideSequence() {
            this.$emit(UI_SEQUENCE_ITEM_TOGGLE_HIDE, this, this.state.options.hidden);
        },

        saveSequenceAsTemplate() {
            this.$emit(UI_SEQUENCE_ITEM_SAVE_TEMPLATE, this);
        },

        deleteSequence() {
            this.$emit(UI_SEQUENCE_ITEM_DELETE, this, this.fallback);
        },

        addEmptySequence(event) {
            this.$emit(UI_SEQUENCE_ITEM_ADD, event, this, this.$refs.$addSequenceSelector, this.index);
        },

        previewAddedSequence(templateData, originalState, cancel) {
            this.$emit(
                UI_SEQUENCE_ITEM_PREVIEW,
                this,
                this.$refs.$addSequenceSelector,
                templateData,
                originalState,
                cancel
            );
        },

        applySequenceTemplate(templateData, originalState) {
            this.$emit(
                UI_SEQUENCE_ITEM_APPLY_TEMPLATE,
                this,
                this.$refs.$addSequenceSelector,
                templateData,
                originalState
            );
        },

        updatePreview(newValue, oldValue) {
            if (newValue) loader.load(newValue, () => {});
            if (oldValue) loader.unload(oldValue, () => {});
        }
    },

    mounted() {
        this.updatePreview(this.previewSrc);
    },

    beforeUnmount() {
        if (this.actionMenuActive) {
            this.$emit(UI_SEQUENCE_ITEM_ACTIONS_CLOSE, this);
        }
        this.updatePreview('', this.previewSrc);
    }
};
</script>

<style lang="scss" scoped>
.sequence-hidden-overlay {
    position: absolute;
    inset: 0;
    margin: auto;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    opacity: 0.5;
    font-size: 2rem;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));

    .fa-icon {
        opacity: 0.5;
    }
}

.ui-sequence-list-item-preview {
    position: relative;
    overflow: hidden;
}
</style>
