<template>
    <div class="ui-voice-over-selector">
        <button
            class="selector"
            :class="selectorClasses"
            :style="previewStyles"
            :disabled="disabled"
            @click="handleOpen"
        ></button>
        <div v-if="isVoiceEmpty" class="selector-empty">
            <svg class="border">
                <rect x="0" y="0" width="100%" height="100%" rx="10" fill="none" />
            </svg>
            <fa-icon class="icon" icon="fa-regular fa-pen-to-square" />
        </div>
        <div v-else class="selector-actions">
            <button class="action-change" :title="$t('Change selection')" @click="handleOpen">
                <span class="visually-hidden">{{ $t('Change selection') }}</span>
                <fa-icon class="icon" icon="fa-regular fa-pen-to-square" />
            </button>
            <button class="action-remove" :title="$t('Remove selection')" @click="handleRemove">
                <span class="visually-hidden">{{ $t('Remove selection') }}</span>
                <fa-icon class="icon" icon="fa-solid fa-xmark" />
            </button>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { Checkboard } from '@lk77/vue3-color';
import UiIcon from './UiIcon.vue';
import { useVoiceOver } from '../videos/composables/useVoiceOver';
import { useHistory } from '../videos/composables/useHistory';

export default defineComponent({
    name: 'VoiceOverSelector',
    components: {
        Checkboard,
        UiIcon
    },
    props: {
        id: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        seqId: {
            type: String,
            default: null
        },
        useInSequence: {
            type: Boolean,
            default: false
        },
        triggerOpening: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const store = useStore();

        const useInSequence = ref(props.useInSequence);
        const sequenceId = ref(props.seqId);
        const isEmpty = ref(false);

        const { showTtsEditor, storeModulePath, voiceOverState, voiceOverDeleteVoiceSource } = useVoiceOver(
            useInSequence,
            sequenceId
        );
        const { saveHistoryStep } = useHistory();

        const currentVoiceId = computed(() => {
            return voiceOverState.value.voice.voiceId;
        });

        const isVoiceEmpty = computed(() => {
            return isEmpty.value || !currentVoiceId.value || !voiceOverState.value.voice.text;
        });

        const selectorClasses = computed(() => {
            return {
                empty: isVoiceEmpty.value
            };
        });

        const previewStyles = computed(() => {
            if (isVoiceEmpty.value) return {};
            return {
                backgroundImage: `url(/assets/thumbnails/voices/${currentVoiceId.value}.gif)`
            };
        });

        const handleRemove = () => {
            isEmpty.value = true;
            saveHistoryStep(() => {
                store.dispatch(storeModulePath.value + '/setDefaultVoiceId');
                store.dispatch(storeModulePath.value + '/setUpToDate', false);
                voiceOverDeleteVoiceSource();
            });
        };

        const handleOpen = () => {
            isEmpty.value = false;
            showTtsEditor();
        };

        onMounted(() => {
            if (props.triggerOpening) handleOpen();
        });

        return {
            isEmpty,
            disabled: props.disabled,
            isVoiceEmpty,
            selectorClasses,
            previewStyles,
            handleOpen,
            handleRemove
        };
    }
});
</script>
