<template>
    <ui-video-card :id="id" classes="ui-video-card-settings-format" :selectable="false" :card-name="$t('Video format')">
        <template #actions-tools>
            <div class="ui-format-selection">
                <label v-for="(format, index) in standardFormats" :id="formatElementIds[index]" class="video-format">
                    <input
                        type="radio"
                        :id="formatInputIds[index]"
                        name="video-format"
                        class="video-format-input"
                        v-model="selectedFormat"
                        :value="format.ratio"
                        :disabled="readOnly"
                    />
                    <svg-icon :icon="'video-format-' + format.ratioString + '-icon'" class="video-format-icon" />
                    <span class="visually-hidden">{{ format.label }}</span>
                    <div class="video-format-selected" v-if="selectedFormat === format.ratio">
                        <fa-icon class="icon" icon="fa-regular fa-check" />
                    </div>
                </label>
            </div>
        </template>

        <template #second-row>
            <div v-if="!isTemplate" class="video-format-resolution-setting ui-card-row">
                <label
                    class="video-format-resolution-label off"
                    for="video-format-resolution-switch-toggle"
                    :aria-hidden="!isSelectedFormatHd || null"
                >
                    <a :href="greenVideoInfoUrl" target="_blank">
                        <fa-icon class="icon" icon="fa-sold fa-circle-info" />
                        <span>{{ $t('Green HD') }}</span>
                    </a>
                </label>

                <ui-switch-button
                    class="video-format-resolution"
                    id="video-format-resolution"
                    :show-label="false"
                    v-model="isSelectedFormatHd"
                    :disabled="readOnly"
                />

                <label
                    class="video-format-resolution-label on"
                    for="video-format-resolution-switch-toggle"
                    :aria-hidden="isSelectedFormatHd || null"
                    @[selectedFormatHdEvent].prevent=""
                >
                    {{ $t('Full HD') }}
                </label>
            </div>
        </template>
    </ui-video-card>
</template>

<script setup>
import { computed } from 'vue';
import UiVideoCard from '@/js/videos/components/ui/body/UiVideoCard.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useDisplay } from '@/js/videos/composables/useDisplay.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { useStore } from 'vuex';
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';

const props = defineProps({
    id: {
        type: String,
        default: 'settings-format'
    }
});

const store = useStore();
const { currentFormatRatio, currentFormatString } = useDisplay();
const { availableFormats, standardFormats, readOnly, saveVideo } = useVideo();
const { startHistoryStep } = useHistory();

const isTemplate = computed(() => store.state.settings.isTemplate);
const formatElementIds = computed(() => standardFormats.value.map((format) => 'video-format-' + format.ratioString));
const formatInputIds = computed(() =>
    standardFormats.value.map((format) => 'video-format-' + format.ratioString + '-input')
);
const greenVideoInfoUrl = computed(() => store.state.ui.urls.greenVideoInfo);

const isSelectedFormatHd = computed({
    get: () => store.getters['ui/isHdFormat'](currentFormatString.value),
    set: (value) => {
        let format = availableFormats.value.find(
            (format) => format.ratio === selectedFormat.value && format.hd === value
        );

        if (format) {
            startHistoryStep();
            store.dispatch('display/setFormat', format);
            saveVideo();
        }
    }
});

const selectedFormat = computed({
    get: () => currentFormatRatio.value,
    set: (value) => {
        let format = availableFormats.value.find(
            (format) => format.ratio === value && format.hd === isSelectedFormatHd.value
        );

        if (format) {
            startHistoryStep();
            store.dispatch('display/setFormat', format);
            saveVideo();
        }
    }
});

const selectedFormatHdEvent = computed(() => (isSelectedFormatHd.value ? 'click' : null));
</script>
