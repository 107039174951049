<template>
    <ui-dropdown
        :id="cardElementId + '-effects'"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom-end"
        menu-strategy="fixed"
        :disabled="readOnly"
        :toggle-style="toggleStyle"
        :icon-only="true"
        :tooltip="$t('Effects')"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-sparkles" />
        </template>

        <ui-switch-button
            :id="cardElementId + '-enable-animation'"
            :label="$t('Animate')"
            v-model="panelAnimationEnabled"
            :disabled="readOnly"
        />

        <ui-switch-button
            v-if="isSideCategory"
            :id="cardElementId + '-enable-bg-move'"
            :label="$t('Center background')"
            v-model="panelBgMoveEnabled"
            :disabled="readOnly"
        />
    </ui-dropdown>
</template>

<script setup>
import { computed } from 'vue';
import UiDropdown from '@/js/components/UiDropdown.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';
import { usePanel } from '@/js/videos/composables/usePanel.js';

const props = defineProps({
    seqId: String,
    cardElementId: String
});

const { readOnly, openLibrary } = useVideo();
const { isSideCategory, panelAnimationEnabled, panelBgMoveEnabled } = usePanel(props.seqId);

const toggleStyle = computed(() => {
    if (panelAnimationEnabled.value === false || panelBgMoveEnabled.value === false) {
        return { color: 'var(--edit-mode-color)' };
    } else return {};
});
</script>
