<template>
    <h2 class="ui-sequence-name" :aria-description="$t('Sequence name')">
        <input
            :id="seqId + '-name-input'"
            ref="sequenceNameInput"
            class="sequence-name"
            :maxlength="SEQUENCE_NAME_MAX_LENGTH"
            :disabled="readOnly"
            :value="sequenceName"
            @change="(event) => (sequenceName = event.target.value)"
            @focus="startEditSequenceName"
            @blur="stopEditSequenceName"
            @keyup.enter="stopEditSequenceName"
        />
        <label class="visually-hidden" :for="seqId + '-name-input'">{{ $t('Edit sequence name') }}</label>
        <span class="sequence-name-placeholder" aria-hidden="true">{{ sequenceName }}</span>
    </h2>
</template>

<script setup>
import { computed, nextTick, ref, toRef } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { printf as sprintf } from 'fast-printf';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { SEQUENCE_NAME_MAX_LENGTH } from '@/js/constants/index.js';

const props = defineProps({
    seqId: String
});

const sequenceId = toRef(props, 'seqId');

const store = useStore();
const { t } = useI18n();
const { readOnly, saveVideo } = useVideo();
const { startHistoryStep } = useHistory();
const { sequenceState, sequenceStoreModulePath } = useSequence(props.seqId);

const sequenceIndex = computed(() => store.getters[sequenceStoreModulePath.value + '/index']);
const sequenceMode = computed(() => store.state.ui.sequenceMode);

const sequenceNameInput = ref(null);
const editSequenceName = ref(false);
const startEditSequenceName = () => {
    editSequenceName.value = true;
    nextTick(() => {
        sequenceNameInput.value.setSelectionRange(0, sequenceName.value.length);
    });
};

const stopEditSequenceName = () => {
    editSequenceName.value = false;
    sequenceNameInput.value.blur();
};

const sequenceName = computed({
    get: () => {
        const name = /\S/.test(sequenceState.value.name) ? sequenceState.value.name : t('Untitled sequence');
        return editSequenceName.value
            ? name
            : // TRANSLATORS: Placeholders are for sequence index (1) and name (2)
              sprintf(t('%1$d. %2$s'), sequenceIndex.value + 1, name);
    },
    set: (value) => {
        value = value.replace(/\s+/g, ' ');
        startHistoryStep();
        store.commit(sequenceStoreModulePath.value + '/setName', value);
        if (sequenceMode.value) store.commit('settings/setName', value);
        saveVideo();
    }
});
</script>
