<template>
    <ui-video-card
        :id="cardElementId"
        :card-name="$t('Voice-over')"
        classes="ui-video-card-voice-over"
        :selectable="false"
    >
        <template #selector>
            <ui-voice-over-type-selector
                :seq-id="seqId"
                :card-element-id="cardElementId"
                :use-in-sequence="useInSequence"
                :trigger-selection="triggerSelection"
            />
        </template>

        <template #content>
            <ui-dropdown
                :id="cardElementId + '-tts-category'"
                :select="true"
                v-model="voiceOverCategory"
                :disabled="readOnly"
                :default-label="$t('Add voice-over')"
                menu-strategy="fixed"
                @[dropdownCategorySelectEvent]="triggerCategoryDropdown"
            >
                <template #dropdown-toggle="{ instance }">
                    <svg-icon v-if="isVoiceCategory && isProcessing" icon="dots-loader" />
                    <template v-else>
                        <fa-icon :icon="categoryIcons[voiceOverCategory]" class="icon" />
                        <span>{{ instance.toggleLabel }}</span>
                    </template>
                </template>

                <ui-dropdown-item
                    v-for="category in ttsCategories"
                    :key="category"
                    :value="category"
                    :label="$t('studio.tts_categories.' + category)"
                    :disabled="recordingDisabled(category)"
                    :show-icon-on-disabled="true"
                    v-tooltip="recordingDisabled(category) && $t('studio.features.recording-disabled')"
                >
                    <template #dropdown-menu-item-label="{ instance }">
                        <fa-icon :icon="categoryIcons[instance.value]" class="icon" />
                        {{ instance.label || instance.value }}
                    </template>
                </ui-dropdown-item>
            </ui-dropdown>
        </template>

        <template #actions-menu-content>
            <ui-card-sequence-menu-content :id="id" :seq-id="seqId" :remove-actions="removeCard" />
        </template>

        <template #actions-tools>
            <ui-voice-over-tools :seq-id="seqId" :card-element-id="cardElementId" :use-in-sequence="useInSequence" />
        </template>
    </ui-video-card>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import UiVideoCard from '@/js/videos/components/ui/body/UiVideoCard.vue';
import UiCardSequenceMenuContent from '@/js/videos/components/ui/body/cards/menus/UiCardSequenceMenuContent.vue';
import { RECORDING_ELEMENT_ID, TTS_ELEMENT_ID } from '@/js/constants/index.js';
import UiVoiceOverTypeSelector from '@/js/videos/components/ui/body/selectors/UiVoiceOverTypeSelector.vue';
import { useVoiceOver } from '@/js/videos/composables/useVoiceOver.js';
import { UiDropdownItem } from '@/js/components/index.js';
import UiDropdown, { UI_DROPDOWN_SELECT_CHANGE } from '@/js/components/UiDropdown.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { TTS } from '@/js/video-studio/constants/index.js';
import { useAuth } from '@/js/composables/useAuth.js';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import UiVoiceOverTools from '@/js/videos/components/ui/body/cards/tools/UiVoiceOverTools.vue';

const vTooltip = UsesTooltip.directives.tooltip;

const props = defineProps({
    id: {
        type: String,
        default: TTS_ELEMENT_ID
    },
    seqId: String
});

const ttsCategories = TTS.CATEGORIES;
const dropdownCategorySelectEvent = UI_DROPDOWN_SELECT_CHANGE;

const useInSequence = ref(true);
const sequenceId = ref(props.seqId);
const cardElementId = computed(() => `${props.seqId}-${props.id}`);

const store = useStore();
const { readOnly } = useVideo();
const { user } = useAuth();
const { sequenceStoreModulePath } = useSequence(props.seqId);
const { voiceOverCategory, isVoiceCategory, isProcessing, categoryIcons } = useVoiceOver(useInSequence, sequenceId);

const triggerSelection = ref(false);
const triggerCategoryDropdown = () => (triggerSelection.value = true);

const recordingDisabled = (category) => category === RECORDING_ELEMENT_ID && !user.value.client.enableRecording;

const removeCard = () => {
    store.commit(sequenceStoreModulePath.value + '/tts/showCard', false);
    store.commit(sequenceStoreModulePath.value + '/resetTtsState');
};
</script>
