<template>
    <ui-dropdown
        id="settings-music-timing"
        :menu="false"
        :caret="false"
        :scrollable="false"
        :disabled="readOnly || (!music.src && !libraryMusic.src)"
        :tooltip="$t('Duration')"
        menu-placement="bottom"
        :icon-only="true"
        :toggle-style="toggleStyle"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-clock" />
        </template>

        <ui-number-input
            id="settings-music-start"
            v-model="musicStart"
            :default-value="0"
            :min="0"
            :label="$t('Starting time')"
            :disabled="readOnly"
        />

        <ui-switch-button
            id="settings-music-fade"
            v-model="enabledEndFade"
            :label="$t('Fade out at video\'s end')"
            :disabled="readOnly"
        />
    </ui-dropdown>
</template>

<script setup>
import { computed } from 'vue';
import UiNumberInput from '@/js/components/UiNumberInput.vue';
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useMusic } from '@/js/videos/composables/useMusic.js';

const { readOnly } = useVideo();
const { music, libraryMusic, musicStart, enabledEndFade } = useMusic();

const toggleStyle = computed(() =>
    musicStart.value !== 0 || !enabledEndFade.value ? { color: 'var(--edit-mode-color)' } : {}
);
</script>
