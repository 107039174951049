<template>
    <ui-video-card
        :id="seqId + '-brand-logo'"
        classes="ui-video-card-brand-logo"
        :card-name="$t('Brand logo')"
        :selectable="false"
    >
        <template #actions-tools>
            <ui-switch-button
                :id="`${seqId}-hide-brand-logo`"
                :label="$t('Show')"
                v-model="showBrandLogo"
                :disabled="readOnly"
            />
        </template>
    </ui-video-card>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import UiVideoCard from '@/js/videos/components/ui/body/UiVideoCard.vue';
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';

const props = defineProps({
    seqId: String
});

const store = useStore();
const { saveHistoryStep } = useHistory();
const { readOnly } = useVideo();
const { sequenceState, sequenceStoreModulePath } = useSequence(props.seqId);

const showBrandLogo = computed({
    get: () => sequenceState.value.options.logo.enabled,
    set: (value) => saveHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/enableLogo`, value))
});
</script>
