/**
 * Edit video Settings Vuex store extension
 */

import _isArray from 'lodash/isArray';
import _merge from 'lodash/merge';
import _mergeWith from 'lodash/mergeWith';
import { DEFAULT_TEMPLATE_LANGUAGE, UNIVERSAL_MUSIC_CATEGORY } from '../../../constants';
import './logo';
import './cover';
import './borders';
import './captions';
import '../commons/tts';
import timeline from '../timeline.js';
import { Sound } from '@/js/video-studio/constants/index.js';
import { conversions } from '@/js/video-studio/utils/index.js';
import logo from './logo.js';
import cover from './cover.js';
import borders from './borders.js';
import captions from './captions.js';
import tts from '../commons/tts.js';

const originalState = () => ({
    ...timeline.state(),

    id: '',
    name: '',
    music: {
        type: UNIVERSAL_MUSIC_CATEGORY,

        src: '',
        src__ref: '',
        src__id: '',

        library_src__ref: '',
        library_src__id: '',

        library_src: '',
        start: 0,
        volume: {
            value: Sound.VOLUME_DEFAULT,
            custom: Sound.VOLUME_EMPTY
        },
        timerange: { start: 0, end: -1 },
        totalDuration: () => 0
    },
    track: {
        enabled: false,
        src: '',
        src__ref: '',
        src__id: '',
        captions: false,
        volume: {
            value: Sound.VOLUME_DEFAULT,
            custom: Sound.VOLUME_EMPTY
        }
    },
    fade: {
        end: {
            enabled: true
        }
    },
    isTemplate: false,

    language: DEFAULT_TEMPLATE_LANGUAGE,
    categories: [],
    compatibleFormats: [],
    description: '',

    needsMigration: false,

    ai: {
        enabled: false,
        id: '',
        name: '',
        duration: '',
        metadata: {},
        formats: {}
    }
});

const state = {
    ...originalState()
};

const getters = {
    audioTimeline: (state) => state.timeline,

    musicVolume: (state) => conversions.optionValue(state.music.volume, [], Sound.VOLUME_DEFAULT),

    musicStart: (state) => state.music.start,

    trackVolume: (state) => conversions.optionValue(state.track.volume, [], Sound.VOLUME_DEFAULT),

    musicSrc: (state) =>
        state.music.timerange
            ? conversions.mediaFragmentAssetSrc(state.music.src, state.music.totalDuration(), state.music.timerange)
            : state.music.src,

    libraryMusicSrc: (state) =>
        state.music.timerange
            ? conversions.mediaFragmentAssetSrc(
                  state.music.library_src,
                  state.music.totalDuration(),
                  state.music.timerange
              )
            : state.music.library_src
};

const mutations = {
    setAudioTimeline: timeline.mutations.setTimeline,

    setID(state, id) {
        state.id = id;
    },

    setName(state, name) {
        state.name = name;
    },

    setMusic(state, src) {
        state.music.src = src;
    },

    setLibraryMusic(state, src) {
        state.music.library_src = src;
    },

    setMusicStart(state, start) {
        state.music.start = start;
    },

    setMusicVolume(state, volume) {
        let val = conversions.percentNum(volume.value, false),
            custom = conversions.percentNum(volume.custom, false);
        if (val !== undefined) {
            state.music.volume.value = val;
            state.music.volume.custom = Sound.VOLUME_EMPTY;
        } else if (custom !== undefined) {
            state.music.volume.value = Sound.VOLUME_CUSTOM;
            state.music.volume.custom = custom;
        } else {
            state.music.volume.value = Sound.VOLUME_DEFAULT;
            state.music.volume.custom = Sound.VOLUME_EMPTY;
        }
    },

    setMusicTimerange(state, timerange) {
        state.music.timerange = Object.assign({ start: 0, end: -1 }, timerange || {});
    },

    setMusicTotalDuration(state, duration) {
        state.music.totalDuration = () => duration;
    },

    enableTrack(state, enabled) {
        state.track.enabled = enabled;
    },

    setTrack(state, src) {
        state.track.src = src;
    },

    setTrackCaptions(state, captions) {
        state.track.captions = Array.isArray(captions) && captions.length && captions;
    },

    setTrackVolume(state, volume) {
        let val = conversions.percentNum(volume.value, false),
            custom = conversions.percentNum(volume.custom, false);
        if (val !== undefined) {
            state.track.volume.value = val;
            state.track.volume.custom = Sound.VOLUME_EMPTY;
        } else if (custom !== undefined) {
            state.track.volume.value = Sound.VOLUME_CUSTOM;
            state.track.volume.custom = custom;
        } else {
            state.track.volume.value = Sound.VOLUME_DEFAULT;
            state.track.volume.custom = Sound.VOLUME_EMPTY;
        }
    },

    enableEndFade(state, enabled) {
        state.fade.end.enabled = enabled;
    },

    setIsTemplate(state, isTemplate) {
        state.isTemplate = isTemplate;
    },

    setLanguage(state, language) {
        state.language = language;
    },

    setCategories(state, categories) {
        state.categories = categories;
    },

    setCompatibleFormats(state, formats) {
        state.compatibleFormats = formats;
    },

    setDescription(state, description) {
        state.description = description;
    },

    setNeedsMigration(state, needsMigration) {
        state.needsMigration = needsMigration;
    },

    setMusicType(state, type) {
        state.music.type = type;
    },

    setMusicReference(state, ref) {
        state.music.src__ref = ref;
    },

    setMusicId(state, id) {
        state.music.src__id = id;
    },

    setLibraryMusicReference(state, ref) {
        state.music.library_src__ref = ref;
    },

    setLibraryMusicId(state, id) {
        state.music.library_src__id = id;
    },

    setTrackReference(state, ref) {
        state.track.src__ref = ref;
    },

    setTrackId(state, id) {
        state.track.src__id = id;
    },

    enableAi(state, enable) {
        state.ai.enabled = enable;
    },

    setAiSettings(state, { id, name, duration, metadata }) {
        state.ai.id = id;
        state.ai.name = name || '';
        state.ai.duration = duration;
        state.ai.metadata = _merge({}, metadata);
    },

    setAiFormats(state, formats) {
        state.ai.formats = { ...formats };
    }
};

const actions = {
    init({ commit, dispatch }, data) {
        commit('setID', data.id);
        commit('setName', data.name);
        dispatch('initLogo', data.logo);
        dispatch('initCover', data.cover);
        dispatch('initBorders', data.borders);
        dispatch('initCaptions', data.captions);
        if (data.tts) dispatch('tts/init', data.tts);
        commit('setMusic', data.music.src);
        commit('setLibraryMusic', data.music.library_src);
        commit('setMusicStart', data.music.start);
        commit('setMusicVolume', data.music.volume);
        commit('setMusicTimerange', data.music.timerange);
        commit('enableTrack', data.track.enabled);
        commit('setTrack', data.track.src);
        commit('setTrackCaptions', data.track.captions);
        commit('setTrackVolume', data.track.volume);
        commit('enableEndFade', data.fade.end.enabled);

        if (data.thumbnailUrl)
            commit('ui/files/setFileToType', { type: 'templateThumbnail', data: data.thumbnailUrl }, { root: true });

        commit('setIsTemplate', data.isTemplate);
        if (data.hasOwnProperty('language')) {
            commit('setLanguage', data.language);
        }
        if (data.hasOwnProperty('categories')) {
            commit('setCategories', data.categories);
        }
        if (data.hasOwnProperty('compatibleFormats')) {
            commit('setCompatibleFormats', data.compatibleFormats);
        }
        if (data.hasOwnProperty('description')) {
            commit('setDescription', data.description);
        }
        commit('setNeedsMigration', data.needsMigration);
        commit('setMusicType', data.music.type || UNIVERSAL_MUSIC_CATEGORY);
        commit('setMusicId', data.music.src__id);
        commit('setMusicReference', data.music.src__ref);
        commit('setLibraryMusicId', data.music.library_src__id);
        commit('setLibraryMusicReference', data.music.library_src__ref);
        commit('setTrackId', data.track.src__id);
        commit('setTrackReference', data.track.src__ref);

        if (data.hasOwnProperty('ai')) {
            commit('enableAi', data.ai.enabled);
            commit('setAiSettings', data.ai.settings);
            commit('setAiFormats', data.ai.settings.formats);
        }
    },

    updateMusic({ commit, dispatch }, data) {
        dispatch('resetMusicTimerange', { ...data, src_key: 'src__id' });
        commit('setMusicId', data.src__id);
        commit('setMusic', data.src);
    },

    updateLibraryMusic({ commit, dispatch }, data) {
        dispatch('resetMusicTimerange', { ...data, src_key: 'library_src__id' });
        commit('setLibraryMusicId', data.src__id);
        commit('setLibraryMusic', data.src);
    },

    resetMusicTimerange({ commit, state }, data) {
        if (!data.src__id) {
            commit('setMusicTotalDuration', 0);
        }
        if (state.music[data.src_key] !== data.src__id) {
            commit('setMusicTimerange', { start: 0, end: -1 });
        }
    },

    updateTrack({ commit }, data) {
        commit('setTrackId', data.src__id);
        commit('setTrack', data.src);
    },

    resetState({ state, rootState }) {
        let currentState = _merge({}, state),
            originalState = _merge({}, rootState.ui.history.originalState().settings);

        rootState.settings = _mergeWith({}, currentState, originalState, (objValue, srcValue, key) =>
            _isArray(srcValue) || key == 'metadata' || key == 'formats' ? srcValue : undefined
        );
    },

    clearState({ state }) {
        Object.assign(state, originalState());
    }
};

export default {
    namespaced: true,

    modules: {
        logo: logo,
        cover: cover,
        borders: borders,
        captions: captions,
        tts: tts
    },

    state,
    getters,
    mutations,
    actions
};
