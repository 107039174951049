<template>
    <div>
        <div class="home-view-templates-banner-header">
            <h2 class="underline-selected">{{ $t('Templates') }}</h2>
            <div class="home-view-templates-filters">
                <UiDropdown
                    v-show="languageOptions.length > 1"
                    id="template-language-filter"
                    :items="languageOptions"
                    v-model="selectedLanguage"
                    :toggleStyle="{ height: '100%' }"
                    :default-label="$t('Language')"
                    select
                    class="dropdown-center"
                />
                <BrandSelector :modelValue="localSelectedBrandId" @update:modelValue="updateSelectedBrand" />
                <UiTextInput
                    id="home-templates-search"
                    type="search"
                    :placeholder="$t('Search')"
                    :show-label="false"
                    icon="search-icon"
                    v-model="localTemplateFilterText"
                />
            </div>
        </div>
        <div class="home-view-templates-banner-categories">
            <div class="template-format-filter-list">
                <TemplateFormatItem
                    v-for="format in formats"
                    :key="format"
                    :format="format"
                    :is-selected="selectedTemplateFormat === format"
                    @select="selectFormat"
                />
            </div>
            <div class="template-filter-separator" />
            <div class="template-category-filter-list">
                <UiScrollableHorizontal class="template-category-filter-list">
                    <TemplateCategoryItem
                        v-for="category in templateCategories"
                        :key="category"
                        :category="category"
                        :is-selected="selectedTemplateCategory === category"
                        @select="selectCategory"
                    />
                </UiScrollableHorizontal>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import BrandSelector from './BrandSelector.vue?inline';
import UiScrollableHorizontal from '@/js/components/UiScrollableHorizontal.vue';
import TemplateCategoryItem from './TemplateCategoryItem.vue?inline';
import TemplateFormatItem from './TemplateFormatItem.vue?inline';
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiTextInput from '@/js/components/UiTextInput.vue';
import LocalStorageManager from '@/js/infrastructure/local_storage/LocalStorageManager';
import i18n from '@/libs/i18n.js';

const props = defineProps({
    templateCategories: {
        type: Array,
        required: true
    },
    formats: {
        type: Array,
        required: true
    },
    selectedBrandId: {
        type: [String, null],
        default: null
    },
    templateFilterText: {
        type: String,
        default: ''
    },
    selectedTemplateCategory: {
        type: String,
        default: 'all'
    },
    selectedTemplateFormat: {
        type: String,
        default: 'landscape'
    },
    selectedLanguage: {
        type: String,
        default: 'fr'
    }
});

const emit = defineEmits([
    'update:selectedBrandId',
    'update:templateFilterText',
    'update:selectedTemplateCategory',
    'update:selectedTemplateFormat',
    'update:selectedLanguage'
]);

const store = useStore();

const LANGUAGE_STORAGE_KEY = 'selectedLanguage';

const localSelectedBrandId = computed({
    get: () => props.selectedBrandId,
    set: (value) => emit('update:selectedBrandId', value)
});

const updateSelectedBrand = (newValue: string) => {
    localSelectedBrandId.value = newValue;
};

const localTemplateFilterText = computed({
    get: () => props.templateFilterText,
    set: (value) => emit('update:templateFilterText', value)
});

const localSelectedTemplateCategory = computed({
    get: () => props.selectedTemplateCategory,
    set: (value) => emit('update:selectedTemplateCategory', value)
});

const localSelectedTemplateFormat = computed({
    get: () => props.selectedTemplateFormat,
    set: (value) => emit('update:selectedTemplateFormat', value)
});

const selectCategory = (category: string) => {
    localSelectedTemplateCategory.value = category;
};

const selectFormat = (format: string) => {
    localSelectedTemplateFormat.value = format;
};

const enabledTemplateLanguages = computed(() => store.state.branding.enabledTemplateLanguages);

const initializeLanguage = () => {
    if (!store.state.branding.enabledTemplateLanguages?.length) {
        return;
    }

    const savedLanguage = LocalStorageManager.load(LANGUAGE_STORAGE_KEY);

    if (savedLanguage && store.state.branding.enabledTemplateLanguages.includes(savedLanguage)) {
        emit('update:selectedLanguage', savedLanguage);
        return;
    }

    const firstEnabledLanguage = store.state.branding.enabledTemplateLanguages[0];
    if (firstEnabledLanguage) {
        emit('update:selectedLanguage', firstEnabledLanguage);
    }
};

const resetLanguageAndInitialize = () => {
    initializeLanguage();
};

watch(
    [enabledTemplateLanguages, localSelectedBrandId],
    ([newLanguages, newBrandId]) => {
        if (newLanguages?.length || newBrandId) {
            resetLanguageAndInitialize();
        }
    },
    { immediate: true }
);

const languageOptions = computed(() => {
    const enabledLanguages = store.state.branding.enabledTemplateLanguages;
    return enabledLanguages.map((lang: string) => ({
        value: lang,
        label: i18n.global.t(`languages.${lang}`) || lang
    }));
});

const selectedLanguage = computed({
    get: () => props.selectedLanguage,
    set: (value) => {
        emit('update:selectedLanguage', value);
        LocalStorageManager.save(LANGUAGE_STORAGE_KEY, value);
    }
});

const shouldShowLanguageSelector = computed(() => {
    return languageOptions.value.length >= 1;
});
</script>

<style scoped>
.home-view-templates-filters {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.dropdown-center {
    display: flex;
    align-items: center;
}
</style>
