<template>
    <ui-video-card
        :id="cardElementId"
        :card-name="$t('Background')"
        :selected="editing"
        classes="ui-video-card-background video-onboarding-step-3-content"
        @click.left="selectBackground"
    >
        <template #selector>
            <ui-background-type-selector :seq-id="seqId" />
        </template>

        <template #content>
            <ui-media-type-selector
                v-if="backgroundTypes"
                :id="'card-seq-' + seqId + '-bg-type'"
                :seqId="seqId"
                v-model="backgroundType"
                :background-types="backgroundTypes"
                :background-type="backgroundType"
                :read-only="readOnly"
            />
        </template>

        <template #actions-menu-content>
            <ui-background-copy-selector :seq-id="seqId" :id="id" />
            <ui-background-paste-selector :seq-id="seqId" :id="id" />
        </template>

        <template #actions-tools>
            <ui-background-tools :seq-id="seqId" :card-element-id="cardElementId" />
        </template>

        <template #second-row v-if="hasCollageBackground">
            <ui-background-collage-images :seq-id="seqId" />
        </template>
    </ui-video-card>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';

import UiVideoCard from '@/js/videos/components/ui/body/UiVideoCard.vue';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import UiBackgroundCopySelector from '@/js/videos/components/ui/body/selectors/ui/UiBackgroundCopySelector.vue';
import UiBackgroundPasteSelector from '@/js/videos/components/ui/body/selectors/ui/UiBackgroundPasteSelector.vue';
import UiMediaTypeSelector from '@/js/videos/components/ui/body/selectors/media/UiMediaTypeSelector.vue';
import { Background } from '@/js/video-studio/constants/index.js';
import { isQuickCutCardButtonClicked } from '@/js/utils.js';
import UiBackgroundTypeSelector from '@/js/videos/components/ui/body/selectors/UiBackgroundTypeSelector.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import UiBackgroundTools from '@/js/videos/components/ui/body/cards/tools/background/UiBackgroundTools.vue';
import { SEQUENCE_ELEMENT_ID } from '@/js/constants/index.js';
import UiBackgroundCollageImages from '@/js/videos/components/ui/body/cards/tools/background/UiBackgroundCollageImages.vue';

const props = defineProps({
    id: {
        type: String,
        default: SEQUENCE_ELEMENT_ID
    },
    seqId: String,
    editing: Boolean
});

const cardElementId = computed(() => props.seqId + '-' + props.id);

const store = useStore();
const { saveHistoryStep } = useHistory();
const { sequenceState, sequenceStoreModulePath, hasCollageBackground, backgroundType } = useSequence(props.seqId);
const videoStudio = inject('$videoStudio');

const { readOnly, isEmotionMode } = useVideo();
const backgroundTypes = computed(() => (isEmotionMode.value ? Background.TYPES.slice(0, -2) : contentModeCategories()));

const contentModeCategories = () => {
    let cat1 = [Background.IMAGE_TYPE, Background.VIDEO_TYPE, Background.COLLAGE_TYPE, Background.RECORDING_TYPE],
        cat2 = [Background.ANIMATED_TYPE, Background.CARTOON_TYPE],
        cat3 = [Background.COLOR_TYPE],
        cat4 = [Background.MAP_ZOOM_TYPE],
        actualType = backgroundType.value;

    return Background.TYPES.slice(0, -2).filter((type) => {
        return (
            (cat1.includes(type) && cat1.includes(actualType)) ||
            (cat2.includes(type) && cat2.includes(actualType)) ||
            (cat3.includes(type) && cat3.includes(actualType)) ||
            (cat4.includes(type) && cat4.includes(actualType))
        );
    });
};

const selectBackground = (e) => {
    if (!isQuickCutCardButtonClicked(e.composedPath()))
        videoStudio.value.studio.$stage.startEditingSequence(props.seqId);
};
</script>
