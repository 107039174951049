<template>
    <ui-message-color-selector :seq-id="seqId" :element-id="elementId" :card-element-id="cardElementId" />

    <ui-message-duration-selector :seq-id="seqId" :element-id="elementId" :card-element-id="cardElementId" />

    <ui-message-effect-selector :seq-id="seqId" :element-id="elementId" :card-element-id="cardElementId" />
</template>

<script setup>
import { useVideo } from '@/js/videos/composables/useVideo.js';
import UiMessageDurationSelector from '@/js/videos/components/ui/body/selectors/duration/UiMessageDurationSelector.vue';
import UiMessageEffectSelector from '@/js/videos/components/ui/body/selectors/effect/UiMessageEffectSelector.vue';
import UiMessageColorSelector from '@/js/videos/components/ui/body/selectors/color/UiMessageColorSelector.vue';

const props = defineProps({
    seqId: String,
    cardElementId: String,
    elementId: String
});

const { isEmotionMode } = useVideo();
</script>
