<template>
    <section
        class="ui-body-panel"
        role="application"
        :aria-description="sprintf($t('Sequence %1$s settings'), sequenceIndex + 1)"
    >
        <header class="ui-sequence-panel-header">
            <ui-sequence-name-input :key="'sc-' + seqId + '-name-input'" :seq-id="seqId" />

            <div class="ui-sequence-panel-header-actions">
                <ui-sequence-header-actions :key="'sc-' + seqId + '-actions'" :seq-id="seqId" />
                <add-sequence-element-button
                    :seq-id="seqId"
                    :show-label="false"
                    :show-tooltip="true"
                    @new-card="emitAddedCard"
                />
            </div>
        </header>

        <div class="ui-card-container">
            <card-sequence-brand-logo v-if="brandLogoEnabled" :key="'sc-' + seqId + '-brand-logo'" :seq-id="seqId" />

            <card-sequence-message
                v-for="(message, index) in messages"
                :key="'sc-' + seqId + '-' + message.id"
                :seq-id="seqId"
                :element-id="message.id"
                :editing="!!editedElement && editedElement.id === message.id"
                :number="index + 1"
            />

            <card-sequence-visual
                v-for="(visual, index) in visuals"
                :key="'sc-' + seqId + '-' + visual.id"
                :seq-id="seqId"
                :element-id="visual.id"
                :editing="!!editedElement && editedElement.id === visual.id"
                :number="index + 1"
                ref="cardSequenceVisual"
            />

            <card-sequence-footer
                :seq-id="seqId"
                :key="'sc-' + seqId + '-footer'"
                v-if="hasFooter"
                :added-card-id="addedCardId"
            />

            <card-sequence-panel
                :seq-id="seqId"
                :key="'sc-' + seqId + '-panel'"
                v-if="hasPanel"
                :editing="!!editedElement && editedElement.id === seqId + '-panel'"
            />

            <card-sequence-audio
                :seq-id="seqId"
                :key="'sc-' + seqId + '-audio'"
                v-if="hasAudio"
                :added-card-id="addedCardId"
            />

            <card-sequence-voice-over :seq-id="seqId" :key="'sc-' + seqId + '-voice-over'" v-if="hasTts" />

            <card-sequence-background
                :seq-id="seqId"
                :key="'sc-' + seqId + '-background'"
                :editing="!!editedElement && editedElement.id === seqId"
            />
        </div>

        <footer class="ui-sequence-panel-footer">
            <add-sequence-element-button :seq-id="seqId" @new-card="emitAddedCard" />
        </footer>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import { printf as sprintf } from 'fast-printf';
import { CardSequenceFooter } from '../cards';
import { Visual } from '../../../../../video-studio/constants';
import AddSequenceElementButton from '@/js/videos/components/ui/body/buttons/AddSequenceElementButton.vue';
import UiSequenceHeaderActions from '@/js/videos/components/ui/body/sequence-panel/header/UiSequenceHeaderActions.vue';
import CardSequenceBrandLogo from '@/js/videos/components/ui/body/cards/CardSequenceBrandLogo.vue';
import CardSequenceBackground from '@/js/videos/components/ui/body/cards/CardSequenceBackground.vue';
import CardSequenceAudio from '@/js/videos/components/ui/body/cards/CardSequenceAudio.vue';
import CardSequenceVoiceOver from '@/js/videos/components/ui/body/cards/CardSequenceVoiceOver.vue';
import CardSequencePanel from '@/js/videos/components/ui/body/cards/CardSequencePanel.vue';
import CardSequenceMessage from '@/js/videos/components/ui/body/cards/CardSequenceMessage.vue';
import CardSequenceVisual from '@/js/videos/components/ui/body/cards/CardSequenceVisual.vue';
import UiSequenceNameInput from '@/js/videos/components/ui/body/sequence-panel/header/UiSequenceNameInput.vue';

export const UI_SEQUENCE_ADDED_CARD = 'ui-sequence-added-card';

export default {
    components: {
        UiSequenceNameInput,
        CardSequenceVisual,
        CardSequenceMessage,
        CardSequencePanel,
        CardSequenceVoiceOver,
        CardSequenceAudio,
        CardSequenceBackground,
        CardSequenceBrandLogo,
        UiSequenceHeaderActions,
        AddSequenceElementButton,
        CardSequenceFooter
    },

    props: {
        seqId: {
            type: String,
            default: ''
        },
        editedElement: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            addedCardId: null
        };
    },

    computed: {
        ...mapState({
            brandLogoEnabled: (state) => state.settings.logo.enabled
        }),

        sequenceStoreModulePath() {
            return 'sequences/' + this.seqId;
        },

        sequenceIndex() {
            return this.$store.getters[this.sequenceStoreModulePath + '/index'];
        },

        messages() {
            return this.$store.getters[this.sequenceStoreModulePath + '/messages'];
        },

        visuals() {
            return this.$store.getters[this.sequenceStoreModulePath + '/visuals'];
        },

        hasPanel() {
            return this.$store.getters[this.sequenceStoreModulePath + '/hasPanel'];
        },
        hasAudio() {
            return this.$store.getters[this.sequenceStoreModulePath + '/hasAudio'];
        },
        hasFooter() {
            return this.$store.getters[this.sequenceStoreModulePath + '/hasFooter'];
        },
        hasTts() {
            return this.$store.getters[this.sequenceStoreModulePath + '/hasTts'];
        }
    },

    watch: {},

    methods: {
        sprintf,

        emitAddedCard(card) {
            // id, category
            this.addedCardId = this.seqId + '-' + card.id;
            this.$emit(UI_SEQUENCE_ADDED_CARD, card.id, this.seqId, '#' + this.seqId + '-' + card.id);
            if (card.id.includes('visual')) {
                this.emitVisualAddedCard(card);
            }
        },

        emitVisualAddedCard(card) {
            // id, category
            let visualCard = this.$refs.cardSequenceVisual.find((ref) => ref.elementId === card.id);
            visualCard.visualCategory = card.category;
            visualCard.triggerCategoryDropdown();
            if (card.category === Visual.IMAGE_CATEGORY) {
                visualCard.openMediaLibrary(visualCard.$refs.$visualTypeSelector.$refs.$imageVisualSelector);
            }
        }
    },

    mounted() {
        //
    }
};
</script>
