<template>
    <ui-dropdown
        :id="seqId + '-duration'"
        :caret="false"
        :menu="false"
        toggle-class="ui-dropdown-action"
        :toggle-style="!isSequenceDurationAuto ? { color: 'var(--edit-mode-color)' } : {}"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-clock" />
            <span>{{ sequenceDuration }}s</span>
        </template>

        <div class="sequence-duration">
            <ui-number-input
                :id="cardElementId + '-duration'"
                :class="{ auto: isSequenceDurationAuto }"
                v-model="sequenceDuration"
                :min="0"
                :label="$t('Sequence duration (in s.)')"
                :disabled="readOnly || sequenceState.options.hidden"
            />
            <label
                :for="cardElementId + '-duration-auto'"
                class="sequence-duration-auto"
                :class="{ disabled: readOnly || sequenceState.options.hidden }"
            >
                <input
                    type="checkbox"
                    :id="cardElementId + '-duration-auto'"
                    :checked="isSequenceDurationAuto"
                    :disabled="readOnly || sequenceState.options.hidden"
                    @change="handleSequenceDurationAutoChange"
                />
                <span>{{ $t('Auto') }}</span>
            </label>
        </div>
    </ui-dropdown>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import UiDropdown from '@/js/components/UiDropdown.vue';
import { Duration } from '@/js/video-studio/constants/index.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { useDisplay } from '@/js/videos/composables/useDisplay.js';
import UiNumberInput from '@/js/components/UiNumberInput.vue';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';

const props = defineProps({
    seqId: String,
    cardElementId: String
});

const store = useStore();
const { saveHistoryStep } = useHistory();
const { readOnly } = useVideo();
const { sequenceState, isLastVisibleSequence, isNextVisibleSequence, sequenceStoreModulePath } = useSequence(
    props.seqId
);
const { labels } = useDisplay();

const sequenceTotalTime = computed(() => {
    if (!sequenceState.value?.options?.hidden) {
        const endTime = labels.value[isLastVisibleSequence.value ? 'end' : isNextVisibleSequence.value.id];
        const startTime = labels.value[props.seqId];
        return Number((endTime - startTime).toFixed(2));
    }
    return '';
});

const isSequenceDurationAuto = computed(() => sequenceState.value.options.duration === Duration.NONE);
const sequenceDuration = computed({
    get: () => (!isSequenceDurationAuto.value ? sequenceState.value.options.duration : sequenceTotalTime.value),
    set: (value) => handleDurationChange(value)
});

const handleSequenceDurationAutoChange = () => {
    sequenceDuration.value = isSequenceDurationAuto.value ? sequenceTotalTime.value : Duration.NONE;
};

const handleDurationChange = (value) => {
    if (value === 0) value = Duration.NONE;
    // Save history step before committing changes
    saveHistoryStep(() => {
        store.commit(`${sequenceStoreModulePath.value}/setDuration`, value);
    });
};
</script>
