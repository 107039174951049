<template>
    <ui-dropdown
        :id="cardElementId + '-track-timing'"
        class="centered timing-dropdown"
        :menu="false"
        :caret="false"
        :scrollable="false"
        :disabled="readOnly || isMediaConverting || !track.src"
        :tooltip="isMediaConverting ? $t('media.preparation.warning') : $t('Timing')"
        menu-placement="bottom"
        :icon-only="true"
        :toggle-style="toggleTimingAudioStyle"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-stopwatch" />
        </template>

        <ui-number-input
            :id="cardElementId + '-track-start'"
            class="ui-content-row"
            v-model="trackStart"
            :default-value="trackStartDefault"
            :min="0"
            :label="$t('Starting time')"
            :disabled="readOnly"
        />
    </ui-dropdown>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import UiNumberInput from '@/js/components/UiNumberInput.vue';
import UiDropdown from '@/js/components/UiDropdown.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { Duration } from '@/js/video-studio/constants/index.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';

const props = defineProps({
    seqId: String,
    cardElementId: String
});

const store = useStore();
const { readOnly } = useVideo();
const { saveHistoryStep } = useHistory();
const { sequenceState, sequenceStoreModulePath } = useSequence(props.seqId);

const trackStartDefault = Duration.START_DEFAULT;

const isMediaConverting = computed(() => store.getters['loading/isConverting'](track.value.src));
const track = computed(() => ({
    src: sequenceState.value.audio.track.src,
    id: sequenceState.value.audio.track.src__id
}));

const trackStart = computed({
    get: () => store.getters[sequenceStoreModulePath.value + '/trackStart'],
    set: (value) => {
        if (value !== trackStart.value) {
            saveHistoryStep(() => {
                store.commit(`${sequenceStoreModulePath.value}/setTrackStart`, { value, custom: Duration.NONE });
            });
        }
    }
});

const toggleTimingAudioStyle = computed(() => {
    if (trackStart.value !== trackStartDefault) {
        return { color: 'var(--edit-mode-color)' };
    } else return {};
});
</script>
