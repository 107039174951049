import { useStore } from 'vuex';
import { computed } from 'vue';
import { useBrand } from '@/js/videos/composables/useBrand.js';

export function useVideo(store = null) {
    if (!store) {
        store = useStore();
    }

    const { libraryTagOrders } = useBrand();

    const readOnly = computed(() => store.state.ui.readOnly);
    const libraryId = computed(() => store.state.ui.currentLibrary.id);
    const restrictions = computed(() => store.state.ui.restrictions);

    const availableFormats = computed(() => store.state.ui.availableFormats);
    const standardFormats = computed(() => store.getters['ui/availableStandardFormats']);

    const saveVideo = (video) => {
        store.dispatch('ui/saveVideo', video);
    };

    const isEmotionMode = computed(() => {
        return store.getters['ui/isEmotionMode'];
    });

    const openMediaLibrary = (selector) => {
        store.commit('ui/setCurrentMediaLibrary', {
            mediaType: selector.type,
            selectedMediaId: selector.media.id,
            selector
        });
    };

    const openMusicLibrary = (selector) => {
        store.commit('ui/setCurrentLibrary', {
            id: selector.library,
            category: selector.category,
            tagOrder: selector.tagOrder || libraryTagOrders.value[selector.library] || null,
            selectedItemId: selector.modelValue.id,
            selector
        });
    };

    const openLibrary = (selector) => {
        const isMusicLibrary = selector.library === 'musics';

        store.commit('ui/setCurrentLibrary', {
            id: selector.library,
            category: selector.category,
            tagOrder: selector.tagOrder || libraryTagOrders.value[selector.library] || null,
            selectedItemId: isMusicLibrary ? selector.modelValue.id : selector.modelValue,
            selector
        });
    };

    return {
        saveVideo,
        isEmotionMode,
        readOnly,
        libraryId,
        restrictions,
        availableFormats,
        standardFormats,
        openMediaLibrary,
        openMusicLibrary,
        openLibrary
    };
}
