<template>
    <ui-video-card :id="id" classes="ui-video-card-voice-over" :selectable="false" :card-name="$t('Video voice-over')">
        <template #selector>
            <ui-voice-over-type-selector
                :seq-id="null"
                :card-element-id="id"
                :use-in-sequence="useInSequence"
                :trigger-selection="triggerSelection"
            />
        </template>

        <template #content>
            <ui-dropdown
                :id="id + '-tts-category'"
                :select="true"
                v-model="voiceOverCategory"
                :disabled="readOnly"
                :default-label="$t('Add voice-over')"
                menu-strategy="fixed"
                @[dropdownCategorySelectEvent]="triggerCategoryDropdown"
            >
                <template #dropdown-toggle="{ instance }">
                    <svg-icon v-if="isVoiceCategory && isProcessing" icon="dots-loader" />
                    <template v-else>
                        <fa-icon :icon="categoryIcons[voiceOverCategory]" class="icon" />
                        <span>{{ instance.toggleLabel }}</span>
                    </template>
                </template>

                <ui-dropdown-item
                    v-for="category in ttsCategories"
                    :key="category"
                    :value="category"
                    :label="$t('studio.tts_categories.' + category)"
                    :disabled="recordingDisabled(category)"
                    :show-icon-on-disabled="true"
                    v-tooltip="recordingDisabled(category) && $t('studio.features.recording-disabled')"
                >
                    <template #dropdown-menu-item-label="{ instance }">
                        <fa-icon :icon="categoryIcons[instance.value]" class="icon" />
                        {{ instance.label || instance.value }}
                    </template>
                </ui-dropdown-item>
            </ui-dropdown>
        </template>

        <template #actions-tools>
            <ui-voice-over-tools :seq-id="null" :card-element-id="id" :use-in-sequence="useInSequence" />
        </template>
    </ui-video-card>
</template>

<script setup>
import { ref } from 'vue';
import UiVideoCard from '@/js/videos/components/ui/body/UiVideoCard.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import UiVoiceOverTypeSelector from '@/js/videos/components/ui/body/selectors/UiVoiceOverTypeSelector.vue';
import { UiDropdownItem } from '@/js/components/index.js';
import UiDropdown, { UI_DROPDOWN_SELECT_CHANGE } from '@/js/components/UiDropdown.vue';
import { useVoiceOver } from '@/js/videos/composables/useVoiceOver.js';
import { TTS } from '@/js/video-studio/constants/index.js';
import { RECORDING_ELEMENT_ID } from '@/js/constants/index.js';
import { useAuth } from '@/js/composables/useAuth.js';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';
import UiVoiceOverTools from '@/js/videos/components/ui/body/cards/tools/UiVoiceOverTools.vue';

const vTooltip = UsesTooltip.directives.tooltip;

const props = defineProps({
    id: {
        type: String,
        default: 'settings-voice-over'
    }
});

const ttsCategories = TTS.CATEGORIES;
const dropdownCategorySelectEvent = UI_DROPDOWN_SELECT_CHANGE;
const useInSequence = ref(false);

const { user } = useAuth();
const { readOnly } = useVideo();
const { voiceOverCategory, isVoiceCategory, isProcessing, categoryIcons } = useVoiceOver(useInSequence, null);

const triggerSelection = ref(false);
const triggerCategoryDropdown = () => (triggerSelection.value = true);

const recordingDisabled = (category) => category === RECORDING_ELEMENT_ID && !user.value.client.enableRecording;
</script>
