<template>
    <article :id="id" :class="cardClass">
        <div class="ui-video-card-row">
            <div v-if="slots.selector" class="ui-video-card-selector">
                <slot name="selector" />
            </div>

            <div class="ui-video-card-content">
                <div class="ui-video-card-header">
                    <div class="ui-video-card-name">
                        <span>{{ cardName }}</span>
                        <fa-icon v-if="nameable" class="icon" icon="fa-regular fa-pen" />
                    </div>

                    <ui-dropdown
                        v-if="showMenu && hasMenu && isEmotionMode"
                        :id="id + 'card-menu'"
                        :icon="true"
                        :caret="false"
                        menu-placement="bottom-end"
                        class="ui-video-card-actions-menu"
                        :tooltip="$t('Options')"
                    >
                        <template #dropdown-toggle>
                            <svg-icon icon="menu-icon" />
                        </template>

                        <slot name="actions-menu-content" />
                    </ui-dropdown>
                </div>

                <div class="ui-video-card-actions">
                    <slot name="content" />

                    <div class="ui-video-card-actions-toolbar">
                        <slot name="actions-tools" />
                    </div>
                </div>
            </div>
        </div>

        <div v-if="slots['second-row']" class="ui-video-card-row">
            <slot name="second-row" />
        </div>

        <div v-if="slots['third-row']" class="ui-video-card-row">
            <slot name="third-row" />
        </div>
    </article>
</template>

<script setup>
import { computed, useSlots } from 'vue';
import UiDropdown from '@/js/components/UiDropdown.vue';
import { useStore } from 'vuex';

const props = defineProps({
    id: String,
    cardName: String,
    showMenu: {
        type: Boolean,
        default: true
    },
    selectable: {
        type: Boolean,
        default: true
    },
    selected: Boolean,
    classes: {
        type: [String, Array, Object],
        default: ''
    },
    nameable: Boolean
});

const slots = useSlots();
const store = useStore();

const hasMenu = computed(() => !!slots['actions-menu-content']);
const isEmotionMode = computed(() => store.getters['ui/isEmotionMode']);

const cardClass = computed(() => [
    'ui-video-card',
    {
        selectable: props.selectable,
        selected: props.selected
    },
    props.classes
]);
</script>
