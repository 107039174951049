<template>
    <ui-dropdown-item :disabled="!canCopyCard" @click.prevent="elementStoreModulePath ? copyElementCard() : copyCard()">
        <template #dropdown-menu-item-label="{ instance }">
            <fa-icon class="icon" icon="fa-regular fa-copy" />
            {{ $t('Copy') }}
        </template>
    </ui-dropdown-item>

    <ui-dropdown-item
        :disabled="!canPasteCard"
        @click.prevent="elementStoreModulePath ? pasteElementCard() : pasteCard()"
    >
        <template #dropdown-menu-item-label="{ instance }">
            <fa-icon class="icon" icon="fa-regular fa-clipboard" />
            {{ $t('Paste') }}
        </template>
    </ui-dropdown-item>

    <ui-dropdown-item
        v-if="isEmotionMode && enableDuplicate"
        classes="ui-duplicate-item"
        :disabled="!canDuplicate"
        @click.prevent="duplicateActions"
    >
        <template #dropdown-menu-item-label="{ instance }">
            <fa-icon class="icon" icon="fa-regular fa-clone" />
            {{ $t('Duplicate') }}
        </template>
    </ui-dropdown-item>

    <ui-dropdown-item
        v-if="isEmotionMode"
        classes="ui-remove-item"
        :disabled="!canRemoveCard"
        @click.prevent="removeCard"
    >
        <template #dropdown-menu-item-label="{ instance }">
            <fa-icon class="icon" icon="fa-regular fa-trash-can" />
            {{ $t('Remove') }}
        </template>
    </ui-dropdown-item>
</template>

<script setup>
import { useStore } from 'vuex';
import UiDropdownItem from '@/js/components/UiDropdownItem.vue';
import { useCard } from '@/js/videos/composables/useCard.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';

const props = defineProps({
    id: String,
    seqId: String,
    removeActions: Function,
    enableDuplicate: {
        type: Boolean,
        default: false
    },
    canDuplicate: {
        type: Boolean,
        default: false
    },
    duplicateActions: {
        type: Function,
        default: null
    },
    elementStoreModulePath: {
        type: String,
        default: ''
    }
});

const store = useStore();
const { isEmotionMode } = useVideo();
const { saveHistoryStep } = useHistory();
const { sequenceStoreModulePath } = useSequence(props.seqId);
const { canCopyCard, canPasteCard, copyCard, pasteCard, canRemoveCard, copyElementCard, pasteElementCard } = useCard(
    props.seqId,
    props.id,
    props.elementStoreModulePath
);

const removeCard = () => saveHistoryStep(props.removeActions);
</script>
