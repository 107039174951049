<template>
    <ui-background-caption-selector
        v-if="hasVideoBackground || hasRecordingBackground"
        :seq-id="seqId"
        :card-element-id="cardElementId"
        :read-only="readOnly || isMediaConverting"
        :tooltip="isMediaConverting ? $t('media.preparation.warning') : ''"
        :icon-only="true"
    />

    <ui-background-video-time-range-selector
        v-if="hasVideoBackground || hasRecordingBackground"
        :seq-id="seqId"
        :card-element-id="cardElementId"
        :read-only="readOnly || isMediaConverting"
        :tooltip="isMediaConverting ? $t('media.preparation.warning') : ''"
        :icon-only="true"
    />

    <ui-background-volume-selector
        v-if="hasVideoBackground || hasRecordingBackground"
        :seq-id="seqId"
        :card-element-id="cardElementId"
        :readOnly="readOnly"
        :icon-only="true"
    />

    <ui-background-video-speed-selector
        v-if="hasVideoBackground || hasRecordingBackground"
        :seq-id="seqId"
        :element-id="cardElementId"
        :read-only="readOnly || isMediaConverting"
        :tooltip="isMediaConverting ? $t('media.preparation.warning') : ''"
        :icon-only="true"
    />

    <ui-background-effect-selector v-if="!hasColorBackground" :seq-id="seqId" :card-element-id="cardElementId" />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import UiBackgroundCaptionSelector from '@/js/videos/components/ui/body/selectors/caption/UiBackgroundCaptionSelector.vue';
import UiBackgroundVideoTimeRangeSelector from '@/js/videos/components/ui/body/selectors/time-range/UiBackgroundVideoTimeRangeSelector.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { Background } from '@/js/video-studio/constants/index.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import UiBackgroundVolumeSelector from '@/js/videos/components/ui/body/selectors/volume/UiBackgroundVolumeSelector.vue';
import UiBackgroundVideoSpeedSelector from '@/js/videos/components/ui/body/selectors/speed/UiBackgroundVideoSpeedSelector.vue';
import UiBackgroundEffectSelector from '@/js/videos/components/ui/body/selectors/effect/UiBackgroundEffectSelector.vue';

const props = defineProps({
    seqId: String,
    cardElementId: String
});

const store = useStore();
const { readOnly } = useVideo();
const { hasVideoBackground, hasRecordingBackground, hasColorBackground, resolvedBackgroundState, backgroundType } =
    useSequence(props.seqId);

const backgroundVideo = computed(() => {
    let ref = { src: '', id: '' };

    if ([Background.VIDEO_TYPE, Background.ANIMATED_TYPE].includes(backgroundType.value)) {
        ref.src = resolvedBackgroundState.value.video.src;
        ref.id = resolvedBackgroundState.value.video.src__id;
    }

    return ref;
});

const backgroundRecording = computed(() => {
    let ref = { src: '', id: '' };

    if (backgroundType.value === Background.RECORDING_TYPE) {
        ref.src = resolvedBackgroundState.value.video.src;
        ref.id = resolvedBackgroundState.value.video.src__id;
    }

    return ref;
});

const isMediaConverting = computed(
    () =>
        (backgroundType.value === Background.RECORDING_TYPE &&
            store.getters['loading/isConverting'](backgroundRecording.value.src)) ||
        (backgroundType.value === Background.VIDEO_TYPE &&
            store.getters['loading/isConverting'](backgroundVideo.value.src))
);
</script>
