<template>
    <ui-video-card :id="id" classes="ui-video-card-music" :selectable="false" :card-name="$t('Video music')">
        <template #selector>
            <template v-for="category in musicLibraryCategories" :key="category">
                <ui-music-library-selector
                    v-if="musicType === category"
                    v-model="libraryMusic"
                    :id="'settings-music-' + category"
                    library="musics"
                    :category="category"
                    :preview-path="'musics/' + category + '/'"
                    :show-label="false"
                    :disabled="readOnly"
                    icon="fa-regular fa-music"
                    :trigger-opening="triggerSelection && musicType === category && !libraryId"
                    @[librarySelectorShowEvent]="openMusicLibrary"
                />
            </template>

            <ui-media-selector
                v-if="musicType === musicCustomCategory || musicTypes.length === 1"
                id="settings-music-audio"
                v-model:media="music"
                :type="mediaTypeMusic"
                :label="$t('Music')"
                :show-label="false"
                :disabled="readOnly"
                icon="fa-regular fa-volume"
                :trigger-opening="
                    triggerSelection && (musicType === musicCustomCategory || musicTypes.length === 1) && !libraryId
                "
                @[mediaSelectorShowEvent]="openMediaLibrary"
            />
        </template>

        <template #content>
            <ui-dropdown
                id="settings-music-type"
                :select="true"
                :disabled="readOnly"
                v-model="musicType"
                menu-strategy="fixed"
                :default-label="$t('Add music')"
            >
                <template #dropdown-toggle="{ instance }">
                    <fa-icon :icon="musicTypeIcons[musicType]" class="icon" />
                    <span>{{ instance.toggleLabel }}</span>
                </template>

                <ui-dropdown-item
                    v-for="type in musicTypes"
                    :key="type"
                    :value="type"
                    :label="$t('studio.music_categories.' + type)"
                >
                    <template #dropdown-menu-item-label="{ instance }">
                        <fa-icon :icon="musicTypeIcons[instance.value]" class="icon" />
                        {{ instance.label || instance.value }}
                    </template>
                </ui-dropdown-item>
            </ui-dropdown>
        </template>

        <template #actions-tools>
            <ui-settings-music-time-range-selector />
            <ui-settings-music-volume-selector />
            <ui-settings-music-duration-selector />
        </template>
    </ui-video-card>
</template>

<script setup>
import { useStore } from 'vuex';
import UiVideoCard from '@/js/videos/components/ui/body/UiVideoCard.vue';
import UiMusicLibrarySelector from '@/js/components/UiMusicLibrarySelector.vue';
import { UI_LIBRARY_SELECTOR_SHOW } from '@/js/components/UiLibrarySelector.vue';
import UiMediaSelector, { UI_MEDIA_TYPE_MUSIC, UI_MEDIA_SELECTOR_SHOW } from '@/js/components/UiMediaSelector.vue';
import { useMusic } from '@/js/videos/composables/useMusic.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { CTE_MUSIC_CATEGORY, CUSTOM_MUSIC_CATEGORY, UNIVERSAL_MUSIC_CATEGORY } from '@/js/constants/index.js';
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiDropdownItem from '@/js/components/UiDropdownItem.vue';
import UiSettingsMusicDurationSelector from '@/js/videos/components/ui/body/selectors/duration/UiSettingsMusicDurationSelector.vue';
import UiSettingsMusicVolumeSelector from '@/js/videos/components/ui/body/selectors/volume/UiSettingsMusicVolumeSelector.vue';
import UiSettingsMusicTimeRangeSelector from '@/js/videos/components/ui/body/selectors/time-range/UiSettingsMusicTimeRangeSelector.vue';

const props = defineProps({
    id: {
        type: String,
        default: 'settings-music'
    }
});

const mediaTypeMusic = UI_MEDIA_TYPE_MUSIC;
const musicCustomCategory = CUSTOM_MUSIC_CATEGORY;
const musicUniversalCategory = UNIVERSAL_MUSIC_CATEGORY;
const mediaSelectorShowEvent = UI_MEDIA_SELECTOR_SHOW;
const librarySelectorShowEvent = UI_LIBRARY_SELECTOR_SHOW;

const store = useStore();
const { readOnly, openMediaLibrary, openMusicLibrary, libraryId } = useVideo();
const { musicLibraryCategories, musicTypes, musicType, music, libraryMusic, triggerSelection } = useMusic();

const universalMusicTosUrl = store.state.ui.urls.universalMusicTos;

const musicTypeIcons = {
    [CUSTOM_MUSIC_CATEGORY]: 'fa-regular fa-user-music',
    [CTE_MUSIC_CATEGORY]: 'fa-regular fa-list-music',
    [UNIVERSAL_MUSIC_CATEGORY]: 'fa-regular fa-list-music'
};
</script>
