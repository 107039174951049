<template>
    <section class="ui-body-panel" role="application" aria-describedby="ui-settings-title">
        <h2 id="ui-settings-title" class="visually-hidden">{{ $t('Video settings') }}</h2>

        <div class="ui-card-container">
            <card-settings-format />
            <card-settings-template v-if="isTemplate" />
            <card-settings-ai v-if="isTemplate && sequenceMode && canEditAiSettings" />
            <card-settings-music v-if="!sequenceMode" />
            <card-settings-voice-over v-if="!sequenceMode" />
            <card-settings-branding v-if="!sequenceMode && isEmotionMode" />
        </div>
    </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { CardSettingsTemplate, CardSettingsAi } from './cards';
import CardSettingsMusic from '@/js/videos/components/ui/body/cards/CardSettingsMusic.vue';
import CardSettingsVoiceOver from '@/js/videos/components/ui/body/cards/CardSettingsVoiceOver.vue';
import CardSettingsBranding from '@/js/videos/components/ui/body/cards/CardSettingsBranding.vue';
import CardSettingsFormat from '@/js/videos/components/ui/body/cards/CardSettingsFormat.vue';

export default {
    components: {
        CardSettingsFormat,
        CardSettingsBranding,
        CardSettingsVoiceOver,
        CardSettingsMusic,
        CardSettingsTemplate,
        CardSettingsAi
    },

    props: {
        //
    },

    data() {
        return {
            //
        };
    },

    computed: {
        ...mapState({
            sequenceMode: (state) => state.ui.sequenceMode,
            canEditAiSettings: (state) => state.ui.permissions.editAiSettings,
            isTemplate: (state) => state.settings.isTemplate
        }),

        ...mapGetters({
            isEmotionMode: 'ui/isEmotionMode'
        })
    },

    watch: {
        //
    },

    methods: {
        //
    },

    mounted() {
        //
    }
};
</script>
