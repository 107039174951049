<template>
    <div v-if="!error" class="audio-container" ref="$audioContainer" :class="containerClasses">
        <audio :src="blobSrc || null" :ref="audioReference" :data-audio-id="name + '-' + seqId" />
    </div>
</template>

<script>
import { Duration, Sound, Timeline } from '../../constants';
import { randomID } from '../../utils';
import gsap from 'gsap';
import { mapGetters, mapState } from 'vuex';
import loader from '@/js/video-studio/loader.js';

export default {
    inject: ['$stage'],

    props: {
        src: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        basisVolume: {
            type: Number,
            default: Sound.VOLUME_DEFAULT
        },
        volume: {
            type: Number,
            default: Sound.VOLUME_DEFAULT
        },
        captions: {
            type: [Boolean, Array],
            default: false
        },
        loop: {
            type: Number,
            default: Sound.LOOP_DEFAULT
        },
        seqId: {
            type: String,
            default: ''
        },
        segments: {
            type: Array
        },
        classes: {
            type: String,
            default: ''
        },
        timerange: {
            type: Object,
            default: () => ({})
        },
        playbackRate: {
            type: Number,
            default: 1
        }
    },

    data() {
        return {
            error: false,
            active: false,
            audioTextTrack: null,
            currentTime: -1,
            endTime: 0,
            totalDuration: 0,
            audioLoaded: false,
            _isLoading: false
        };
    },

    computed: {
        ...mapState({
            playing: (state) => state.display.timeline.playing,
            muted: (state) => state.display.timeline.muted,
            avoidTimelineReflow: (state) => state.display.avoidTimelineReflow
        }),

        ...mapGetters({
            mobile: 'detection/mobile',
            seeking: 'display/seeking'
        }),

        parentActive() {
            let parent = this.$parent;
            while (parent && !Object.hasOwn(parent, 'active')) {
                parent = parent.$parent;
            }
            return !parent || parent.active;
        },

        shouldPlay() {
            return this.active && this.parentActive && this.$stage.isCurrentSequenceTimeline(this.seqId);
        },
        isPlaying() {
            return this.playing && this.shouldPlay;
        },

        showingCaptions() {
            return this.shouldPlay;
        },

        containerClasses() {
            return [
                this.classes,
                {
                    'mobile-mode': this.mobile
                }
            ];
        },

        blobSrc() {
            return this.$store.getters['loading/getBlob'](this.src);
        },

        hasError() {
            return this.$store.getters['loading/hasError'](this.src);
        },

        audioReference() {
            return this.name + '-audio';
        },

        repeat() {
            let loops = Math.max(0, Math.round(this.loop));
            return loops ? loops - 1 : Sound.LOOP_INFINITE;
        },

        isValidTimerange() {
            return this.timerange.end > this.timerange.start;
        }
    },

    watch: {
        playbackRate() {
            if (this.blobSrc) {
                this.audioLoaded = false;
                this.onAudioLoaded();
            }
        },
        timerange: {
            handler() {
                this.updateTimeline(this.getAudioElement());
                this.$emit('update');
            },
            deep: true
        },

        segments: {
            handler() {
                this.updateTimeline(this.getAudioElement());
                this.$emit('update');
            },
            deep: true
        },

        src(newValue, oldValue) {
            this.updateSrc(newValue, oldValue);
        },

        blobSrc(newValue) {
            if (newValue && this.mobile)
                this.$nextTick(() => {
                    this.getAudioElement().load();
                });
        },

        volume(newValue) {
            let audioElement = this.getAudioElement();
            audioElement.volume = this.basisVolume * newValue;
            audioElement.muted = this.muted || (this.mobile && this.basisVolume * newValue == 0);
        },

        basisVolume(newValue) {
            let audioElement = this.getAudioElement();
            audioElement.volume = newValue * this.volume;
            audioElement.muted = this.muted || (this.mobile && newValue * this.volume == 0);
        },

        captions: {
            handler() {
                this.updateCaptions();
            },
            deep: true
        },

        currentTime(newValue, oldValue) {
            let audioElement = this.getAudioElement();

            if (
                !this.error &&
                Math.abs(newValue - audioElement.currentTime) > Duration.VIDEO_SYNC_THRESHOLD * this.playbackRate &&
                !audioElement.seeking
            ) {
                audioElement.currentTime = newValue;
                this.sendCaptionEvent();
            }
            if (this.isPlaying && !this.seeking && !audioElement.seeking && audioElement.paused) this.playElement();
            // console.log('currentTime', newValue, oldValue, audioElement.currentTime, audioElement.seeking, audioElement.paused, audioElement.muted, audioElement.volume, this.playing, this.active);
        },

        isPlaying(newValue, oldValue) {
            let audioElement = this.getAudioElement();

            if (!newValue && !audioElement.paused) audioElement.pause();
            else if (newValue && !this.seeking && audioElement.paused) this.playElement();
            this.sendCaptionEvent();
        },

        muted(newValue, oldValue) {
            this.getAudioElement().muted = newValue || (this.mobile && this.basisVolume * this.volume == 0);
        },

        seeking(newValue, oldValue) {
            let audioElement = this.getAudioElement();

            if (newValue && !audioElement.paused) audioElement.pause();
            else if (!newValue && this.isPlaying && audioElement.paused) this.playElement();
            this.sendCaptionEvent();
        },

        showingCaptions() {
            this.sendCaptionEvent();
        }
    },

    methods: {
        getAudioElement() {
            return this.$refs[this.audioReference] || this._emptyAudioElement;
        },

        playElement() {
            if (!this.muted) {
                var p = this.getAudioElement().play();
                if (p)
                    p.then(() => {
                        if (!this._audioContext) {
                            const AudioContext = window.AudioContext || window.webkitAudioContext;
                            this._audioContext = new AudioContext();
                        }
                    }).catch((error) => {
                        if (error.name == 'NotAllowedError') {
                            this.$stage.muteTimeline();
                            this.$nextTick(() => {
                                this.getAudioElement().play();
                            });
                        }
                    });
            }
        },

        start() {
            this._timeline.restart();
        },

        play() {
            this._timeline.play();
        },

        pause() {
            let audioElement = this.getAudioElement();

            this._timeline.pause();
            audioElement.pause();
            audioElement.currentTime = this.currentTime;
        },

        timeline() {
            return this._timeline;
        },

        updateSrc(newValue, oldValue) {
            let audioElement = this.getAudioElement();

            // TODO: add support for fetch cancellation
            if (oldValue) loader.unload(oldValue, this.onAudioError);
            if (newValue) {
                this.$store.commit('loading/prepare', this);
                if (audioElement.isConnected) {
                    this.setAudioListeners(audioElement);
                    loader.load(newValue, this.onAudioError);
                } else {
                    this.$nextTick(() => {
                        this.audioTextTrack = null;
                        this.setAudioListeners(this.getAudioElement());
                        loader.load(newValue, this.onAudioError);
                    });
                }
            } else {
                this.removeAudioListeners(audioElement);
                this.$nextTick(() => {
                    this.updateTimeline(audioElement);
                    this.$store.commit('loading/prepared', this);
                });
            }
            this.updateCaptions();
            this.error = !newValue;
        },

        updateTimeline(audioElement) {
            if (this.avoidTimelineReflow) return;

            let segments = this.segments;
            if (!segments || segments.length === 0) {
                const audioStart = this.isValidTimerange ? this.timerange.start : 0;
                const audioEnd = this.isValidTimerange ? this.timerange.end : audioElement.duration;
                segments = [{ start: audioStart, end: audioEnd }];
            }

            let totalDuration = 0;
            const adjustedSegments = segments.map((segment) => {
                const start = segment.start;
                const end = segment.end === -1 ? audioElement.duration : segment.end;
                const duration = (end - start) / this.playbackRate;
                totalDuration += duration;
                return { start, end, duration };
            });

            this._timeline = gsap.timeline({
                id: Timeline.AUDIO_TIMELINE_ID,
                paused: true
            });

            if (audioElement.isConnected) {
                this._timeline.set(this, { active: true }, 0.0001);

                let currentPosition = 0;
                adjustedSegments.forEach(({ start, end, duration }) => {
                    this._timeline.fromTo(
                        this,
                        { currentTime: start },
                        {
                            duration: duration,
                            currentTime: end,
                            ease: 'none'
                        },
                        currentPosition // Position explicite dans la timeline
                    );
                    currentPosition += duration;
                });

                this._timeline.repeat(this.repeat);

                if (this.repeat != Sound.LOOP_INFINITE) {
                    this._timeline.set(this, { active: false }, currentPosition);
                }
            }

            this.endTime = totalDuration;
        },

        updateCaptions() {
            if (!this.audioTextTrack) {
                this.audioTextTrack = this.getAudioElement().addTextTrack('captions');
                this.audioTextTrack.addEventListener('cuechange', this.sendCaptionEvent);
            }
            while (this.audioTextTrack.cues.length) {
                this.audioTextTrack.removeCue(this.audioTextTrack.cues[0]);
            }
            if (this.captions) {
                this.captions.forEach((caption) => {
                    if (
                        !isNaN(caption.startTime) &&
                        isFinite(caption.startTime) &&
                        !isNaN(caption.endTime) &&
                        isFinite(caption.endTime)
                    )
                        this.audioTextTrack.addCue(new VTTCue(caption.startTime, caption.endTime, caption.text));
                });
            }
            this.sendCaptionEvent();
        },

        sendCaptionEvent() {
            this.$stage.updateActiveCaptions(
                this.showingCaptions ? this.audioTextTrack.activeCues : [],
                this._internalID
            );
        },

        setAudioListeners(audioElement) {
            audioElement.addEventListener('canplaythrough', this.onAudioLoaded);
            audioElement.addEventListener('error', this.onAudioError);
        },

        removeAudioListeners(audioElement) {
            audioElement.removeEventListener('canplaythrough', this.onAudioLoaded);
            audioElement.removeEventListener('error', this.onAudioError);
            if (!this.avoidTimelineReflow) this.$emit('update', { el: this.$refs.$audioContainer });
        },

        onAudioLoaded() {
            if (this._isLoading) return;
            if (this.audioLoaded) {
                this.$emit('load-success');
                this.$store.commit('loading/prepared', this);
                return;
            }

            this._isLoading = true;
            this.audioLoaded = true;
            try {
                let audioElement = this.getAudioElement();
                this.totalDuration = audioElement.duration;
                audioElement.playbackRate = this.playbackRate;

                this.removeAudioListeners(audioElement);
                audioElement.volume = this.basisVolume * this.volume;
                audioElement.muted = this.muted || (this.mobile && this.basisVolume * this.volume == 0);

                this.updateCaptions();
                this.updateTimeline(audioElement);
                this.$emit('load-success');
                if (!this.avoidTimelineReflow) this.$emit('update', { el: this.$refs.$audioContainer });
                this.$store.commit('loading/prepared', this);

                this._isLoading = false;
            } catch (error) {
                console.error('Error in onAudioLoaded:', error);
                this._isLoading = false;
            }
        },

        onAudioError(event) {
            console.log(event);
            if (this.blobSrc || this.hasError) {
                let audioElement = this.getAudioElement();
                this.totalDuration = 0;

                this.removeAudioListeners(audioElement);
                this.error = true;
                this.$nextTick(() => {
                    this.updateTimeline(audioElement);
                    this.$store.commit('loading/prepared', this);
                    this.$emit('load-error');
                });
            }
        }
    },

    created() {
        this._internalID = randomID('audio_');
        this._timeline = gsap.timeline({ id: Timeline.AUDIO_TIMELINE_ID, paused: true });
    },

    mounted() {
        this._emptyAudioElement = document.createElement('audio');
        this.updateSrc(this.src);
    },

    beforeUnmount() {
        this.active = false;
        this.updateSrc('', this.src);
        this._timeline.kill();
    }
};
</script>
