<template>
    <ui-button-color-selector
        v-if="!isCustomCategory || enableColor"
        :id="cardElementId + '-color-start'"
        key="color-selector-panel-color-start"
        v-model:color="panelColorStart"
        default-color="color1"
        :palette="brandPalette"
        :enable-alpha="true"
        :enable-other-colors="brandEnableOtherColors"
        :disabled="readOnly"
        :tooltip="$t('Color 1')"
    />
</template>

<script setup>
import { usePanel } from '@/js/videos/composables/usePanel.js';
import { useBrand } from '@/js/videos/composables/useBrand.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import UiButtonColorSelector from '@/js/videos/components/ui/body/buttons/UiButtonColorSelector.vue';

const props = defineProps({
    seqId: String,
    cardElementId: String
});

const { readOnly } = useVideo();
const { brandPalette, brandEnableOtherColors } = useBrand();
const { isCustomCategory, enableColor, panelColorStart } = usePanel(props.seqId);
</script>
