<template>
    <ui-panel-color-selector :seq-id="seqId" :card-element-id="cardElementId" :disabled="disableActions" />

    <ui-panel-position-selector
        v-if="isEmotionMode"
        :seq-id="seqId"
        :card-element-id="cardElementId"
        :disabled="disableActions"
    />

    <ui-panel-duration-selector :seq-id="seqId" :card-element-id="cardElementId" :disabled="disableActions" />

    <ui-panel-effect-selector
        v-if="isEmotionMode"
        :seq-id="seqId"
        :card-element-id="cardElementId"
        :disabled="disableActions"
    />
</template>

<script setup>
import { computed } from 'vue';
import UiPanelDurationSelector from '@/js/videos/components/ui/body/selectors/duration/UiPanelDurationSelector.vue';
import UiPanelPositionSelector from '@/js/videos/components/ui/body/selectors/position/UiPanelPositionSelector.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import UiPanelColorSelector from '@/js/videos/components/ui/body/selectors/color/UiPanelColorSelector.vue';
import UiPanelEffectSelector from '@/js/videos/components/ui/body/selectors/effect/UiPanelEffectSelector.vue';
import { usePanel } from '@/js/videos/composables/usePanel.js';

const props = defineProps({
    seqId: String,
    cardElementId: String
});

const { isEmotionMode } = useVideo();
const { panelShapeImage, isCustomCategory } = usePanel(props.seqId);

const disableActions = computed(() => (isCustomCategory.value ? panelShapeImage.value.src === '' : false));
</script>
