import { computed } from 'vue';
import { useStore } from 'vuex';

export function useBrand() {
    const store = useStore();
    const shortLanguage = computed(() => store.state.ui.shortLanguage);

    const getBrandLibraryItems = (library, category) => {
        return store.getters['branding/libraries/libraryItems'](library, category);
    };

    const isBrandLibraryEmpty = computed(() => {
        return (library, category) => !getBrandLibraryItems(library, category).length;
    });

    const libraryTagOrders = computed(() => store.state.branding.libraries.tagOrders);
    const findLibraryItem = computed(() => store.getters['branding/libraries/findLibraryItem']);

    const fontLibrary = computed(() => store.state.branding.libraries.fonts);
    const parsedFontLibrary = computed(() =>
        fontLibrary.value.sort((fontA, fontB) => fontA.name.localeCompare(fontB.name, shortLanguage.value))
    );

    const brandPalette = computed(() => store.state.branding.palette);
    const brandEnableOtherColors = computed(() => store.state.branding.enableOtherColors);
    const brandLogo = computed(() => store.state.branding.logo);

    return {
        getBrandLibraryItems,
        isBrandLibraryEmpty,
        libraryTagOrders,
        findLibraryItem,
        parsedFontLibrary,

        brandPalette,
        brandEnableOtherColors,
        brandLogo
    };
}
