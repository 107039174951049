/**
 * Video Studio
 * Visual constants
 */
import { defineAsyncComponent, markRaw } from 'vue';

const IMAGE_STATIC = 'ImageStatic';
const PREFIX_CLASS = 'Visual';

const BRAND_LOGO_CATEGORY = 'brand-logo';
const IMAGE_CATEGORY = 'image';
const ICON_CATEGORY = 'icon';
const ANIMATED_CATEGORY = 'animated';
const VIDEO_CATEGORY = 'video';
const BLUR_CATEGORY = 'blur';
const COLLAGE_CATEGORY = 'collage';
const LOTTIE_CATEGORY = 'lottie';
const RECORDING_CATEGORY = 'recording';

const files = import.meta.glob('../components/visuals/*.vue');
const visuals = {};
Object.keys(files).forEach((key) => {
    key = key
        .split('/')
        .pop()
        .replace(/(\.\/|\.vue)/g, '');
    let parts = key.match(/([A-Z\d]+(?:[a-z]+|$))/g);
    visuals[parts.join('_').toUpperCase()] = key;
});

const visualList = Object.values(visuals).reduce((_visuals, componentName) => {
    _visuals[componentName.replace(PREFIX_CLASS, '')] = markRaw(
        defineAsyncComponent(() => import(`../components/visuals/${componentName}.vue`))
    );
    return _visuals;
}, {});

const BLUR_DEFAULT = 20;
const DEFAULT_CROP_POSITION = { width: 1, height: 1, left: 0, top: 0 };

// lists the visual animations where the inner asset should be cropped instead of the visual
const ANIMATIONS_CROPPING_ASSET = [
    'CircleBreathing',
    'Desktop',
    'DeviceCard1',
    'DeviceCard2',
    'Laptop',
    'LaptopSymbol',
    'MockupDesktopMinimalist1',
    'MockupDesktopMinimalist2',
    'MockupDesktopMinimalist3',
    'MockupDesktopMinimalist4',
    'MockupDesktopMinimalist5',
    'MockupDesktopMinimalist6',
    'MockupDesktopRealistic1',
    'MockupDesktopRealistic2',
    'MockupDesktopRealistic3',
    'MockupDesktopRealistic4',
    'MockupDesktopRealistic5',
    'MockupDesktopRealistic6',
    'MockupFacebook',
    'MockupInstagram',
    'MockupPhoneHands1',
    'MockupPhoneHands2',
    'MockupPhoneHands3',
    'MockupPhoneHands4',
    'MockupPhoneMinimalist1',
    'MockupPhoneMinimalist2',
    'MockupPhoneMinimalist3',
    'MockupPhoneMinimalist4',
    'MockupPhoneMinimalist5',
    'MockupPhoneMinimalist6',
    'MockupPhoneMinimalist7',
    'MockupPhoneRealistic1',
    'MockupPhoneRealistic2',
    'MockupPhoneRealistic3',
    'MockupPhoneRealistic4',
    'MockupPhoneRealistic5',
    'MockupPhoneRealistic6',
    'MockupPolaroid',
    'MockupTabletMinimalist1',
    'MockupTabletMinimalist2',
    'MockupTabletMinimalist3',
    'MockupTabletMinimalist4',
    'MockupTabletMinimalist5',
    'MockupTabletRealistic1',
    'MockupTabletRealistic2',
    'MockupTabletRealistic3',
    'MockupTabletRealistic4',
    'MockupTabletRealistic5',
    'MockupTabletRealistic6',
    'MockupTabletRealistic7',
    'MockupTabletRealistic8',
    'MockupTabletRealistic9',
    'MockupTabletRealistic10',
    'Phone',
    'PhoneFrame',
    'PhoneHand1',
    'PhoneHand2',
    'PopUp',
    'PopUp2',
    'Shape',
    'ShapeBadge',
    'ShapeBubble',
    'ShapeBubble2',
    'ShapeBubble3',
    'ShapeBubble4',
    'ShapeBubble5',
    'ShapeCircle',
    'ShapeCircleBrush',
    'ShapeCircleGradient',
    'ShapeDoor1',
    'ShapeDoor2',
    'ShapeDoor3',
    'ShapeFlower',
    'ShapeHalfCircle',
    'ShapeHalfRoundedSquare',
    'ShapeLong1',
    'ShapeLong2',
    'ShapeLong3',
    'ShapeLongTriple1',
    'ShapeLongTriple2',
    'ShapeMarker',
    'ShapeOrganic1',
    'ShapeOrganic2',
    'ShapeOrganic3',
    'ShapeOrganic4',
    'ShapeOrganic5',
    'ShapeOrganic6',
    'ShapeOrganic7',
    'ShapeOrganic8',
    'ShapeOrganic9',
    'ShapeOrganic10',
    'ShapeOval',
    'ShapePhone',
    'ShapePill1',
    'ShapePill2',
    'ShapePill3',
    'ShapePillow',
    'ShapeQuarterCircle',
    'ShapeRectangleBrush',
    'ShapeRectangleBubble',
    'ShapeRoundedArrowBL',
    'ShapeRoundedDiamond',
    'ShapeRoundedRectangle',
    'ShapeRoundedRectangle2',
    'ShapeRoundedRectangle3',
    'ShapeRoundedRectangle4',
    'ShapeRoundedRectangle5',
    'ShapeRoundedSquare',
    'ShapeRoundedSquare2',
    'ShapeRoundedSquare3',
    'ShapeRoundedSquare4',
    'ShapeRoundedTiltedRectangle',
    'ShapeRoundedTiltedRectangle2',
    'ShapeRoundedTiltedRectangle3',
    'ShapeRoundedTiltedRectangle4',
    'ShapeRoundedTiltedRectangleDouble',
    'ShapeRoundedTrapezoid',
    'ShapeRoundedYawedRectangle1',
    'ShapeRoundedYawedRectangle2',
    'ShapeRoundedYawedSquare1',
    'ShapeRoundedYawedSquare2',
    'ShapeSquareBubble',
    'ShapeTagRight',
    'ShapeTagUp',
    'ShapeTiltedLong1',
    'ShapeTiltedLong2',
    'ShapeTiltedLong3',
    'ShapeTiltedLongDouble',
    'ShapeTiltedRectangle1',
    'ShapeTiltedRectangle2',
    'ShapeTiltedRectangle3',
    'ShapeTiltedRectangleDouble1',
    'ShapeTiltedRectangleDouble2',
    'ShapeYawedRectangle1',
    'ShapeYawedRectangle2',
    'ShapeYawedSquare1',
    'ShapeYawedSquare2',
    'Tablet'
];

const Visual = {
    PREFIX_CLASS,
    PREFIX_ID: 'visual_',

    DEFAULT: IMAGE_STATIC,
    CUSTOM: 'Custom',

    BRAND_LOGO_CATEGORY,
    IMAGE_CATEGORY,
    ANIMATED_CATEGORY,
    VIDEO_CATEGORY,
    ICON_CATEGORY,
    BLUR_CATEGORY,
    COLLAGE_CATEGORY,
    LOTTIE_CATEGORY,
    RECORDING_CATEGORY,
    CATEGORIES: [
        IMAGE_CATEGORY,
        ICON_CATEGORY,
        VIDEO_CATEGORY,
        BRAND_LOGO_CATEGORY,
        ANIMATED_CATEGORY,
        LOTTIE_CATEGORY,
        BLUR_CATEGORY,
        COLLAGE_CATEGORY,
        RECORDING_CATEGORY
    ],
    CATEGORY_DEFAULT: IMAGE_CATEGORY,

    BLUR_DEFAULT,
    BLUR_ANIMATION_NAME: 'Blurred',

    COLLAGE_DEFAULT: 'MosaicHorizontal',
    COLLAGE_MAX_MEDIA: 4,

    LOTTIE_DEFAULT: 'LottieFemmeFleche',
    LOTTIE_DEFAULT_DURATION: 5,

    DEFAULT_CROP_POSITION,
    ANIMATIONS_CROPPING_ASSET,

    visualList,
    ...visuals
};

export default Visual;
