<template>
    <ui-caption-button
        :id="cardElementId + '-track-captions'"
        v-model="trackCaptions"
        :captioning="trackCaptioning"
        :status="sequenceState.audio.track.captioningStatus"
        :modifier="sequenceStoreModulePath + '/setTrackCaptions'"
        :label="$t('Captions')"
        :disabled="readOnly || isMediaConverting || !track.src"
        :timerange="trackRange"
        :icon-only="true"
        :tooltip="
            trackCaptioning
                ? $t('Captioning in progress...')
                : isMediaConverting
                  ? $t('media.preparation.warning')
                  : $t('Captions')
        "
        @[captionButtonShowEvent]="handleCaptioning"
        @[captionButtonCaptioningChangeEvent]="trackCaptioning = $event"
        :toggle-style="toggleCaptionAudioStyle"
    />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import UiCaptionButton, {
    UI_CAPTION_BUTTON_SHOW,
    UI_CAPTION_BUTTON_CAPTIONING_CHANGE
} from '@/js/videos/components/ui/body/cards/tools/caption/UiCaptionButton.vue';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';

const props = defineProps({
    seqId: String,
    cardElementId: String
});

const store = useStore();
const { saveHistoryStep } = useHistory();
const { saveVideo, readOnly } = useVideo();
const { sequenceState, sequenceStoreModulePath } = useSequence(props.seqId);

const captionButtonShowEvent = UI_CAPTION_BUTTON_SHOW;
const captionButtonCaptioningChangeEvent = UI_CAPTION_BUTTON_CAPTIONING_CHANGE;

const track = computed(() => ({
    src: sequenceState.value.audio.track.src,
    id: sequenceState.value.audio.track.src__id
}));

const trackCaptions = computed({
    get: () => sequenceState.value.audio.track.captions || [],
    set: (value) => saveHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/setTrackCaptions`, value))
});

const trackCaptioning = computed({
    get: () => sequenceState.value.audio.track.captioning,
    set: (value) => {
        if (!!value) {
            store.dispatch(`${sequenceStoreModulePath.value}/startTrackCaptioning`, value);
        } else {
            store.dispatch(`${sequenceStoreModulePath.value}/cancelTrackCaptioning`);
        }
        store.commit(`${sequenceStoreModulePath.value}/setTrackCaptioningStatus`, null);
        saveVideo();
    }
});

const trackRange = computed({
    get: () => ({
        start: sequenceState.value.audio.track.timerange?.start || 0,
        end: sequenceState.value.audio.track.timerange?.end || -1
    }),
    set: ({ start, end }) => {
        if (start !== trackRange.value.start || end !== trackRange.value.end) {
            saveHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/setTrackTimerange`, { start, end }));
        }
    }
});

const isMediaConverting = computed(() => store.getters['loading/isConverting'](track.value.src));

const hasCaptions = computed(() => trackCaptions.value.length > 0 && trackCaptions.value[0].text.length > 0);
const handleCaptioning = (button) => {
    if (hasCaptions.value) store.commit('ui/setCurrentCaptionButton', button);
    else store.dispatch(`${sequenceStoreModulePath.value}/startTrackCaptioning`);
};

const toggleCaptionAudioStyle = computed(() => {
    if (sequenceState.value.audio.track.captions && track.value.src) {
        return { color: 'var(--edit-mode-color)' };
    } else return {};
});
</script>
