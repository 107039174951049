import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { STAGE_LOADING_ENDED_EVENT } from '@/js/video-studio/utils/index.js';
import { usePreview } from '@/js/videos/composables/usePreview.js';
import { MAX_NB_OF_MESSAGES_PER_SEQUENCES, MESSAGE_ELEMENT_ID } from '@/js/constants/index.js';
import { useCard } from '@/js/videos/composables/useCard.js';
import { Align, Color, Duration, Message } from '@/js/video-studio/constants/index.js';
import { v4 as uuidv4 } from 'uuid';
import { useBrand } from '@/js/videos/composables/useBrand.js';
import _isEqual from 'lodash/isEqual.js';
import { getPrefixedUuid } from '@/js/utils.js';

const MESSAGE_IMAGES_MAX = 5; // Note: Vuex store currently supports up to 5 images

export const useMessage = (sequenceId, elementId) => {
    const videoStudio = inject('$videoStudio');

    const store = useStore();
    const { openLibrary, saveVideo, restrictions, libraryId } = useVideo();
    const { findLibraryItem } = useBrand();
    const { playPreview } = usePreview();
    const { canDuplicateCard } = useCard(sequenceId, MESSAGE_ELEMENT_ID);
    const { saveHistoryStep, ignoreHistoryStep, startHistoryStep } = useHistory(store);
    const { sequenceState, sequenceStoreModulePath } = useSequence(sequenceId);

    const elementState = computed(() => sequenceState.value[elementId]);
    const elementStoreModulePath = computed(() => `${sequenceStoreModulePath.value}/${elementId}`);

    const prefixes = computed(() => store.state.ui.prefixes);

    const canDuplicateMessage = computed(() => {
        const messagesLength = store.getters['sequences/' + sequenceId + '/messages'].length;

        return (
            canDuplicateCard.value &&
            messagesLength < MAX_NB_OF_MESSAGES_PER_SEQUENCES &&
            (restrictions.value.maxSequenceMessageCards === null ||
                messagesLength < restrictions.value.maxSequenceMessageCards)
        );
    });

    const removeMessage = () => store.dispatch(sequenceStoreModulePath.value + '/removeElement', elementId);
    const duplicateMessage = () => {
        videoStudio.value.studio.$stage.pauseTimeline();
        startHistoryStep();
        store.dispatch('sequences/' + sequenceId + '/duplicateMessage', {
            newId: Message.PREFIX_ID + uuidv4(),
            modelId: elementId
        });
        saveVideo();
    };

    const messageText = computed({
        get: () => elementState.value.text,
        set: (value) => {
            saveHistoryStep(() => store.commit(elementStoreModulePath.value + '/setText', value));
        }
    });

    const messageType = computed({
        get: () => elementState.value.animation.type,
        set: (type) => {
            saveHistoryStep(() => {
                store.commit(elementStoreModulePath.value + '/setRotationDegrees', 0); // reset rotation when animation is changed
                store.commit(elementStoreModulePath.value + '/setAnimation', type);

                // Save current message text state in case some markers were changed by changing message type
                store.commit(elementStoreModulePath.value + '/setText', messageText.value);
                isMessageTypePreview.value = false;
            });
        }
    });

    const isMessageTypePreview = ref(false);
    const openMessageLibrary = (selector) => {
        isMessageTypePreview.value = true;
        openLibrary(selector);
    };

    const previewMessageType = (type, cancel) => {
        ignoreHistoryStep(() => {
            store.commit(elementStoreModulePath.value + '/setRotationDegrees', 0);
            store.commit(elementStoreModulePath.value + '/setAnimation', type);
            if (!cancel)
                videoStudio.value.studio.$stage.$el.addEventListener(
                    STAGE_LOADING_ENDED_EVENT,
                    playMessageTypePreview,
                    { once: true }
                );
            else isMessageTypePreview.value = false;
        });
    };

    const previewMessageText = (text, cancel) => {
        ignoreHistoryStep(() => store.commit(elementStoreModulePath.value + '/setText', text));
    };

    const playMessageTypePreview = () =>
        playPreview(sequenceId, store.getters[elementStoreModulePath.value + '/start'] || 0.001);
    const isMessagePreview = computed(() => isMessageTypePreview.value || libraryId.value === 'sequenceTemplates');

    const messageStart = computed({
        get: () => store.getters[elementStoreModulePath.value + '/start'],
        set: (value) => {
            if (value !== messageStart.value) {
                saveHistoryStep(() => {
                    if (messageEnd.value !== '' && value > messageEnd.value) {
                        store.commit(elementStoreModulePath.value + '/setEnd', {
                            value: value + (messageEnd.value - messageStart.value),
                            custom: Duration.NONE
                        });
                    }
                    store.commit(elementStoreModulePath.value + '/setStart', { value, custom: Duration.NONE });
                });
            }
        }
    });

    const messageEnd = computed({
        get() {
            return store.getters[elementStoreModulePath.value + '/end'] !== Duration.END_DEFAULT
                ? store.getters[elementStoreModulePath.value + '/end']
                : '';
        },
        set: (value) => {
            if (value !== messageEnd.value) {
                saveHistoryStep(() =>
                    store.commit(elementStoreModulePath.value + '/setEnd', { value, custom: Duration.NONE })
                );
            }
        }
    });

    const shadowEnabled = computed({
        get: () => elementState.value.shadow,
        set: (value) => {
            saveHistoryStep(() => store.commit(elementStoreModulePath.value + '/enableShadow', value));
        }
    });

    const messageMetadata = computed(() => findLibraryItem.value('messages', '', messageType.value) || null);
    const messageUses = computed(() => messageMetadata.value?.uses || []);

    const messageColor1 = computed({
        get: () => getComputedColor('color1'),
        set: (value) => setComputedColor('color1', value)
    });

    const messageColor2 = computed({
        get: () => getComputedColor('color2'),
        set: (value) => setComputedColor('color2', value)
    });

    const messageColor3 = computed({
        get: () => getComputedColor('color3'),
        set: (value) => setComputedColor('color3', value)
    });

    const messageColor4 = computed({
        get: () => getComputedColor('color4'),
        set: (value) => setComputedColor('color4', value)
    });

    const messageColor5 = computed({
        get: () => getComputedColor('color5'),
        set: (value) => setComputedColor('color5', value)
    });

    const messageQuotesColor = computed({
        get: () => getComputedColor('quotes'),
        set: (value) => setComputedColor('quotes', value)
    });

    const getComputedColor = (key) => {
        let color = { ref: Color.NONE, value: Color.NONE };

        if (messageUses.value.includes('palette.' + key)) {
            color.ref = elementState.value.palette[key + '__ref'];
            color.value = elementState.value.palette[key];
        }

        return color;
    };
    const setComputedColor = (key, { ref, value }) => {
        saveHistoryStep(() => {
            store.commit(
                elementStoreModulePath.value + '/setPalette',
                Object.assign({}, elementState.value.palette, { [key + '__ref']: ref, [key]: value })
            );
        });
    };

    const displayedMediaSelectors = ref(0);
    const messageImagesMax = computed(() => {
        if (!messageUses.value.includes('images')) return 0;

        return (
            messageUses.value.reduce((max, use) => max + Number(/^images\.image/.test(use)), 0) || MESSAGE_IMAGES_MAX
        );
    });

    const messageImages = computed({
        get: () => {
            if (!messageImagesMax.value) return [];
            let index,
                images = [];

            for (index = messageImagesMax.value; index > 0; --index) {
                if (
                    !images.length &&
                    !store.getters[elementStoreModulePath.value + '/image'](index) &&
                    index > Math.max(1, displayedMediaSelectors.value)
                )
                    continue;

                images[index - 1] = {
                    src: store.getters[elementStoreModulePath.value + '/image'](index),
                    id: store.getters[elementStoreModulePath.value + '/imageId'](index)
                };
            }

            return images;
        },
        set: (images) => {
            let imagesStore = images.reduce((_store, image, index) => {
                if (image.id !== store.getters[elementStoreModulePath.value + '/imageId'](index + 1)) {
                    _store['image' + (index + 1)] = image.src;
                    _store['image' + (index + 1) + '__id'] = image.id;
                    if (!store.getters[elementStoreModulePath.value + '/imageRef'](index + 1)) {
                        _store['image' + (index + 1) + '__ref'] = getPrefixedUuid(prefixes.value.mediaReference);
                    }
                }

                return _store;
            }, {});

            if (!!Object.keys(imagesStore).length) {
                saveHistoryStep(() => {
                    if (displayedMediaSelectors.value !== elementState.value.imageSelectors) {
                        store.commit(
                            elementStoreModulePath.value + '/setImageSelectors',
                            displayedMediaSelectors.value
                        );
                    }

                    store.commit(elementStoreModulePath.value + '/setImageReferences', imagesStore);
                    store.dispatch(elementStoreModulePath.value + '/updateImages', imagesStore);
                });
            }
        }
    });

    const messageAlign = computed({
        get: () => elementState.value.align || Align.TEXT_LEFT,
        set: (value) => {
            saveHistoryStep(() => store.commit(elementStoreModulePath.value + '/setAlign', value));
        }
    });

    const messageColors = computed(() => {
        return Object.keys(elementState.value.palette).reduce((palette, key) => {
            palette[key] = getComputedColor(key).value;
            return palette;
        }, {});
    });

    const messageMarks = computed(() => {
        return (messageMetadata.value?.marks || []).reduce((marks, name) => {
            marks[name] = true;
            return marks;
        }, {});
    });

    const messageFont = computed({
        get: () => store.getters[elementStoreModulePath.value + '/font'],
        set: (font) => {
            saveHistoryStep(() =>
                store.commit(elementStoreModulePath.value + '/setFont', { value: font, custom: null })
            );
        }
    });

    const messageFontSize = computed({
        get: () => {
            return elementState.value.size.autoSize
                ? ''
                : store.getters[elementStoreModulePath.value + '/fontScale'] * Message.FONT_SIZE_DEFAULT;
        },
        set: (value) => {
            let fontScale =
                value === ''
                    ? { value: Message.FONT_SCALE_DEFAULT, custom: null }
                    : { value: Message.FONT_SCALE_CUSTOM, custom: value / Message.FONT_SIZE_DEFAULT };

            if (
                !_isEqual(fontScale, elementState.value.fontScale) ||
                elementState.value.size.autoSize ^ (value === '')
            ) {
                saveHistoryStep(() => {
                    if (elementState.value.size.autoSize ^ (value === '')) {
                        store.commit(elementStoreModulePath.value + '/enableAutoSize', value === '');
                    }

                    if (!_isEqual(fontScale, elementState.value.fontScale)) {
                        store.commit(elementStoreModulePath.value + '/setFontScale', fontScale);
                    }
                });
            }
        }
    });

    return {
        elementState,
        elementStoreModulePath,

        canDuplicateMessage,
        duplicateMessage,
        removeMessage,

        isMessagePreview,

        messageText,
        messageType,
        messageStart,
        messageEnd,
        messageUses,

        messageFont,
        messageFontSize,
        messageAlign,
        messageColors,
        messageMarks,

        messageColor1,
        messageColor2,
        messageColor3,
        messageColor4,
        messageColor5,
        messageQuotesColor,

        messageImages,
        messageImagesMax,
        displayedMediaSelectors,

        shadowEnabled,

        openMessageLibrary,
        previewMessageType,
        previewMessageText
    };
};
