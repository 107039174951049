import { computed, inject } from 'vue';
import { useStore } from 'vuex';

export function usePreview() {
    const store = useStore();
    const videoStudio = inject('$videoStudio');

    const playing = computed(() => store.state.preview.playing);
    const currentTimelineId = computed(() => store.state.preview.currentTimelineId);
    const isMainTimeline = computed(() => store.state.preview.isMainTimeline);
    const currentTimelineLabels = computed(() => store.state.preview.currentTimelineLabels);

    const playPreview = (seqId, time) => {
        store.commit('preview/setCurrentTimelineId', seqId);
        videoStudio.value.studio.$stage.seekSequenceTimeline(seqId, time || 0.001);
        videoStudio.value.studio.$stage.playTimeline();
    };

    return {
        playing,
        currentTimelineId,
        isMainTimeline,
        currentTimelineLabels,

        playPreview
    };
}
