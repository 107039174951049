<template>
    <ui-dropdown
        id="settings-music-timerange"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        :disabled="
            readOnly ||
            (!settingsState.music.src && !settingsState.music.library_src) ||
            !settingsState.music.totalDuration()
        "
        :icon-only="true"
        :tooltip="$t('Cut')"
        :toggle-style="activeTimerange ? { color: 'var(--edit-mode-color)' } : {}"
    >
        <template #dropdown-toggle>
            <fa-icon icon="fa-regular fa-scissors" class="icon" />
        </template>

        <ui-interval-input
            id="settings-music-timerange"
            v-model:values="musicRange"
            class="ui-content-row"
            unit="s."
            :step="0.01"
            :max="settingsState.music.totalDuration()"
            :min-range-length="0.5"
            :range-decimals="2"
            :label="$t('Select the part to keep')"
            :disabled="readOnly"
        />
    </ui-dropdown>
</template>

<script setup>
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiIntervalInput from '@/js/components/UiIntervalInput.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useMusic } from '@/js/videos/composables/useMusic.js';

const { readOnly } = useVideo();
const { settingsState, music, musicRange, activeTimerange } = useMusic();
</script>
