<template>
    <ui-dropdown
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        :disabled="readOnly"
        :toggle-style="toggleStyle ? { color: 'var(--edit-mode-color)' } : {}"
        :icon-only="true"
        :tooltip="$t(viewData.tooltip)"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" :icon="viewData.icon" />
        </template>

        <ui-number-input
            :id="cardElementId + '-start'"
            v-model="visualStart"
            :default-value="0"
            :min="0"
            :label="$t('Starting time')"
            :disabled="readOnly"
        />

        <ui-number-input
            :id="cardElementId + '-end'"
            v-model="visualEnd"
            :default-value="visualEndDefault"
            :min="visualStart"
            :label="$t('Ending time')"
            :disabled="readOnly"
        />

        <ui-number-input
            v-if="isVideoCategory || isRecordingCategory"
            v-model="playbackRate"
            :default-value="playbackRateDefault"
            :min="0.25"
            :max="4"
            :step="0.25"
            :label="$t('Speed')"
            :disabled="speedDisabled"
        />
    </ui-dropdown>
</template>

<script setup>
import { computed } from 'vue';
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiNumberInput from '@/js/components/UiNumberInput.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useVisual } from '@/js/videos/composables/useVisual.js';
import { Duration } from '@/js/video-studio/constants/index.js';
import { useElement } from '@/js/videos/composables/useElement.js';

const props = defineProps({
    seqId: String,
    cardElementId: String,
    elementId: String
});

const visualEndDefault = Duration.END_DEFAULT;
const playbackRateDefault = Duration.PLAY_BACK_RATE_DEFAULT;

const { readOnly } = useVideo();
const { videoState, playbackRate } = useElement(props.seqId, props.elementId);
const { visualStart, visualEnd, isVideoCategory, isRecordingCategory } = useVisual(props.seqId, props.elementId);

const speedDisabled = computed(() => readOnly.value || !videoState.value.src);
const toggleStyle = computed(() => visualStart.value !== Duration.START_DEFAULT || visualEnd.value !== '');

const viewData = computed(() =>
    isVideoCategory.value || isRecordingCategory.value
        ? { icon: ['far', 'stopwatch'], tooltip: 'Duration and speed' }
        : { icon: ['far', 'clock'], tooltip: 'Duration' }
);
</script>
